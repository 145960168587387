import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../Button"
import "./style.scss"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import useOutsideClick from "../../hooks/useOutsideClick";



export default function Dropdown({
  children,
  icon,
  options
}: {
  children: React.ReactElement | React.ReactElement[] | string | (string | React.ReactElement)[],
  icon?: React.ReactElement,
  options: Array<{
    label: string,
    name: string,
    onClick: () => any
  }>
}) {
  const ref = useOutsideClick(() => {
    setDropdownVisible(false)
  });

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdownVisible = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return <div className="Dropdown__Container">
    <Button
      className="Dropdown__Button"
      icon={icon}
      onClick={toggleDropdownVisible}
    >
      <>
        {children}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="Dropdown__Icon"
        />
      </>
    </Button>
    {dropdownVisible && <div
      className="Dropdown__Options"
      ref={ref}
    >
      {options.map(option => {
        return <button
          className="Dropdown__Option"
          name={option.name}
          onClick={() => {
            setDropdownVisible(false)
            option.onClick()
          }}
        >
          {option.label}
        </button>
      })}
    </div>}
  </div>
}