import './style.scss'
import background from '../../assets/Bg_Pattern_reddish.svg'
import vignetoMasked from '../../assets/VignetoMasked.png'
import lilacLabel from '../../assets/UI_Label_wide_lilac.svg'
import { useContext } from 'react'
import ChallengeContext from '../../contexts/ChallengeContext'
import scoreCircle from '../../assets/UI_Circle_pink_shadow.svg'
import iconScore from '../../assets/Icon_Score.svg'
import Leaders from './components/Leaders'
import GameButton from '../../presentationals/GameButton'
import iconArrow from '../../assets/ButtonIcon_Arrow.svg'
import { InternalPages } from '../Game'

const ExitButton = ({
  onClick,
  label
}: {
  onClick: () => void,
  label: String
}) => {
  return <GameButton onClick={onClick} className="ExitButton__Container">
    <>{label}</>
    <img alt="" src={iconArrow} />
  </GameButton>
}
const LeaderboardPage = () => {

  const challengeContext = useContext(ChallengeContext)

  const getButtonLabel = () => {
    if (!challengeContext.challenge) {
      return 'back'
    }
    if (challengeContext.challenge!.hasSolutionsApplied()) {
      return 'next'
    }
    return 'back'
  }

  return <div
    className="LeaderboardPage__Container"
    style={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover'
    }}
  >
    <div
      className="LeaderboardPage__LeftColumn"
    >
      <div className="LeaderboardPage__MyScore">
        <img
          alt=""
          src={vignetoMasked}
          className="LeaderboardPage__MyScoreImage"
        />
        <div className="LeaderboardPage__MyScoreLabel">
          <img
            alt=""
            src={lilacLabel}
          />
          <div className="LeaderboardPage__MyScoreLabelText">
            Leaderboard
          </div>

        </div>
        <div className="LeaderboardPage__MyScoreScoreContainer">
          <div className="LeaderboardPage__MyScoreNickname">
            {challengeContext.game!.user?.nickname}
          </div>
          <div className="LeaderboardPage__MyScoreScore">
            <img
              alt=""
              src={scoreCircle}
            />
            <div className="LeaderboardPage__MyScoreScoreText">
              {challengeContext.game!.score}
            </div>
            <div className="LeaderboardPage__MyScoreScoreIcon">
              <img
                alt=""
                src={iconScore}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="LeaderboardPage__RightColumn"
    >
      <Leaders />
    </div>
    <div
      className="LeaderboardPage__Actions"
    ><ExitButton
        label={getButtonLabel()}
        onClick={() => {
          if (!challengeContext.challenge) {
            window.location.reload()
            return
          }

          if (challengeContext.challenge!.hasSolutionsApplied()) {
            window.location.reload()
          } else if (challengeContext.challenge!.acknowledged) {
            challengeContext.setInternalPage!(InternalPages.CHALLENGE)
          } else {
            challengeContext.setInternalPage!(InternalPages.MAP)
          }
          }}
      /></div>
  </div>
}

export default LeaderboardPage