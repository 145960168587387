import './MapNavigator.scss'
import zoomInSvg from '../../../assets/ZoomIn.svg'
import zoomOutSvg from '../../../assets/ZoomOut.svg'
import moveUpSvg from '../../../assets/MoveUp.svg'
import moveDownSvg from '../../../assets/MoveDown.svg'
import moveLeftSvg from '../../../assets/MoveLeft.svg'
import moveRightSvg from '../../../assets/MoveRight.svg'
import AreaSelector from './AreaSelector'

import bottomRightGroupV2 from '../../../assets/BottomLeftBoxV2.svg'
import mapControls from '../../../assets/MapControls.svg'
import mapControlsMask from '../../../assets/MapControlsMask.svg'
import dropdownIcon from '../../../assets/DropdownIcon.svg'
import { useState } from 'react'
const MapNavigator = ({
  zoomIn,
  zoomOut,
  moveUp,
  moveDown,
  moveLeft,
  moveRight,
  onGuessArea,
  onWrongArea,
  onHighlightArea
}: {
  zoomIn: () => void,
  zoomOut: () => void,
  moveUp: () => void,
  moveDown: () => void,
  moveLeft: () => void,
  moveRight: () => void,
  onGuessArea: () => any,
  onWrongArea: () => any,
  onHighlightArea: (area: string) => any
}) => {
  const [controlsOpen, setControlsOpen] = useState(false)

  return <div className="MapNavigator__Container">
    <img alt="" src={bottomRightGroupV2} />
    <div
      className="MapNavigator__MapControls"
      style={{
        maskImage: `url(${mapControlsMask})`,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        WebkitMaskImage: `url(${mapControlsMask})`,
        WebkitMaskSize: 'contain',
        WebkitMaskRepeat: 'no-repeat'
      }}
    >
      <div
        className={`MapNavigator__MapControlsInner MapNavigator__MapControlsInner${controlsOpen ? '--open' : '--closed'}`}
        style={{
          backgroundImage: `url(${mapControls})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <button
          className="MapNavigator__Button MapNavigator__ButtonToggleControls"
          onClick={() => {

            setControlsOpen(!controlsOpen)
          }}
        >Map controls
          <img className={controlsOpen ? "" : "MapNavigator__IconRotated"} alt="" src={dropdownIcon} />

        </button>
        <button
          className="MapNavigator__Button MapNavigator__ButtonZoomIn"
          onClick={zoomIn}
          style={{
            backgroundImage: `url(${zoomInSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <button
          className="MapNavigator__Button MapNavigator__ButtonZoomOut"
          onClick={zoomOut}
          style={{
            backgroundImage: `url(${zoomOutSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <button
          className="MapNavigator__Button MapNavigator__ButtonMoveUp"
          onClick={moveUp}
          style={{
            backgroundImage: `url(${moveUpSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <button
          className="MapNavigator__Button MapNavigator__ButtonMoveDown"
          onClick={moveDown}
          style={{
            backgroundImage: `url(${moveDownSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <button
          className="MapNavigator__Button MapNavigator__ButtonMoveLeft"
          onClick={moveLeft}
          style={{
            backgroundImage: `url(${moveLeftSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <button
          className="MapNavigator__Button MapNavigator__ButtonMoveRight"
          onClick={moveRight}
          style={{
            backgroundImage: `url(${moveRightSvg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>


    </div>


    <div className="MapNavigator__Selector">
      <AreaSelector
        onGuessArea={onGuessArea}
        onWrongArea={onWrongArea}
        onHighlightArea={onHighlightArea}
      />
    </div>
  </div>
}

export default MapNavigator