import DialogBox from '../../../presentationals/DialogBox'
import GameDialog from '../../../presentationals/GameDialog'
import './ConfirmDialog.scss'
import crossIconSVG from '../../../assets/CrossIcon.svg'
import GameButton from '../../../presentationals/GameButton'

const ConfirmDialog = ({
    open,
    onClose,
    onConfirm
} : {
    open: boolean,
    onClose: () => any,
    onConfirm: () => any
}) => {
    return <GameDialog
        open={open}
        onClose={onClose}
        xSmall
    >
        <DialogBox>
            <img
                alt=""
                width="50px"
                className="ConfirmDialog__ExitIcon"
                src={crossIconSVG}
                onClick={onClose}
            />
            <div className="ConfirmDialog__Content">
                <p>Are you sure?</p>
                
                <GameButton
                    onClick={onConfirm}
                    className="ConfirmDialog__Button">
                    APPLY
                </GameButton>
            </div>
        </DialogBox>
    </GameDialog>
}

export default ConfirmDialog