const calculateLinearRegression = (data: {x: number, y: number}[]) => {
    const n = data.length;
  
    // Calculate sums
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumX2 = 0;
  
    for (const point of data) {
      sumX += point.x;
      sumY += point.y;
      sumXY += point.x * point.y;
      sumX2 += point.x ** 2;
    }
  
    // Calculate coefficients of the regression line (y = mx + b)
    const m = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
    const b = (sumY - m * sumX) / n;
  
    return { m, b };
  };

  export default calculateLinearRegression