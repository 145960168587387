import React, { useState, useContext } from 'react'
import './style.scss'
import Breadcrumb from '../../presentationals/Breadcrumb';
import Actions from '../../presentationals/Actions';
import Button from '../../presentationals/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import Card from '../../presentationals/Card';
import Session from '../../../core/session/Session';
import DomainContext from '../../../contexts/DomainContext'
import SessionForm from './SessionForm';
import useSnackbar from '../../hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';
import Table from '../../presentationals/Table';

export default function Page() {
  const navigate = useNavigate()

  const globalContext = useContext(DomainContext)
  const { showSnackbar } = useSnackbar()
  const [session] = useState<Session>(globalContext.sessionRepository.newSession())

  const [partecipants, setPartecipants] = useState<Array<{
    first_name: string,
    last_name: string,
    user_name: string,
    treatment: string
  }>>([]);

  const [prefix, setPrefix] = useState("")

  const saveSession = async () => {

    var prefix_regex = new RegExp("^[a-zA-Z0-9_-]+$");

    if (session.name === "") {
      showSnackbar("Insert a session name", "error")
    } else if (prefix === "") {
      showSnackbar("Insert a prefix", "error")
    } else if (partecipants.length === 0) {
      showSnackbar("No participants added", "error")
    } else if (!prefix_regex.test(prefix)) {
      showSnackbar("Token prefix: invalid characters", "error")
    } else {
      var sessionId = await globalContext.sessionInitializer.initializeSession(
        session,
        partecipants,
        prefix)

      showSnackbar("Session created")
      navigate('/sessions/' + sessionId)
    }
  }

  const handleChangePartecipants = (partecipants: Array<{
    first_name: string,
    last_name: string,
    user_name: string,
    treatment: string
  }>) => {
    setPartecipants(partecipants)

  }

  return <div className="SessionCreatePage__Container">
    <Breadcrumb title="Create session" items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'create',
        label: 'Create',
        href: '/sessions/create'
      }
    ]} />

    <Actions leftActions={<Button
      onClick={() => {
        navigate('/sessions')
      }}
      icon={
        <FontAwesomeIcon icon={faArrowLeft} />
      }
    >Back to sessions</Button>}>
      <Button
        icon={<FontAwesomeIcon icon={faSave} />}
        onClick={saveSession}
      >Save session</Button>
    </Actions>
    <Card>
      <SessionForm
        onSessionChange={(key, value) => {
          if (key === 'name') {
            session.name = value
          }
          if (key === 'prefix') {
            setPrefix(value)
          }
        }}
        onChangePartecipants={handleChangePartecipants}
      />
    </Card>
    <h1 className="SessionCreatePage__Title">Participants</h1>
    <Card noPadding className="SessionCreatePage__Partecipants">
      {partecipants.length > 0 ?
        <Table>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Treatment</th>
            </tr>
          </thead>
          <tbody>
            {partecipants.map((partecipant, index) => <tr key={index}>
              <td>{partecipant.first_name}</td>
              <td>{partecipant.last_name}</td>
              <td>{partecipant.treatment}</td>
            </tr>)}
          </tbody>
        </Table> :
        <div className="SessionCreatePage__NoPartecipants">Upload a csv to add participants</div>
      }

    </Card>
  </div>

}