
import DebriefChart from "../../widgets/DebriefChart";



export default function SessionDebriefExperiments()
{
    return <DebriefChart
        title={"Which group experimented the most?"}
        description={"average number of experiments"}
        field={"experimentsDone"}
        yDomain={([dataMin, dataMax]:[any, any]) => {
            return [
                0, Math.trunc(1 + dataMax)
            ]
        }}
        yTicks={null}
    />
}