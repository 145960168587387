import boxWrapper from '../../assets/BoxWrapper_yelloish.svg';
import boxHeader from '../../assets/BoxHeader_reddish.svg';
import './style.scss'

const Box = ({
  small,
  title,
  children,
  buttons
}: {
  small?: boolean,
  title?: string,
  children: any,
  buttons?: any
}) => {
  return <div
    className="Box__Container"
  >
    {title && <div
      className="Box__Header"
      style={{
        backgroundImage: `url(${boxHeader})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div
        className="Box__HeaderContent"
      >
        <div
          className="Box__HeaderContentTitle"
        >
          {title}
        </div>
      </div>
    </div>}
    <div
      className="Box__Wrapper"
      style={{
        backgroundImage: `url(${boxWrapper})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div
        className="Box__WrapperContent"
      >
        {children}
      </div>
    </div>
    <div className="Box__Actions">
      {buttons}
    </div>


  </div>
}

export default Box