import { CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from "recharts"
import CustomScatterDot from "./CustomScatterDot"
import { GameData } from "../../../../core/debrief/DebriefService"
import { useMemo } from "react"
import calculateLinearRegression from "../../../../core/utils/calculateLinearRegression"

export default function CorrelationChart({
  filteredChartData,
  chartData,
  showRegression,
  refreshKey
}: {
  filteredChartData: GameData[],
  chartData: GameData[],
  showRegression: boolean,
  refreshKey: number
}) {

  const regressionLine = useMemo(() => {

    const chartDataProjection = chartData.map(cd => ({
      x: cd.experiments,
      y: cd.score
    }))
    const { m, b } = calculateLinearRegression(chartDataProjection);

    return [
      {
        x: 0,
        y: m * 0 + b
      },
      {
        x: 9,
        y: m * 9 + b
      },
    ]
  }, [chartData])

  return <ResponsiveContainer width="100%" height="100%" key={refreshKey}><ScatterChart
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
    }}
  >

    <CartesianGrid />
    <XAxis
      type="number"
      dataKey="jitteredX"
      name="experiments"
      domain={([dataMin, dataMax]) => {
        return [0, Math.max(5, dataMax)]
      }}
      ticks={[0, 1, 2, 3, 4, 5]}
      tickMargin={10}
    >
      <Label value="Average number of experiments" offset={5} position="bottom" />
    </XAxis>
    <YAxis
      type="number"
      dataKey="jitteredY"
      name="score"
      domain={[-3, 3]}
      ticks={[-3, -2, -1, 0, 1, 2, 3]}
      tickMargin={10}
    >
      <Label angle={-90} value='Average points earned per challenge' position='insideLeft' style={{ textAnchor: 'middle' }} />
    </YAxis>
    <Scatter
      isAnimationActive={false}
      name="Correlation"
      data={filteredChartData}
      fill="#8884d8"
      tooltipType="none"
      shape={<CustomScatterDot />}
    />
    {showRegression && <ReferenceLine ifOverflow="visible" stroke="#59194B" strokeWidth={2} segment={[regressionLine[0], regressionLine[1]]} />}

  </ScatterChart></ResponsiveContainer>
}