import Game from "../../game/Game";
import GameRepository from "../../game/GameRepository";
import LeaderboardRepository from "../../leaderboard/LeaderboardRepository";
import ResearchDataRepository from "../../research_data/ResearchDataRepository";
import Challenge, { Solution } from "../Challenge";
import ChallengeRepository from "../ChallengeRepository";

export default class ExperimentCommand {

    constructor(
        private gameRepository: GameRepository,
        private challengeRepository: ChallengeRepository,
        private leaderboardRepository: LeaderboardRepository,
        private researchDataRepository: ResearchDataRepository,
        private game: Game,
        private challenge: Challenge,
        private solution: Solution
    ) {

    }

    public async execute(): Promise<void> {

        const score = await this.challengeRepository.findSolutionScore(this.solution.id);

        this.challenge.experimentSolution(this.solution.id, score)
        this.game.addScore(-1);
        await this.challengeRepository.update(this.challenge)
        await this.gameRepository.update(this.game)
        await this.leaderboardRepository.assignScore(this.game.sessionId, this.game.id!, this.game.score);

        let solution_pos = 0;

        for (var solution of this.challenge.solutions) {
            solution_pos++;
            if (solution.id === this.solution.id) {
                break;
            }
        }

        var researchData = {
            session_id: this.game.sessionId,
            student: this.game.user?.user_name!,
            treatment: this.game.treatment!,
            issue: this.challenge.title,
            action: "experiment",
            action_seq: this.challenge.currentActionIndex.toString(),
            solution: this.solution.id,
            solution_pos: solution_pos.toString(),
            timestamp: new Date()
        }

        this.researchDataRepository.insert(researchData)
    }
}