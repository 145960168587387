import GameInput from "../../../presentationals/GameInput"
import SelectInput from "../../../presentationals/SelectInput"
import './ThirdPage.scss'

import { countryOptions } from "../../../../core/utils/countries"

const ThirdPage = ({
  cityBirth,
  cityResidence,
  countryBirth,
  countryResidence,
  universityMajor,
  setCityBirth,
  setCityResidence,
  setCountryBirth,
  setCountryResidence,
  setUniversityMajor,
  resetError
}: {
  cityBirth: string,
  cityResidence: string,
  countryBirth: string,
  countryResidence: string,
  universityMajor: string,
  setCityBirth: (value: any) => void,
  setCityResidence: (value: any) => void,
  setCountryBirth: (value: any) => void,
  setCountryResidence: (value: any) => void,
  setUniversityMajor: (value: any) => void,
  resetError: () => void
}) => {
  const ID = "ProfilationThirdPage"

  const relativeClass = (className: string) => {
    return ID + "__" + className
  }

  return <div className={relativeClass("Container")}>
    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        City of birth
      </div>
      <GameInput
        name="city_birth"
        value={cityBirth}
        onChange={(e) => {
          resetError()
          setCityBirth(e.target.value)
        }}
      />
    </div>
    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        City of residence
      </div>
      <GameInput
        name="city_residence"
        value={cityResidence}
        onChange={(e) => {
          resetError()
          setCityResidence(e.target.value)
        }}
      />
    </div>
    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        Country of birth
      </div>
      <SelectInput
        name="country_birth"
        value={countryBirth}
        onChange={(e) => {
          resetError()
          setCountryBirth(e.target.value)
        }}
        options={countryOptions}
      />
    </div>
    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        Country of residence
      </div>
      <SelectInput
        name="country_residence"
        value={countryResidence}
        onChange={(e) => {
          resetError()
          setCountryResidence(e.target.value)
        }}
        options={countryOptions}
      />
    </div>
    <div className={relativeClass("Field")}>
      <div className={relativeClass("Label")}>
        University major
      </div>
      <GameInput
        name="university_major"
        value={universityMajor}
        onChange={(e) => {
          resetError()
          setUniversityMajor(e.target.value)
        }}
      />
    </div>
  </div>
}

export default ThirdPage