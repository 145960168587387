import "./AreaWrong.scss"
import GameDialog from "../../../presentationals/GameDialog"
import DialogBox from "../../../presentationals/DialogBox"
import hint from "../../../assets/Hint.svg"
import { useContext } from "react"
import ChallengeContext from "../../../contexts/ChallengeContext"
const AreaWrong = ({
  open,
  onClose
}: {
  open: boolean,
  onClose: () => void
}) => {

  const challengeContext = useContext(ChallengeContext)

  return <GameDialog
    open={open}
    onClose={onClose}
    dialogStyle={{
      height: '30vw'
    }}
  >
    <DialogBox
      small
      title="Try again!"
      onClose={onClose}
      buttons={<></>}
    >
      <div className="AreaWrong__Content">
        <div className=" AreaWrong__Text AreaWrong__Text--center AreaWrong__Text--larger">
          No issues detected in this area!
        </div>
        <div className="AreaWrong__Hint">
          <img alt="" src={hint} />
          <div className="AreaWrong__HintDesc">
            <div className="AreaWrong__HintDescTitle">
              Hint:
            </div>
            <div className="AreaWrong__HintDescContent">
              {challengeContext.challenge!.hint}
            </div>
          </div>
        </div>
      </div>
    </DialogBox>

  </GameDialog>
}

export default AreaWrong