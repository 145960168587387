import Challenge from "../../challenge/Challenge";
import ChallengeRepository from "../../challenge/ChallengeRepository";
import JwtKeeper from "./JwtKeeper";
import PouchDB from 'pouchdb'

class ChallengeRepositoryPouchDB extends ChallengeRepository {

    private _couchdbUrl: string

    private _databaseUrl: string
    private _scoresDatabaseUrl: string
    private _db: PouchDB.Database | undefined
    private _scoresDb: PouchDB.Database | undefined

    private max_participants: string | undefined


    constructor(private jwtKeeper: JwtKeeper) {
        super()

        this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
        this._databaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_CHALLENGES_DB

        
        this._db = new PouchDB(this._databaseUrl, {
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })

        this._scoresDatabaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_SCORES_DB

        this._scoresDb = new PouchDB(this._scoresDatabaseUrl, {
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })

        this.max_participants = process.env.REACT_APP_MAX_PARTICIPANTS

    }

    public async fetchByGameIdAndBlueprintId(gameId: string, challengeBlueprintId: string): Promise<Challenge | null> {
        const result = await this._db!.find({
            selector: {
                game_id: gameId,
                challenge_blueprint_id: challengeBlueprintId
            }
        })


        if (result.docs.length > 0) {
            const datas = result.docs[0] as any

            var challenge = new Challenge(gameId, challengeBlueprintId, datas.solutions)
            Challenge.restore(datas, challenge)

            return challenge
        } else {
            return null
        }
    }

    public async create(challenge: Challenge): Promise<string> {
        const dump = Challenge.dump(challenge)
        delete dump._id
        delete dump._rev
        const result = await this._db!.post(dump)

        challenge._id = result.id
        challenge._rev = result.rev
        return result.id
    }

    public async update(challenge: Challenge) {
        const dump = Challenge.dump(challenge)
        var response = await this._db!.put(dump)
        challenge._rev = response.rev
    }

    public async findSolutionScore(solution_id: string): Promise<number> {
        const result = await this._scoresDb?.get(solution_id, {})
        return (result! as any).score as number
    }

    public async fetchCompletedBySessionId(session_id: string): Promise<Challenge[]>{
        const result = await this._db!.find({
            selector: {
                session_id: {$eq: session_id},
                completed: {$eq: true}
            },
            limit: Number(this.max_participants)
        })

        const challenges = result.docs.map((document: any) => {
            var challenge = new Challenge(document.game_id, document.challenge_blueprint_id, document.solutions)
            Challenge.restore(document, challenge)

            return challenge
        })

        return challenges;
    }
    public async fetchCompletedBySessionIdAndBlueprintId(session_id: string, blueprint_id: string): Promise<Challenge[]>{
        const result = await this._db!.find({
            selector: {
                session_id: {$eq: session_id},
                completed: {$eq: true},
                challenge_blueprint_id: {$eq: blueprint_id}
            },
            limit: Number(this.max_participants)
        })

        const challenges = result.docs.map((document: any) => {
            var challenge = new Challenge(document.game_id, document.challenge_blueprint_id, document.solutions)
            Challenge.restore(document, challenge)

            return challenge
        })

        return challenges;
    }
}

export default ChallengeRepositoryPouchDB