import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DomainContext from "../../../contexts/DomainContext";
import './style.scss'
import Breadcrumb from "../../presentationals/Breadcrumb";
import Session from "../../../core/session/Session";
import LeaderboardEntry from "../../../core/leaderboard/LeaderboardEntry";
import Card from "../../presentationals/Card";
import Table from "../../presentationals/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrophy } from "@fortawesome/free-solid-svg-icons";
import Actions from "../../presentationals/Actions";
import Button from "../../presentationals/Button";


export default function SessionLeaderboard() {
  const { id } = useParams()
  const globalContext = useContext(DomainContext)
  const [session, setSession] = useState<Session | null>(null)
  const [leaderboardEntries, setLeaderboardEntries] = useState<LeaderboardEntry[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    globalContext.leaderboardRepository.fetchAllScores(id!, true).then(response => {
      setLeaderboardEntries(response)
    })
    loadSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadSession = async () => {
    const result = await globalContext.sessionRepository.restoreSession(id!);
    setSession(result)
  }

  return <div className="SessionLeaderboardPage__Container">
    <Breadcrumb title={"Session " + session?.name + '\'s Leaderboard'} items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      },
      {
        name: 'leaderboard',
        label: 'Leaderboard',
        href: '/sessions/' + id + '/leaderboard'
      },
    ]} />
    <Actions
      leftActions={<Button
        onClick={() => {
          navigate('/sessions/' + id)
        }}
        icon={
          <FontAwesomeIcon icon={faArrowLeft} />
        }
      >Back to session</Button>}
    ></Actions>
    <Card noPadding className="SessionLeaderboardPage__Leaderboard">
      <Table>
        <thead>
          <tr>
            <td></td>
            <th>Position</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardEntries.map(((entry, index) => {
            let trophyColor = 'transparent'
            if (index + 1 === 1) {
              trophyColor = '#cfa411'
            }
            if (index + 1 === 2) {
              trophyColor = '#a3a2a0'
            }
            if (index + 1 === 3) {
              trophyColor = '#b87949'
            }
            return <tr key={entry.game_id}>
              <td>
                <FontAwesomeIcon color={trophyColor} icon={faTrophy} />
              </td>
              <td>{index + 1}</td>
              <td>{entry.name === '-' ? '(no username picked)' : entry.name}</td>
              <td>{entry.score_data.score}</td>
            </tr>
          }))}
        </tbody>
      </Table>
    </Card>
  </div>
}