import React from 'react';

const CustomScatterDot: React.FC<any> = ({ cx, cy, payload }) => {
  return (
    <circle
      cx={cx!}
      cy={cy!}
      r={8}
      stroke={"black"}
      strokeWidth={2}
      style={{ opacity: "0.7" }}
      fill={payload.colour} />
  );
};

export default CustomScatterDot