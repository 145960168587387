import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './style.scss'
import Breadcrumb from "../../presentationals/Breadcrumb";
import Card from "../../presentationals/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faTrophy } from "@fortawesome/free-solid-svg-icons";
import Actions from "../../presentationals/Actions";
import Button from "../../presentationals/Button";
import Loading from "../../presentationals/Loading";
import Table from "../../presentationals/Table";
import GroupCard from "./components/GroupCard";
import DebriefContext from "../../contexts/DebriefContext";


export default function SessionDebrief() {
  const { id } = useParams()

  const {
    groups,
    loading,
    leaderboardEntries,
    session
  } = useContext(DebriefContext)

  const navigate = useNavigate()

  return <div className="SessionDebriefPage__Container">
    <Breadcrumb title={"Session " + session?.name + '\'s Debrief'} items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      },
      {
        name: 'debrief',
        label: 'Debrief',
        href: '/sessions/' + id + '/debrief'
      },
    ]} />
    <Actions
      leftActions={<Button
        onClick={() => {
          navigate('/sessions/' + id)
        }}
        icon={
          <FontAwesomeIcon icon={faArrowLeft} />
        }
      >Back to session</Button>}
    >

    </Actions>
    {
      loading ?
        <Loading />
        :
        <>
          <Card noPadding className="SessionDebriefPage__Content">
            <Table>
              <thead>
                <tr>
                  <td></td>
                  <th>Position</th>
                  <th>Nickname</th>
                  <th>Group</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {leaderboardEntries.map(((entry, index) => {
                  let trophyColor = 'transparent'
                  if (index + 1 === 1) {
                    trophyColor = '#cfa411'
                  }
                  if (index + 1 === 2) {
                    trophyColor = '#a3a2a0'
                  }
                  if (index + 1 === 3) {
                    trophyColor = '#b87949'
                  }
                  return <tr key={entry.game_id}>
                    <td>
                      <FontAwesomeIcon color={trophyColor} icon={faTrophy} />
                    </td>
                    <td>{index + 1}</td>
                    <td>{entry.name === '-' ? '(no username picked)' : entry.name}</td>
                    <td>{entry.group}</td>
                    <td>{entry.score_data.score}</td>
                  </tr>
                }))}
                {leaderboardEntries.length === 0 &&
                  <td
                    colSpan={5}
                    style={{
                      textAlign: 'center',
                      padding: '60px 0px'
                    }}
                  >No participant started the game</td>}
                </tbody>
            </Table>
          </Card>
          <div className="SessionDebriefPage__Title">
            <h1>Groups performance</h1>
          </div>
          <div className="SessionDebriefPage__Groups">
            {
              groups.map(group => <GroupCard name={group.name} score={group.participantsNum === 0 ? '-' : (group.score / group.participantsNum).toFixed()} />)
            }
          </div>
          <div className="SessionDebriefPage__Title">
            <h1>Charts</h1>
          </div>
          <Card className="SessionDebriefPage__Action">
            <div>Which group <b>decided better</b>?</div>
            <div>
              <Button
                onClick={() => {
                  navigate('/sessions/' + id + '/debrief/net-score')
                }}
              ><FontAwesomeIcon icon={faArrowRight} /></Button>
            </div>
          </Card>
          <Card className="SessionDebriefPage__Action">
            <div>Which group <b>experimented the most</b>?</div>
            <div>
              <Button
                onClick={() => {
                  navigate('/sessions/' + id + '/debrief/experiments')
                }}
              ><FontAwesomeIcon icon={faArrowRight} /></Button>
            </div>
          </Card>
          <Card className="SessionDebriefPage__Action">
            <div>What treatment makes you the <b>best decisor</b>?</div>
            <div>
              <Button
                onClick={() => {
                  navigate('/sessions/' + id + '/debrief/correlation')
                }}
              ><FontAwesomeIcon icon={faArrowRight} /></Button>
            </div>
          </Card>
        </>
    }
  </div>
}