import { useContext } from "react"
import "./AcknowledgeDialog.scss"
import ChallengeContext from "../../../contexts/ChallengeContext"
import GameDialog from "../../../presentationals/GameDialog"
import GameButton from "../../../presentationals/GameButton"
import DialogBox from "../../../presentationals/DialogBox"
import alertIconSvg from "../../../assets/Icon_alert.svg"

const AcknowledgeDialog = ({
  open,
  onClose
}: {
  open: boolean,
  onClose: () => void
}) => {

  const challengeContext = useContext(ChallengeContext)

  return <GameDialog
    open={open}
    onClose={onClose}
    dialogStyle={{
      height: '30vw'
    }}
  >
    <DialogBox
      small
      title="Good job!"
      onClose={onClose}
      buttons={<GameButton
        variant="green"
        onClick={async () => {
          var command = challengeContext.challengeInteractService?.buildAcknowledgeCommand(
            challengeContext.challenge!,
            challengeContext.game!
          )
          command?.execute().then(() => {
            challengeContext.reloadChallenge!()
            challengeContext.showChallenge!()
          })
        }}
      >start the challenge</GameButton>}
    >
      <div className="AcknowledgeDialog__Content">
        <div className=" AcknowledgeDialog__Text AcknowledgeDialog__Text--center AcknowledgeDialog__Text--larger">
          You have spotted the issue!
        </div>
        <div className="AcknowledgeDialog__Row">
          <div className="AcknowledgeDialog__Icon">
            <img style={{ width: '2vw' }} alt="" src={alertIconSvg} />
          </div>
          <div className="AcknowledgeDialog__Text">
            Click on “Start the challenge” to learn more about the managerial issue Fiasco is facing
          </div>
        </div>
        <div className="AcknowledgeDialog__Row">
          <div className="AcknowledgeDialog__Icon">
            <img style={{ width: '2vw' }} alt="" src={alertIconSvg} />
          </div>
          <div className="AcknowledgeDialog__Text">
            Remember that you can look at the course materials before or during the challenge
          </div>
        </div>
      </div>
    </DialogBox>

  </GameDialog>
}

export default AcknowledgeDialog