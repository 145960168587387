import { useContext } from "react"
import ConfirmContext from "../contexts/ConfirmContext"


const useConfirm = () => {
  const { setConfirmContext } = useContext(ConfirmContext)

  const showConfirm = (message: string, confirmCallback: () => void) => {
    document.body.style.overflowY = 'hidden';

    setConfirmContext({
      confirmOpen: true,
      confirmMessage: message,
      confirmCallback: confirmCallback
    })
  }

  return showConfirm;
}

export default useConfirm