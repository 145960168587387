import LeaderboardEntry from "../../leaderboard/LeaderboardEntry";
import LeaderboardRepository, { PerformanceResult } from "../../leaderboard/LeaderboardRepository";
import PouchDB from 'pouchdb'
import JwtKeeper from "./JwtKeeper";


class LeaderbaordRepositoryPouchDB extends LeaderboardRepository {
    private _couchdbUrl: string

    private _databaseUrl: string

    private _db: PouchDB.Database | undefined

    private max_participants: string | undefined

    constructor(private jwtKeeper: JwtKeeper) {
        super()

        this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
        this._databaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_LEADERBOARDS_DB

        this.max_participants = process.env.REACT_APP_MAX_PARTICIPANTS


        this._db = new PouchDB(this._databaseUrl, {
            // auth: {
            //     username: process.env.REACT_APP_DB_USERNAME,
            //     password: process.env.REACT_APP_DB_PASSWORD
            // }
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })

    }

    public async assignScore(sessionId: string, gameId: string, score: number) {
        const result = await this._db!.find({
            selector: {
                session_id: sessionId,
                game_id: gameId
            }
        })

        if (result.docs.length > 0) {
            const doc = result.docs[0] as any
            doc.score_data = {
                ...doc.score_data,
                score: score
            };

            await this._db?.post(doc);
        } else {
            throw new Error("Leaderboard entry not found")
        }
    }

    public async assignScoreAndGamesPlayedNum(
        sessionId: string,
        gameId: string,
        score: number,
        challengesPlayedNum: number)
    {
        const result = await this._db!.find({
            selector: {
                session_id: sessionId,
                game_id: gameId
            }
        })

        if (result.docs.length > 0) {
            const doc = result.docs[0] as any
            doc.score_data = {
                ...doc.score_data,
                score: score,
                challengesPlayedNum: challengesPlayedNum
            };

            await this._db?.post(doc);
        } else {
            throw new Error("Leaderboard entry not found")
        }
    }

    public async assignNickname(sessionId: string, gameId: string, nickname: string) {
        const result = await this._db!.find({
            selector: {
                session_id: sessionId,
                game_id: gameId
            }
        })

        if (result.docs.length > 0) {
            const doc = result.docs[0] as any
            doc.name = nickname

            await this._db?.post(doc);
        } else {
            throw new Error("Leaderboard entry not found")
        }
    }

    public async fetchAllScores(sessionId: string, onlyPlayed: boolean = false): Promise<LeaderboardEntry[]> {
        const result = await this._db!.find({
            selector: onlyPlayed ? {
                session_id: {$eq: sessionId},
                'name': {$ne: '-'}
            } : {
                session_id: {$eq: sessionId},
            },
            sort: this.sortingRule,
            limit: Number(this.max_participants)
        })

        return result.docs as unknown as Array<LeaderboardEntry>
    }
    

    public async fetchTop10(sessionId: string): Promise<LeaderboardEntry[]> {
        const result = await this._db!.find({
            selector: {
                session_id: {$eq: sessionId},
                'name': {$ne: '-'}
            },
            fields: [
                'name', 'score_data'
            ],
            sort: this.sortingRule,
            limit: 10
        })

        return result.docs as unknown as Array<LeaderboardEntry>
    }

    public async fetchTop10AllData(sessionId: string): Promise<LeaderboardEntry[]> {
        const result = await this._db!.find({
            selector: {
                session_id: {$eq: sessionId},
                'name': {$ne: '-'}
            },
            sort: this.sortingRule,
            limit: 10
        })

        return result.docs as unknown as Array<LeaderboardEntry>
    }

    public async checkPerformance(sessionId: string, gameId: string): Promise<PerformanceResult> {
        const allScores = await this.fetchAllScores(sessionId, true)

        const totalScores = allScores.length
        
        let position = 0

        for (let score of allScores) {
            if (score.game_id === gameId) {
                break
            }

            position++;
        }

        const percentPosition = position / totalScores
        const goodThreshold = 0.33
        const normalThreshold = 0.66

        if (percentPosition < goodThreshold) {
            return PerformanceResult.GOOD
        }

        if (percentPosition < normalThreshold) {
            return PerformanceResult.NORMAL
        }

        return PerformanceResult.BAD
    }

    public async bulkInsert (entries: LeaderboardEntry[]) {
        await this._db!.bulkDocs(entries)
    }

    public async bulkDelete (entries: LeaderboardEntry[]) {
        await this._db!.bulkDocs(entries.map((entry: any) => {
        
            entry._deleted = true
            return entry
        }))
    }

}

export default LeaderbaordRepositoryPouchDB