import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../presentationals/Button";
import Dialog from "../../presentationals/Dialog";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import ConfirmContext from "../../contexts/ConfirmContext";

export default function Confirm() {

  const { confirmContext, setConfirmContext } = useContext(ConfirmContext)


  return <>{confirmContext.confirmOpen && <Dialog
    footer={
      <><Button outline
        onClick={() => {
          document.body.style.overflowY = 'initial';
          setConfirmContext({
            confirmOpen: false,
            confirmMessage: "",
            confirmCallback: () => { }
          })
        }}
      >Cancel</Button>
        <Button
          onClick={() => {
            document.body.style.overflowY = 'initial';
            confirmContext.confirmCallback().then(() => {
              setConfirmContext({
                confirmOpen: false,
                confirmMessage: "",
                confirmCallback: () => { }
              })
            })

          }}

        >Ok</Button></>
    }
  >
    <div style={{
      paddingTop: 20,
      paddingBottom: 20
    }}>
      <FontAwesomeIcon style={{
        fontSize: 46,
        color: '#e3a034'
      }} icon={faCircleExclamation} />
      <br />
      <br />
      {confirmContext.confirmMessage}
    </div>
  </Dialog>}</>
}