import { jwtDecode } from 'jwt-decode';

export class CAS {
  private readonly STORAGE_KEY = 'cas_user';

  protected constructor() {}

  private decode(token: string) {
    if (!token || token === '') return null;
    return jwtDecode(token);
  }

  private save(token: string) {
    localStorage.setItem(this.STORAGE_KEY, token || '');
  }

  get() {
    return localStorage.getItem(this.STORAGE_KEY) || '';
  }

  getUser() {
    return this.decode(this.get()) as CASUser;
  }

  get loginUrl() {
    return `${process.env.REACT_APP_CAS_ENDPOINT}${window.location.origin}/cas_response`;
  }

  static init(search?: string) {
    const cas = new CAS();
    if (search) {
      const params = new URLSearchParams(search);
      const token = params.get('t');
      cas.save(token || '');
    }
    return cas;
  }
}

type CASUser = {
  id: string;
}
