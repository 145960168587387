import Card from '../Card'
import './style.scss'

export default function Dialog ({
    children,
    footer
}: {
    children: JSX.Element | JSX.Element[] | (() => JSX.Element) | string,
    footer: React.ReactElement | React.ReactElement[]
}) {
    return <div className="Dialog__Container">
        <div className="Dialog__Content">
            <Card
                className="Dialog__Card"
                footer={
                    <div className="Dialog__Footer">
                        {footer}
                    </div>
                }
            >
                {children}
            </Card>
        </div>
    </div>
}