import { useContext } from "react"
import "./style.scss"
import SnackbarContext from "../../contexts/SnackbarContext"

export default function Snackbar () {
    const {snackbarContext} = useContext(SnackbarContext)

    return <div className={"Snackbar__Container " + (!snackbarContext.snackbarOpen ? "Snackbar__Container--hidden " : "")}>
            <div className={`Snackbar__Content Snackbar__Content--${snackbarContext.snackbarType}`}>
                {snackbarContext.snackbarMessage}
            </div>
        </div>
}