import { useContext } from "react"
import ChallengeContext from "../../../contexts/ChallengeContext"
import iconScore from '../../../assets/Icon_Score.svg';
import iconLeaderboard from '../../../assets/Icon_Leadboard.svg';
import './GameScore.scss'
import { InternalPages } from "..";

const GameScore = ({
    className
}: {
    className?: string
}) => {

    const challengeContext = useContext(ChallengeContext)

    return <div className={"GameScore__Container " + className}>
        <div className="GameScore__Score">{challengeContext.game?.score}</div>
        <div className="GameScore__IconScore"
            style={{
                backgroundImage: `url(${iconScore})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }}
        ></div>
        <div><div
            className="GameScore__IconLeaderboard"
            style={{
                backgroundImage: `url(${iconLeaderboard})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }}
            onClick={() => {
                challengeContext.setInternalPage!(InternalPages.LEADERBOARD)
            }}
        /></div>
    </div>
    
}

export default GameScore
