import { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SessionsLayout from './admin/pages/Sessions/layout';
import Sessions from './admin/pages/Sessions';
import SessionCreate from './admin/pages/SessionCreate';
import Session from './admin/pages/Session';
import SessionLeaderboard from './admin/pages/SessionLeaderboard';
import SessionCompletion from './admin/pages/SessionCompletion';
import AuthLayout from './AuthLayout';
import AuthContext from './contexts/AuthContext';
import axios from 'axios';
import HomePage from './game/pages/Home';
import GamePage from './game/pages/Game';
import SplashScreen from './game/pages/SplashScreen';
import BocconiHeader from './presentationals/BocconiHeader';
import './style/global.scss'
import GameLayout from './game/layouts/GameLayout';
import SessionDebrief from './admin/pages/SessionDebrief';
import DebriefLayout from './admin/layout/DebriefLayout';
import SessionDebriefNetScore from './admin/pages/SessionDebriefNetScore';
import SessionDebriefExperiments from './admin/pages/SessionDebriefExperiments';
import SessionDebriefCorrelation from './admin/pages/SessionDebriefCorrelation';
import { CASResponse } from './cas/CASResponse';
import { UserResolver } from './UserResolver';

function App() {

  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const [authState, setAuthState] = useState({
    loggedIn: false,
    identity: "",
    role: "",
    database_jwt_token: ""
  })

  useEffect(() => {
    // me()
    setTimeout(() => {
      setShowSplashScreen(false)
    }, 1000)
  }, [])

  // const me = async () => {
  //   const res = await axios.get('http://localhost:3001/me');

  //   setAuthState({
  //     loggedIn: true,
  //     identity: res.data.identity,
  //     role: res.data.role,
  //     database_jwt_token: res.data.jwt_token
  //   })
  //   navigate('/')
  // }

  const login = async () => {

    const res = await axios.get('http://localhost:3001/me');

    setAuthState({
      loggedIn: true,
      identity: res.data.identity,
      role: res.data.role,
      database_jwt_token: res.data.jwt_token
    })
    navigate('/')
  }

  const logout = () => {
    setAuthState({
      loggedIn: false,
      identity: "",
      role: "",
      database_jwt_token: ""
    })
  }

  const navigate = useNavigate()


  return <div
    className="AppContainer"
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}
  >
    <div
      className="AppHeader"
    >
      <BocconiHeader />
    </div>
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        position: 'relative'
      }}
      className="AppContent"

    >
      <SplashScreen show={showSplashScreen} />
      <div
        className="App"
        style={{
          flexGrow: 1
        }}
      >
        <AuthContext.Provider value={{
          loggedIn: authState.loggedIn,
          identity: authState.identity,
          role: authState.role,
          database_jwt_token: authState.database_jwt_token,
          login,
          logout,
          isAdmin: () => {
            return authState.role === "admin"
          }
        }}>
          <Routes>

            {/* Non auth routes */}
            <Route path='cas_response' element={<CASResponse />} />

            <Route element={<GameLayout />}>
              <Route index element={<HomePage />} />
            </Route>


            <Route path='users/:userId' element={<GameLayout />}>
              <Route index element={<UserResolver />} />
            </Route>
            <Route path="sessions" element={<SessionsLayout
              // header={<Header/>}
              header={<></>}
              footer={<>footer</>}
            />}>
              <Route index element={<Sessions />} />
              <Route path="create" element={<SessionCreate />} />
              <Route path=":id" element={<Session />} />
              <Route path=":id/leaderboard" element={<SessionLeaderboard />} />
              <Route path=":id/completion" element={<SessionCompletion />} />
              <Route element={<DebriefLayout />}>
                <Route path=":id/debrief" element={<SessionDebrief />} />
                <Route path=":id/debrief/net-score" element={<SessionDebriefNetScore />} />
                <Route path=":id/debrief/experiments" element={<SessionDebriefExperiments />} />
                <Route path=":id/debrief/correlation" element={<SessionDebriefCorrelation />} />
              </Route>
            </Route>

            {/* Auth routes */}
            <Route element={<AuthLayout />}>

              <Route element={<GameLayout />}>
                <Route path="games/:token" element={<GamePage />} />
              </Route>
            </Route>
          </Routes>
        </AuthContext.Provider>
      </div>
    </div>
  </div>
}

export default App;


