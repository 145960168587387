import GameDialog from "../../../presentationals/GameDialog"
import boxTested from '../../../assets/UI_box_tested_solution.svg'
import iconGlasses from '../../../assets/Icon_glasses.svg'
import labelOrange from '../../../assets/UI_Label_wide_orange.svg'
import './TestedSolutionDialog.scss'
import { Solution } from "../../../../core/challenge/Challenge"
import { useContext } from "react"
import ChallengeContext from "../../../contexts/ChallengeContext"
import closeIcon from "../../../assets/CrossIcon.svg"
const TestedSolutionDialog = ({
    open,
    onClose,
    solution
} : {
    open: boolean,
    onClose: () => any,
    solution?: Solution
}) => {
    const challengeContext = useContext(ChallengeContext)

    return <GameDialog
        open={open}
        onClose={onClose}
        small
    >
        
        <div className="TestedSolutionDialog__Container">
            <img alt=""
                src={closeIcon}
                className="TestedSolutionDialog__Close"
                onClick={onClose}
            />
            <img alt="" className="TestedSolutionDialog__Icon" src={iconGlasses}/>
            <img alt="" className="TestedSolutionDialog__Box" src={boxTested} />
            <div className="TestedSolutionDialog__Content">
                <div className="TestedSolutionDialog__ContentHeader">
                    You tested {solution?.title}
                </div>
                <div className="TestedSolutionDialog__ContentBody">
                    You spent <b>1</b> point<br/>
                    <b>{challengeContext.game?.score}</b> points still remaining
                </div>
            </div>
            <div className="TestedSolutionDialog__BottomLabel">
                <img alt="" src={labelOrange}/>
                <div className="TestedSolutionDialog__BottomLabelContent">
                    By applying this solution, you will earn <b>{solution?.score}</b> points
                </div>
            </div>
        </div>
    </GameDialog>
}

export default TestedSolutionDialog