import {
  useContext, useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import DebriefContext from "../../contexts/DebriefContext";
import Breadcrumb from "../../presentationals/Breadcrumb";
import './index.scss'
import Actions from "../../presentationals/Actions";
import Button from "../../presentationals/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DebriefChartLayout from "../../layout/DebriefChartLayout";
import Card from "../../presentationals/Card";
import { GameData } from "../../../core/debrief/DebriefService";
import ToggleSwitch from "../../presentationals/inputs/ToggleInput";
import CorrelationChart from "./components/CorrelationChart";
import pointToJittered from "../../../core/utils/pointToJittered";
import toBounded from "../../../core/utils/toBounded";

const colours = [
  '#ef2525',
  '#982fad',
  '#449144',
  '#ffc63b',
  '#22bad5',
  '#77554a'
]

interface GameDataWithJitteredPoints extends GameData {
  jitteredX: any,
  jitteredY: any
}

export default function SessionDebriefCorrelation() {
  const { id } = useParams()

  const [showRegression, setShowRegression] = useState(true)

  const [groupToggles, setGroupToggles] = useState<{
    name: string,
    active: boolean
  }[]>([])

  const activeGroups = useMemo(() => {
    return groupToggles.filter(g => g.active).map(g => g.name)
  }, [groupToggles])

  const [loading, setLoading] = useState(true)
  const [chartData, setChartData] = useState<GameDataWithJitteredPoints[]>([])
  const [filteredChartData, setFilteredChartData] = useState<GameDataWithJitteredPoints[]>([])
  const [groupColors, setGroupColors] = useState<{
    [id: string]: string
  }>({})

  const [refreshKey, setRefreshKey] = useState(Date.now())

  const navigate = useNavigate()

  const {
    session,
    debriefService
  } = useContext(DebriefContext)

  useEffect(() => {
    if (session)
      initGroups()
  }, [session])

  useEffect(() => {
    fetchChallengeData()
  }, [])

  useEffect(() => {
    filterChallengeData()
  }, [activeGroups, chartData])

  const filterChallengeData = async () => {

    const newDatas = chartData
      .filter(nd => {
        return activeGroups.includes(nd.treatment)
      })
      .map(nd => {
        return {
          ...nd,
          experiments: parseFloat(nd.experiments.toFixed(2)),
          score: parseFloat(nd.score.toFixed(2)),
          colour: groupColors[nd.treatment],
          jitteredX: nd.jitteredX,
          jitteredY: nd.jitteredY
        }
      })

    setFilteredChartData(newDatas)
    setRefreshKey(Date.now())
  }

  const initGroups = () => {

    var newGroupColors: {
      [index: string]: string
    } = {}
    var i = 0;
    for (var treatment of session?.treatments!) {
      newGroupColors[treatment] = colours[i]
      i++
    }

    setGroupColors(newGroupColors)

    setGroupToggles(session!.treatments.map(group => ({
      name: group,
      active: true
    })))
  }

  const toggleGroup = (name: string) => {
    setGroupToggles(groupToggles.map(group => {
      if (group.name === name) {
        group.active = !group.active
      }

      return group;
    }))
  }

  const fetchChallengeData = async () => {
    setLoading(true)
    const data = await debriefService?.fetchGamesData(id!)

    if (data) {

      setChartData(data.map(d => ({
        ...d,
        jitteredX: toBounded(pointToJittered(d.experiments, .1), [0, 9]),
        jitteredY: toBounded(pointToJittered(d.score, .1), [-3, 3]),
      })))

      setLoading(false)
    } else {
      // Error
    }
  }
  return <div className="SessionDebriefCorrelation__Container">
    <Breadcrumb items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      },
      {
        name: 'debrief',
        label: 'Debrief',
        href: '/sessions/' + id + '/debrief'
      },
      {
        name: 'score_chart',
        label: 'best decision',
        href: '/sessions/' + id + '/debrief/correlation'
      },
    ]} />

    <div className="SessionDebriefCorrelation__Title">
      <h1>What treatment makes you the best decisor?</h1>
      <h3>Correlation between number of experiments and quality of choices</h3>
    </div>
    <Actions
      leftActions={<Button
        onClick={() => {
          navigate('/sessions/' + id + '/debrief')
        }}
        icon={
          <FontAwesomeIcon icon={faArrowLeft} />
        }
      >Back to debrief</Button>}
    >
      <div style={{ display: 'inline-block' }}>
        <ToggleSwitch
          isChecked={showRegression}
          onToggle={() => {
            setShowRegression(!showRegression)
          }}
          label="class regression"
        />
      </div>
    </Actions>
    <DebriefChartLayout
      loading={loading}
      filters={<Card>

        <div className="SessionDebriefCorrelation__GroupFilter">
          <div className="SessionDebriefCorrelation__GroupFilterLabel">
            Groups
          </div>
          {
            groupToggles.map(group => {
              return <div
                className="SessionDebriefCorrelation__Group"
              ><Button
                inactive={!group.active}
                onClick={() => {
                  toggleGroup(group.name)
                }}
              >{group.name}</Button><div
                className="SessionDebriefCorrelation__GroupDot"
                style={{
                  background: groupColors[group.name]
                }}></div></div>
            })
          }
        </div>

      </Card>}
    >
      <Card style={{
        height: '800px',
        padding: '20px'
      }}
        pureOverlay
      >
        <CorrelationChart
          filteredChartData={filteredChartData}
          showRegression={showRegression}
          chartData={chartData}
          refreshKey={refreshKey}
        />
      </Card>
    </DebriefChartLayout>
  </div>
}