import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DomainContext from "../../../contexts/DomainContext";
import './style.scss'
import Breadcrumb from "../../presentationals/Breadcrumb";
import Session from "../../../core/session/Session";
import Card from "../../presentationals/Card";
import Table from "../../presentationals/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import Challenge from "../../../core/challenge/ChallengeBlueprint";
import Actions from "../../presentationals/Actions";
import Button from "../../presentationals/Button";
import Game from "../../../core/game/Game";
import Papa from 'papaparse';
import downloadBlob from "../../utils/downloadBlob";

export default function SessionCompletion() {

  const { id } = useParams()
  const globalContext = useContext(DomainContext)
  const [session, setSession] = useState<Session | null>(null)
  const [challenges, setChallenges] = useState<(Challenge & { isActive?: boolean, isDeactivated?: boolean })[]>([])
  const [games, setGames] = useState<Game[]>([])
  const navigate = useNavigate()


  const [sessionLoaded, setSessionLoaded] = useState(false)
  const [challengesLoaded, setChallengesLoaded] = useState(false)
  const [gamesLoaded, setGamesLoaded] = useState(false)

  useEffect(() => {
    loadSession()
    loadGames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadChallenges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const loadChallenges = async () => {
    const challs = await globalContext.challengeRepositoryBlueprint.fetch()
    const challsWithActiveStatus = challs.map(challenge => {
      return {
        ...challenge,
        isActive: session?.activeChallengesIds.includes(challenge.id),
        isDeactivated: !session?.activeChallengesIds.includes(challenge.id) && session?.onceActiveChallengesIds?.includes(challenge.id)
      }
    })
    setChallenges(challsWithActiveStatus)
    setChallengesLoaded(true)
  }

  const loadGames = async () => {
    const games = await globalContext.gameRepository.fetchAllBySessionId(id!)
    setGames(games)
    setGamesLoaded(true)
  }

  const loadSession = async () => {
    const result = await globalContext.sessionRepository.restoreSession(id!);
    setSession(result)
    setSessionLoaded(true)
  }

  const donwloadMatrix = async () => {

    var lines: any[] = []
    games.forEach(game => {
      var gameLine: any = {
        participant: game.user?.first_name + " " + game.user?.last_name,
      }
      challenges.forEach((challenge) => {

        let isChallengePlayed = game.challengesPlayed.includes(challenge.id)
        gameLine[challenge.title] = isChallengePlayed

      })

      lines.push(gameLine)
    })

    var csv = Papa.unparse(lines);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(csvData, session?.name.replace(" ", "_") + "_completion.csv")
  }

  if (!gamesLoaded || !sessionLoaded || !challengesLoaded) {
    return <>Loading...</>
  }

  return <div className="SessionCompletionPage__Container">
    <Breadcrumb title={"Session " + session?.name + '\'s Completion'} items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      },
      {
        name: 'completion',
        label: 'Completion',
        href: '/sessions/' + id + '/completion'
      },
    ]} />

    <Actions
      leftActions={<Button
        onClick={() => {
          navigate('/sessions/' + id)
        }}
        icon={
          <FontAwesomeIcon icon={faArrowLeft} />
        }
      >Back to session</Button>}
    >
      <Button
        icon={
          <FontAwesomeIcon icon={faDownload} />
        }
        onClick={donwloadMatrix}
      >Download</Button>
    </Actions>
    <Card noPadding className="SessionCompletionPage__Leaderboard">
      <Table>
        <thead>
          <tr>
            <th>Participant</th>
            {challenges.map((challenge) => {

              const challengeActive = challenge.isActive
              const challengeDeactivated = challenge.isDeactivated

              let totalPlayed = 0;
              games.forEach(game => {
                if (game.challengesPlayed.includes(challenge.id)) {
                  totalPlayed++;
                }
              })

              const percent = ((totalPlayed / games.length) * 100).toFixed(0);

              return <th key={challenge.id}>
                <div className="SessionCompletionPage__ChallengeStatus">
                  <div className="SessionCompletionPage__ChallengeTitle">{challenge.title}</div>
                  <div className="SessionCompletionPage__ChallengeProgress">
                    {percent}%
                  </div>
                  <div className="SessionCompletionPage__ChallengeActive">
                    {challengeActive &&
                      <div className="SessionCompletionPage__ActiveLabel">Active</div>
                    }
                    {
                      challengeDeactivated &&
                      <div className="SessionCompletionPage__DeactivatedLabel">Deactivated</div>
                    }
                    {
                      (!challengeActive && !challengeDeactivated) &&
                      <div className="SessionCompletionPage__InactiveLabel">Inactive</div>
                    }
                  </div>
                </div>
              </th>
            })}
          </tr>
        </thead>
        <tbody>
          {
            games.map(game => {

              return <tr key={game.id}>
                <td>
                  {game.user!.first_name + " " + game.user!.last_name}
                </td>
                {challenges.map((challenge) => {

                  let isChallengePlayed = game.challengesPlayed.includes(challenge.id)

                  return <td key={challenge.id + " " + game.id} style={{ textAlign: 'center' }}>
                    {isChallengePlayed ? <FontAwesomeIcon
                      color="#0a8a25"
                      icon={faCheck}
                      size="xl"
                    /> : <FontAwesomeIcon
                      color="#bf3a19"
                      icon={faXmark}
                      size="xl"
                    />}
                  </td>
                })}
              </tr>
            })
          }
        </tbody>
      </Table>
    </Card>
  </div>
}