import { useCallback, useContext, useEffect, useState } from "react"
import { Outlet, useParams } from "react-router-dom"
import DebriefContext, { Group } from "../contexts/DebriefContext"
import Session from "../../core/session/Session"
import DomainContext from "../../contexts/DomainContext"
import LeaderboardEntry from "../../core/leaderboard/LeaderboardEntry"
import DebriefService from "../../core/debrief/DebriefService"

export default function DebriefLayout() {
  const { id } = useParams()
  const globalContext = useContext(DomainContext)
  const [session, setSession] = useState<Session | null>(null)
  const [leaderboardEntries, setLeaderboardEntries] = useState<LeaderboardEntry[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [groups, setGroups] = useState<Group[]>([])


  useEffect(() => {
    loadDebrief()
  }, [])

  const loadDebrief = async () => {
    await loadData()
    setLoading(false)
  }

  const loadData = async () => {
    const sessionResult = await globalContext.sessionRepository.restoreSession(id!);
    setSession(sessionResult)

    const result = await globalContext.leaderboardRepository.fetchTop10AllData(id!)

    const allResult = await globalContext.leaderboardRepository.fetchAllScores(id!, true)

    setGroups(buildGroupData(allResult, sessionResult?.treatments!))

    setLeaderboardEntries(result)
  }

  const buildGroupData = useCallback((scores: LeaderboardEntry[], allGroups: string[]) => {

    var returnGroups: Group[] = []

    var groupIndex: {
      [index: string]: number
    } = {}

    for (var score of scores) {
      if (Object.keys(groupIndex).includes(score.group)) {
        var existingGroup = returnGroups[groupIndex[score.group]]
        existingGroup.score += score.score_data.score
        existingGroup.participantsNum++
      } else {
        groupIndex[score.group] = returnGroups.length
        returnGroups.push({
          name: score.group,
          score: score.score_data.score,
          participantsNum: 1
        })
      }
    }

    for (let g of allGroups) {
      if (!returnGroups.find(rg => rg.name === g)) {
        returnGroups.push({
          name: g,
          score: 0,
          participantsNum: 0
        })
      }
    }
    returnGroups.sort((a, b) => {
      if (a.participantsNum === 0)
        return 1
      return a.score / a.participantsNum < b.score / b.participantsNum ? 1 : -1
      // return a.score < b.score ? 1 : -1
    })
    return returnGroups
  }, [])

  return <DebriefContext.Provider value={{
    groups,
    setGroups,
    session,
    setSession,
    leaderboardEntries,
    setLeaderboardEntries,
    loading, setLoading,
    debriefService: new DebriefService(
      globalContext.challengeRepository
    )
  }}><Outlet /></DebriefContext.Provider>
}