import './Leaders.scss'
import labelOutline from '../../../assets/UI_Label_outline.svg'
import scoreIcon from '../../../assets/UI_Circle_number_bg.svg'
import { useContext, useEffect, useState } from 'react'
import LeaderboardEntry from '../../../../core/leaderboard/LeaderboardEntry'
import DomainContext from '../../../../contexts/DomainContext'
import ChallengeContext from '../../../contexts/ChallengeContext'

const Leader = ({
    position,
    name,
    score
} : {
    position: number,
    name: string,
    score: number
}) => {
    return <div
        className="Leader__Container"
        key={position}
    >
        <div
            className="Leader__Text"
        >
            {position.toString()}&nbsp;. &nbsp;&nbsp;&nbsp;{name}
        </div>
        <div
            className="Leader__Icon"
        >
            <img
                alt=""
                src={scoreIcon}
            />
            <div
                className="Leader__IconContent"
            >
                {score.toString()}
            </div>
        </div>
    </div>
}

const Leaders = () => {

    const challengeContext = useContext (ChallengeContext)
    const domainContext = useContext(DomainContext)

    const [leaders, setLeaders] = useState<LeaderboardEntry[]>([])

    useEffect(() => {
        loadLeaders()
    },[])

    const loadLeaders = async () => {
        const leaders = await domainContext.leaderboardRepository!.fetchTop10(challengeContext.game?.sessionId!)
        setLeaders(leaders)
    }    

    return <div
        className="Leaders__Container"
    >
        <div className="Leaders__Title">
            <img alt="" src={labelOutline} />
            <div className="Leaders__TitleContent">
                TOP 10
            </div>
        </div>
        <div className="Leaders__Content">
            <div
                className="Leaders__ContentColumn"
            >
                {leaders.slice(0, 5).map((leader, index) => {
                    return <Leader
                    key={index}
                    position={index + 1}
                    name={leader.name}
                    score={leader.score_data.score}
                    />
                })}
                
            </div>
            <div
                className="Leaders__ContentColumn"
            >
                {leaders.slice(5, 10).map((leader, index) => {
                    return <Leader
                    position={index + 6}
                    name={leader.name}
                    score={leader.score_data.score}
                    />
                })}
               
            </div>
           
        </div>
        
    </div>
}

export default Leaders;