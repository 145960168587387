export default class User {

  public nickname: string = ""

  public age: number | null = 0

  public gender: string = ""

  public role: string = "" // student | professional

  public user_name: string = ""

  public first_name: string = ""
  public last_name: string = ""

  public city_birth: string = ""
  public city_residence: string = ""
  public country_birth: string = ""
  public country_residence: string = ""
  public university_major: string = ""
}