import ChallengeBlueprint from "../../challenge/ChallengeBlueprint";
import ChallengeBlueprintRepository from "../../challenge/ChallengeBlueprintRepository";
import JwtKeeper from "./JwtKeeper";
import PouchDB from 'pouchdb'

class ChallengeBlueprintRepositoryPouchDB extends ChallengeBlueprintRepository {
    private _couchdbUrl: string
    private _databaseUrl: string
    
    private _db: PouchDB.Database | undefined

    constructor(private jwtKeeper: JwtKeeper) {
        super()

        this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
        this._databaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_CHALLENGE_BLUEPRINTS_DB

        this._db = new PouchDB(this._databaseUrl, {
            // auth: {
            //     username: process.env.REACT_APP_DB_USERNAME,
            //     password: process.env.REACT_APP_DB_PASSWORD
            // }
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })

        
    }
    
    public async fetch(): Promise<ChallengeBlueprint[]> {
        

        const result = await this._db?.find({
            selector: {
            },
            sort: [{'number': 'asc'}]
        })
        return result?.docs as unknown as ChallengeBlueprint[]
    }

    public async find(challengeBlueprintId: string): Promise<ChallengeBlueprint|undefined> {
        
        const result = await this._db?.get(challengeBlueprintId)
        return result as ChallengeBlueprint|undefined
    }

    
}

export default ChallengeBlueprintRepositoryPouchDB