import { useContext, useState } from 'react'
import './style.scss'
import ChallengeContext from '../../contexts/ChallengeContext'
import background from '../../assets/background_blurred.jpg'
import issueBackground from '../../assets/IssueDesc_bg_pattern.svg'
import issueCard from '../../assets/IssueDesc_bg.svg'
import headingSeparator from '../../assets/UI_heading_separator.svg'
import textPreviewBottom from '../../assets/TextPreviewBottom.png'
import GameButton from '../../presentationals/GameButton'
import labelLeft from '../../assets/UI_Label_left_purplish.svg';
import Solutions from './components/Solutions'
import GameScore from '../Game/components/GameScore'
import AppliedSolution from './components/AppliedSolution'
import { InternalPages } from '../Game'

const DescriptionTitle = ({ withMargin }: { withMargin?: boolean }) => {
  return <div
    className={"ChallengePage__Separator " + (withMargin ? "ChallengePage__Separator--withMargin" : "")}
  >
    <div className="ChallengePage__SeparatorLeft">
      <img alt="" className="ChallengePage__SeparatorIcon--rotated" src={headingSeparator} />
    </div>
    <div className="ChallengePage__SeparatorCenter">
      Challenge description
    </div>
    <div className="ChallengePage__SeparatorRight">
      <img alt="" src={headingSeparator} />
    </div>
  </div>
}

const ChallengePage = () => {

  const challengeContext = useContext(ChallengeContext)

  const [descriptionCollapsed, setDescriptionCollapsed] = useState(true)
  const [solutionApplied, setSolutionApplied] = useState(false)

  const toggleDescriptionCollapsed = () => {
    setDescriptionCollapsed(!descriptionCollapsed)
  }

  const onApplySolution = () => {
    setSolutionApplied(true)
  }

  return <div
    className="ChallengePage__Container"
    style={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover'
    }}
  >
    {
      solutionApplied ?
        <AppliedSolution
          onNext={() => {
            const challengePlayedNum = challengeContext.game?.challengesPlayed.length
            if (challengePlayedNum === 5) {
              challengeContext.setInternalPage!(InternalPages.HALFWAY_CHECK)
            } else {
              challengeContext.setInternalPage!(InternalPages.LEADERBOARD)
            }
          }}
        />
        :

        <div
          className="ChallengePage__Content"
        >
          <div
            className="ChallengePage__Left"
          >
            <div
              style={{
                backgroundImage: `url(${issueBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }}
              className="ChallengePage__DescrBackground"
            >
              <div
                className="ChallengePage__DescCard"
                style={{
                  backgroundImage: `url(${issueCard})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <div className="ChallengePage__ChallengeTitle">
                  <img alt="" src={labelLeft} />
                  <div className="ChallengePage__ChallengeTitleText">
                    CHALLENGE
                  </div>
                </div>
                {
                  descriptionCollapsed ?
                    <><div
                      className="ChallengePage__Video"
                    >
                      <video
                        controls
                        src={challengeContext.challenge?.video}
                        poster={challengeContext.challenge?.video.replace('.mp4', '.png')}

                      />

                    </div>
                      <DescriptionTitle />
                      <div
                        className="ChallengePage__DescriptionPreview"
                      >
                        {challengeContext.challenge?.description.replace("%paragraph%", "")}
                        <img
                          alt=""
                          className="ChallengePage__DescriptionPreviewBottom"
                          src={textPreviewBottom}
                        />

                      </div></> :
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <DescriptionTitle withMargin />
                      <div className="ChallengePage__Description">
                        {challengeContext.challenge?.description.split("%paragraph%").map(paragraph => {
                          return <p>
                            {paragraph}
                          </p>
                        })}
                      </div>
                    </div>
                }

                <div className="ChallengePage__ShowDescriptionButtonContainer">
                  <GameButton
                    className="ChallengePage__ShowDescriptionButton"
                    onClick={toggleDescriptionCollapsed}
                  >{descriptionCollapsed ? 'read more' : 'close'}</GameButton>
                </div>
              </div>
            </div>
          </div>
          <div className="ChallengePage__Right">
            <div className="ChallengePage__RightHeader">
              <div className="ChallengePage__RightHeaderText">
                Which is the best solution?
              </div>
              <GameScore className="ChallengePage__RightHeaderActions" />
            </div>
            <div className="ChallengePage__RightSolutions">
              <Solutions
                onApplySolution={onApplySolution}
              />
            </div>
          </div>
        </div>
    }


  </div>
}

export default ChallengePage