import GameDialog from '../../../presentationals/GameDialog'
import './SolutionDialog.scss'
import background from '../../../assets/UI_popup_dotted.svg'
import { Solution } from '../../../../core/challenge/Challenge'
import buttonTest from '../../../assets/ButtonIcon_test.svg'
import buttonTestDisabled from '../../../assets/ButtonIcon_test_disabled.svg'
import buttonApply from '../../../assets/ButtonIcon_apply.svg'
import label from '../../../assets/UI_label_lightorange.svg'
import labelOrange from '../../../assets/UI_Label_wide_orange.svg'
import crossIconSVG from '../../../assets/CrossIcon.svg'
import { useContext, useState } from 'react'
import ChallengeContext from '../../../contexts/ChallengeContext'
import TestedSolutionDialog from './TestedSolutionDialog'
import ConfirmDialog from './ConfirmDialog'

const ButtonLabel = ({
    content
} : {
    content: string
}) => {
    return <div className="Label__Container">
        <img alt="" src={label}/>
        <div className="Label__Content">
            {content}
        </div>
    </div>
}

const TestButton = ({
    onClick,
    disabled
} : {
    onClick: () => void,
    disabled?: boolean
}) => {
    return <div className={"Button__Container " + (disabled ? "" : "AnimatedButton")}>
        {<img alt="" src={disabled? buttonTestDisabled : buttonTest}/>}
        <div className="Button__Description">TEST</div>
        <button
            onClick={onClick}
            disabled={disabled}
        />
        {!disabled && <ButtonLabel content="COSTS 1 POINT"/>}
        
    </div>
}

const ApplyButton = ({
    onClick 
} : {
    onClick: () => void
}) => {
    return <div className="Button__Container AnimatedButton">
        <img alt="" src={buttonApply}/>
        <div className="Button__Description">APPLY</div>
        <button
            onClick={onClick}
        />
    </div>
}

const SolutionDialog = ({
    open,
    onClose,
    solution,
    onApplySolution
}: {
    open: boolean,
    onClose: () => void,
    solution?: Solution,
    onApplySolution: () => {}
}) => {

    const challengeContext = useContext(ChallengeContext)

    const [testedSolution, setTestedSolution] = useState<Solution|undefined>()
    const [testedSolutionDialogOpened, setTestedSolutionDialogOpened] = useState(false)
    const [confirmDialogOpened, setConfirmDialogOpened] = useState(false)

    const experimentSolution = async () => {
        
        var game = challengeContext.game!
        var challenge = challengeContext.challenge!
        var command = challengeContext.challengeInteractService?.buildExperimentCommand(game, challenge, solution!)
        await command?.execute();

        await challengeContext.reloadChallenge!()

        var testedSolution = challengeContext.challenge!.getSolution(solution!.id)
        setTestedSolution(testedSolution)
        setTestedSolutionDialogOpened(true)
        onClose()
    }

    const onConfirmApply = async () => {
        var game = challengeContext.game!
        var challenge = challengeContext.challenge!
        var command = challengeContext.challengeInteractService?.buildApplyCommand(game, challenge, solution! )

        await command?.execute();
        
        setConfirmDialogOpened(false)
        onApplySolution()
    }

    const applySolution = async () => {
        onClose()
        setConfirmDialogOpened(true)
    }

    return <><GameDialog
        open={open}
        onClose={onClose}
        dialogStyle={{
            height: '80vh',
            width: '40vw'
        }}
    >
        <div className="SolutionDialog__Container">
            <div
                className="SolutionDialog__Wrapper"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <img
                alt=""
                    className="SolutionDialog__ExitIcon"
                    src={crossIconSVG}
                    onClick={onClose}
                />
                {/* <img alt="" src={background}/> */}
                <div className="SolutionDialog__Content">
                    <div className="SolutionDialog__Title">
                        {solution?.title}
                    </div>
                    <div className="SolutionDialog__Description">
                        {solution?.description}
                    </div>
                    <div className="SolutionDialog__Actions">
                        <TestButton
                            onClick={experimentSolution}
                            disabled={solution?.experimented}
                        />
                        <ApplyButton
                            onClick={applySolution}
                        />
                    </div>
                </div>
                {
                    solution?.experimented && 
                    <div className="SolutionDialog__Points">
                        <img width="100%" alt="" src={labelOrange}/>
                        <div className="SolutionDialog__PointsContent">
                            By applying this solution, you will earn <b>{solution?.score}</b> points
                        </div>
                    </div>
                }
            </div>
        </div>
    </GameDialog>
    <TestedSolutionDialog
        open={testedSolutionDialogOpened}
        onClose={() => {
            setTestedSolutionDialogOpened(false)
        }}
        solution={testedSolution}
    />
    <ConfirmDialog
        open={confirmDialogOpened}
        onClose={() => {
            setConfirmDialogOpened(false)
        }}
        onConfirm={() => {
            onConfirmApply()
            setConfirmDialogOpened(false)
        }}
    />
    </>
}

export default SolutionDialog