
import orangeButton from '../../assets/OrangeButton.svg';
import greenButton from '../../assets/ButtonLabel_greenish.svg';
import './style.scss'

const GameButton = ({
    children,
    onClick,
    variant="orange",
    className
} : {
    children: React.ReactElement | React.ReactElement[] | string | (string | React.ReactElement)[],
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    variant?: string,
    className?: string
}) => {

    return <div
        className={"GameButton__Container " + className}
        style={{
            width: variant==="orange" ? '10vw' : '14vw',
            backgroundImage: `url(${variant==="orange" ? orangeButton: greenButton})`,
            backgroundSize: 'cover'
        }}
    >
    
    
    <button
        className="GameButton__Button"
        onClick={onClick}
        style={{
            fontSize: variant==="orange" ? '2em' : '1.4em'
        }}
    >
        {children}
    </button>
    </div>
}

export default GameButton