import background from '../../assets/Bg_Pattern_reddish.svg'
import './index.scss'
import wrongAspect from '../../assets/WrongAspect.svg'
const InvalidAspect = () => {
    


    return <div
        className="InvalidAspect__Container"
        style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover'
        }}
    >
        <div className="InvalidAspect__Content">
        <img alt="" src={wrongAspect} /> <br/>
        Your current aspect ratio is not supported. <br/>Try resizing the page or rotating your device to landscape.
        </div>
    </div>
}

export default InvalidAspect