import { createContext } from "react"
import Challenge from "../../core/challenge/Challenge"
import Game from "../../core/game/Game"
import ChallengeInteractService from "../../core/challenge/ChallengeInteractService"

interface ChallengeContextValue {
    challenge?: Challenge,
    game?: Game,
    challengeInteractService?: ChallengeInteractService,
    reloadChallenge?: () => Promise<void>,
    showChallenge?: () => void,
    showLeaderboard?: () => void,
    setInternalPage?: (page: any) => any
}

const ChallengeContext = createContext<ChallengeContextValue>({})

export default ChallengeContext