import { useContext, useEffect, useState } from "react";
import ChallengeContext from "../../../contexts/ChallengeContext";
import background from '../../../assets/background_blurred.jpg'
import './HalfwayCheck.scss'
import boxOutline from '../../../assets/UI_Box_frame_outline.svg'
import DomainContext from "../../../../contexts/DomainContext";
import { PerformanceResult } from "../../../../core/leaderboard/LeaderboardRepository";
import NextButton from "../../Game/components/Tutorial/NextButton";
import { InternalPages } from "../../Game";

const goodFeedback = "Impressive work! Your expertise shines through, demonstrating invaluable insights as CEO. Fiasco is fortunate to have your leadership."
const normalFeedback = "You're making progress but there is room for improvement. Keep in mind that Fiasco's success relies on your efforts!"
const badFeedback = "You're not making much progress and there's plenty of room for improvement. Keep in mind that Fiasco's success relies on your efforts!"

const HalfwayCheck = () => {
    const challengeContext = useContext(ChallengeContext)
    const domainContext = useContext(DomainContext)

    const [loading, setLoading] = useState(true)

    const [feedback, setFeedback] = useState("")

    const loadFeedback = async () => {
        const res = await domainContext.leaderboardRepository?.checkPerformance(challengeContext.game?.sessionId!, challengeContext.game?.id!)
        if (res === PerformanceResult.GOOD) {
            setFeedback(goodFeedback)
        }
        if (res === PerformanceResult.NORMAL) {
            setFeedback(normalFeedback)
        }
        if (res === PerformanceResult.BAD) {
            setFeedback(badFeedback)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadFeedback()
    }, [])

    return <div
        className="HalfwayCheck__Container"
        style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover'
        }}
    >
        <div className="HalfwayCheck__Content"
        style={{
            backgroundImage: `url(${boxOutline})`,
            backgroundSize: 'cover',
        }}>
            <div className="HalfwayCheck__ContentInner">
                {!loading && <>{feedback}</>}
            </div>
        </div>
        <NextButton
            className="HalfwayCheck__NextButton"
            onClick={() => {
                challengeContext.setInternalPage!(InternalPages.LEADERBOARD)
            }}/>
    </div>
}

export default HalfwayCheck;