import Challenge from "./Challenge";

export default abstract class ChallengeRepository {
    abstract fetchByGameIdAndBlueprintId(gameId: string, challengeBlueprintId: string): Promise<Challenge|null>

    abstract create(challenge: Challenge): Promise<string>;

    abstract update(challenge: Challenge): Promise<void>;

    abstract findSolutionScore(solution_id: string): Promise<number>;

    abstract fetchCompletedBySessionId(session_id: string): Promise<Challenge[]>

    abstract fetchCompletedBySessionIdAndBlueprintId(session_id: string, blueprint_id: string): Promise<Challenge[]>
}