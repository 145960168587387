import { useEffect } from 'react'
import './style.scss'

const GameDialog = ({
  open,
  onClose,
  children,
  blurBackground,
  small,
  big,
  xSmall,
  dialogStyle
}: {
  open: boolean,
  onClose: () => void,
  children: any,
  blurBackground?: boolean,
  small?: boolean,
  big?: boolean,
  xSmall?: boolean,
  dialogStyle?: any
}) => {

  useEffect(() => {
    if (open) {
      (window as any).interactable_map_locked = true
    } else {
      (window as any).interactable_map_locked = false
    }
    return () => {
      (window as any).interactable_map_locked = false
    }
  }, [open])

  return <>{open && <div className="GameDialog__Container">
    <div
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          onClose()
        }
      }}
      className={"GameDialog__Overlay" + (blurBackground ? " GameDialog__Overlay--blurred" : "")}>
      <div
        className={"GameDialog__Dialog "
          + (xSmall ? "GameDialog__Dialog--xSmall" : "")
          + (small ? "GameDialog__Dialog--small" : "")
          + (big ? "GameDialog__Dialog--big" : "")
        }
        style={dialogStyle}
      >
        {children}
      </div>
    </div>
  </div>}
  </>
}

export default GameDialog