import { useState } from "react"
import TextInput from "../../presentationals/inputs/TextInput"
import Papa from 'papaparse';
import useSnackbar from "../../hooks/useSnackbar";

export default function SessionForm({
  onSessionChange,
  onChangePartecipants
}: {
  onSessionChange: (key: string, value: string) => void,
  onChangePartecipants: (partecipants: Array<{
    first_name: string,
    last_name: string,
    user_name: string,
    treatment: string
  }>) => void
}) {
  const { showSnackbar } = useSnackbar();
  const [sessionState, setSessionState] = useState({
    name: '',
    prefix: ''
  })
  const [file, setFile] = useState<any>()

  const dataChange = (key: string, value: string) => {
    setSessionState({
      ...sessionState,
      [key]: value
    })
    onSessionChange(key, value)
  }

  const handlePartecipantsUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      showSnackbar("Select a file", "error")
      return;
    }



    setFile(e.target.value)
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.data.length > Number(process.env.REACT_APP_MAX_PARTICIPANTS)) {
            showSnackbar("Too many participants", "error")
            setFile("")
            return;
          }

          const members = result.data.map((result: any) => ({
            first_name: result["First Name"],
            last_name: result["Last Name"],
            user_name: result["User Name"],
            treatment: result["Group Code"]
          }))

          onChangePartecipants(members as Array<{
            first_name: string,
            last_name: string,
            user_name: string,
            treatment: string
          }>)
        },
        error: (error) => {
          showSnackbar("Error reading csv", "error")
        },
      });
    }
  }

  return <>
    <TextInput
      label="Session name"
      name="session_name"
      value={sessionState.name}
      onChange={(e) => {
        dataChange("name", e.target.value)
      }}
    />
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end'
      }}
    >
      <div>
        <TextInput
          label="Token prefix"
          name="token_prefix"
          value={sessionState.prefix}
          onChange={(e) => {
            dataChange("prefix", e.target.value)
          }}
        />
      </div>
      <div
        style={{
          margin: '20px'
        }}
      >
        Valid characters are <b>A-Z a-z 0-9 _ - </b>
      </div>
    </div>
    <TextInput
      label="Participants"
      name="participants"
      type="file"
      value={file}
      onChange={handlePartecipantsUpload}
    />
  </>
}