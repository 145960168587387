import { ChangeEvent, FC } from "react"

import './style.scss'

export interface Option {
    label: string,
    value: string
}

interface SelectInputProps {
    label: string,
    value: string | number
    name: string,
    options: Option[],
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
    vertical?: boolean
}

const SelectInput: FC<SelectInputProps> = ({
    label,
    value,
    name,
    onChange,
    options,
    vertical
}) => {
    return <div className={"SelectInput__Container " + (vertical ? "SelectInput__Container--vertical" : "") }>
        <label
            htmlFor={label}
            className="SelectInput__Label"
        >{label}</label>
        <select
            className="SelectInput__Input"
            id={label}
            value={value}
            name={name}
            onChange={onChange}
            style={{minWidth: 200}}
        >
            {options?.map((option) => {
                return <option value={option.value}>{option.label}</option>
            } )}
        </select>
    </div>
}

export default SelectInput