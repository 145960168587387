class JwtKeeper {
    private jwt_token: string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhcHAiLCJfY291Y2hkYi5yb2xlcyI6WyJfYWRtaW4iXX0.RIf-6v8xb7z9sJrhTNrH-cDg3tBswEzlalq1DHW9WlM"
    // private jwt_token: string = ""
    

    // constructor(jwt: string) {
    //     this.jwt_token = jwt
    // }

    public SetJwtToken(value: string): void {
        this.jwt_token = value
    }

    public GetJwtToken(): string {
        return this.jwt_token
    }
}
export default JwtKeeper