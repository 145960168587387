import { useContext, useEffect, useState } from "react";
import Card from "../../presentationals/Card";
import DomainContext from "../../../contexts/DomainContext";
import Session from "../../../core/session/Session";
import Table from "../../presentationals/Table";
import SimplePaginator from "../../presentationals/SimplePaginator";
import Button from "../../presentationals/Button";
import { faArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './index.scss'
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";
import useConfirm from "../../hooks/useConfirm";

export default function SessionsTable() {

  const globalContext = useContext(DomainContext)

  const [sessions, setSessions] = useState<Session[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()

  const { showSnackbar } = useSnackbar()
  const showConfirm = useConfirm()

  const perPage = 10

  const deleteSession = async (session: Session) => {
    await globalContext.sessionService.deleteSession(session._id!)
  }

  const loadSessions = () => {
    setLoading(true)
    globalContext.sessionRepository.fetch(currentPage, perPage).then((result) => {
      setSessions(result.rows)
      setTotal(result.total)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadSessions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return <div className="SessionsTable__Container">
   
      {
        loading ?
        <div
          className="SessionsTable__Loading"
        >Loading data...</div>
        :
        <Card
        footer={<div style={{ textAlign: 'right' }}>
          <SimplePaginator
            currentPage={currentPage}
            totalPages={Math.ceil(total / perPage)}
            onChangePage={(newPage) => {
              setCurrentPage(newPage)
            }}
          />
        </div>}
          noPadding
        >
          <Table>
          <thead>
            <tr>
              <th>Session name</th>
              <th>Created at</th>
              <th>Status</th>
              <th>Active challenges</th>
              <th
                style={{
                  textAlign: 'right'
                }}
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => {
              return <tr key={session.id}>
                <td>{session.name}</td>
                <td>{session.created_at?.toLocaleString()}</td>
                <td>{session.status}</td>
                <td>{session.activeChallengesIds.length}</td>
                <td className="SessionsTable__Actions">
                  <Button
                    onClick={() => {
                      showConfirm(`Are you sure you want to delete "${session.name}" session?`, async () => {
                        await deleteSession(session)
                        showSnackbar("Session deleted")
                        loadSessions()
                      })
                    }}
                    danger><FontAwesomeIcon icon={faTrash} /></Button>
                  <Button
                    onClick={() => {
                      // router.push('/admin/sessions/'+ session.id)
                      navigate('/sessions/' + session.id)
                    }}
                  ><FontAwesomeIcon icon={faArrowRight} /></Button>
                </td>
              </tr>
            })}
            {
              sessions.length === 0 && <tr>
                <td
                  colSpan={5}
                  style={{
                    textAlign: 'center'
                  }}
                >No sessions found</td>
              </tr>
            }
          </tbody>
        </Table>

      </Card>
      }
  </div>
}