import { Outlet } from "react-router-dom";
import './GameLayout.scss'
import InvalidAspect from "../pages/InvalidAspect";
import { useEffect, useState } from "react";

export default function GameLayout () {
    const [invalidAspect, setInvalidAspect] = useState(false)
    useEffect(() => {
        checkInvalidAspect()
        window.removeEventListener("resize", checkInvalidAspect)
        window.addEventListener("resize",checkInvalidAspect);
    }, [])

    const checkInvalidAspect = () => {
        if (window.innerWidth/window.innerHeight < 1.2) {
            setInvalidAspect(true)
        } else {
            setInvalidAspect(false)
        }
    }
    return <div className="GameLayout">
        {invalidAspect && <InvalidAspect/>}
        <Outlet/>
    </div>
}