import { createContext } from "react";

export const defaultConfirmContext = {
    confirmOpen: false,
    confirmMessage: "",
    confirmCallback: async () => null
}

const ConfirmContext = createContext({
    confirmContext: defaultConfirmContext,
    setConfirmContext: (ctx: any) => {}
})

export default ConfirmContext;