import { Outlet } from "react-router-dom";
import './layout.scss'

import SnackbarContext, { defaultSnackbarContext } from "../../contexts/SnackbarContext";
import { useState } from "react";
import Snackbar from "../../presentationals/Snackbar";
import ConfirmContext, { defaultConfirmContext } from "../../contexts/ConfirmContext";
import Confirm from "../../widgets/Confirm";
import background from '../../../game/assets/Bg_Pattern_yellow.svg'

const checkLoggedIn = () => {

  var storageItem = "fiasco_admin_key"

  var valid_key = 'fiasco_pbac'

  var saved_key = sessionStorage.getItem(storageItem)
  if (saved_key !== valid_key) {
    // Saved key is invalid or no saved key
    sessionStorage.removeItem(storageItem)
    var provided_key = prompt("Insert admin password")
    if (provided_key !== valid_key) {
      // Provided key is wrong
      return false
    } else {
      // Provided key is ok
      sessionStorage.setItem(storageItem, provided_key)
      return true
    }
  } else {
    // Saved key is valid
    return true
  }
}

export default function Layout({
  header,
  footer
}: {
  header: React.ReactElement,
  footer: React.ReactElement
}) {
  const [snackbarContext, setSnackbarContext] = useState(defaultSnackbarContext)
  const [confirmContext, setConfirmContext] = useState(defaultConfirmContext)

  var loggedIn = checkLoggedIn()

  if (!loggedIn) {
    return <div
    className="SessionsLayout__Body"
    style={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat',
      height: '100vh',
      paddingTop: '20%',
      textAlign: 'center',
      fontFamily: 'ShantelleSans',
      fontSize: '22px'
    }}
  >Not logged in. Refresh the page to login.</div>
  }

  return <SnackbarContext.Provider value={
    {
      snackbarContext,
      setSnackbarContext
    }
  }>
    <ConfirmContext.Provider value={
      {
        confirmContext,
        setConfirmContext
      }
    }>
      <div className="SessionsLayout__Container background">
        <div className="SessionsLayout__Header">
          {header}
        </div>
        <div
          className="SessionsLayout__Body"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat'
          }}
        >
          <Outlet />
        </div>
        <div className="SessionsLayout__Footer">
          <div>Fiasco Admin panel v.1.0.0</div>
          <div></div>
        </div>
      </div>
      <Snackbar />
      <Confirm />
    </ConfirmContext.Provider>
  </SnackbarContext.Provider>
}