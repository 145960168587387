import { useContext, useEffect, useState } from 'react'
import Challenge from '../../../core/challenge/ChallengeBlueprint'
import Button from '../../presentationals/Button'
import Card from '../../presentationals/Card'
import Table from '../../presentationals/Table'
import './style.scss'
import DomainContext from '../../../contexts/DomainContext'

export default function Challenges({
  initActiveChallengesIds,
  initOnceActiveChallengesIds,
  onActivateChallenge,
  onDeactivateChallenge
}: {
  initActiveChallengesIds: string[],
  initOnceActiveChallengesIds: string[],
  onActivateChallenge: (id: string) => void,
  onDeactivateChallenge: (id: string) => void
}) {

  const globalContext = useContext(DomainContext)
  const [challenges, setChallenges] = useState<(Challenge & { isActive: boolean, isDeactivated: boolean })[]>([])

  useEffect(() => {
    loadChallenges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadChallenges = async () => {
    const challs = await globalContext.challengeRepositoryBlueprint.fetch()
    const challsWithActiveStatus = challs.map(challenge => {
      return {
        ...challenge,
        isActive: initActiveChallengesIds.includes(challenge.id),
        isDeactivated: !initActiveChallengesIds.includes(challenge.id) && initOnceActiveChallengesIds?.includes(challenge.id)
      }
    })
    setChallenges(challsWithActiveStatus)
  }

  const activateChallenge = (challenge: Challenge & { isActive: boolean, isDeactivated: boolean }) => {
    onActivateChallenge(challenge.id)
    challenge.isActive = true
    challenge.isDeactivated = false
    setChallenges([...challenges])
  }

  const deactivateChallenge = (challenge: Challenge & { isActive: boolean, isDeactivated: boolean }) => {
    onDeactivateChallenge(challenge.id)
    challenge.isActive = false
    challenge.isDeactivated = true
    setChallenges([...challenges])
  }

  return <div className="Challenges__Container">
    <Card noPadding>
      <Table>
        <thead>
          <tr>
            <th>
              Challenge name
            </th>
            <th>
              Status
            </th>
            <th style={{ textAlign: 'right' }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {challenges.map((challenge) => {

            const challengeActive = challenge.isActive
            const challengeDeactivated = challenge.isDeactivated


            return <tr key={challenge.id}>
              <td>
                {challenge.title}
              </td>
              <td>
                {challengeActive &&
                  <div className="Challenges__ActiveLabel">Active</div>
                }
                {
                  challengeDeactivated &&
                  <div className="Challenges__DeactivatedLabel">Deactivated</div>
                }
                {
                  (!challengeActive && !challengeDeactivated) &&
                  <div className="Challenges__InactiveLabel">Inactive</div>
                }
              </td>
              <td className="Challenges__Actions">
                {challengeActive ?
                  <Button
                    onClick={() => deactivateChallenge(challenge)}
                  >
                    Deactivate
                  </Button>
                  :
                  <Button
                    onClick={() => activateChallenge(challenge)}
                  >
                    Activate
                  </Button>
                }
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </Card>
  </div>
}