import boxWrapperSVG from '../../assets/BoxWrapper_yelloish.svg';
import boxHeaderSVG from '../../assets/BoxHeader_orange.svg'
import crossIconSVG from '../../assets/CrossIcon.svg'
import './style.scss'

const DialogBox = ({
    small,
    title,
    children,
    buttons,
    onClose
}: {
    small?: boolean,
    title?: string,
    children: any,
    buttons?: any,
    onClose?: () => void
}) => {
    return <div
        className="DialogBox__Container"
    >
        {title && <div
            className="DialogBox__Header"
        >
            <img alt="" src={boxHeaderSVG}/>
            <img
                alt=""
                className="DialogBox__ExitIcon"
                src={crossIconSVG}
                onClick={onClose}
            />
            <div
                className="DialogBox__HeaderContent"
            >
                <div
                    className="DialogBox__HeaderContentTitle"
                >
                    {title}
                </div>
            </div>
        </div>}
        <div
            className={"DialogBox__Wrapper " + (small ? "DialogBox__WrapperSmall" : "")}
            style={{
                marginTop: title? "-2vw" : "0"
            }}       
        >
            <img alt="" src={boxWrapperSVG}/>
            <div
                className="DialogBox__WrapperContent"
            >
                {children}
            </div>
        </div>
        <div className="DialogBox__Actions">
            {buttons}
        </div>
       
       
    </div>
}

export default DialogBox