import React from 'react';
import './style.scss';

interface ToggleSwitchProps {
  label: string;
  onToggle: any;
  isChecked: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, isChecked, onToggle }) => {
  
    const handleToggle = () => {
      onToggle()
    };
  
    return (
      <div className="ToggleInput__Container">
        <label className="ToggleInput__Switch">
          <input type="checkbox" checked={isChecked} onChange={handleToggle} />
          <span className={"ToggleInput__Slider " + (isChecked ? 'ToggleInput__Slider--checked' : "")}></span>
        </label>
        <span className="ToggleInput__Label">{label}</span>
      </div>
    );
  };
  
  export default ToggleSwitch;