import Game from "../game/Game";
import SessionRepository from "../session/SessionRepository";
import ChallengeRepository from "./ChallengeRepository";
import Challenge from "./Challenge";
import ChallengeBlueprintRepository from "./ChallengeBlueprintRepository";
import getShuffle from "../utils/shuffleArray";

export enum RetrieveChallengeOutcome {
  NO_ACTIVE_CHALLENGES,
  ALL_CHALLENGES_COMPLETED
}

export default class ChallengeRetrieveService {

  constructor(
    private sessionRepository: SessionRepository,
    private challengeRepository: ChallengeRepository,
    private challengeBlueprintRepository: ChallengeBlueprintRepository
  ) {

  }

  public async retrieveOrBuildChallenge(game: Game): Promise<Challenge | RetrieveChallengeOutcome> {
    const session = await this.sessionRepository.restoreSession(game.sessionId)

    if (session.activeChallengesIds.length === 0) {
      return RetrieveChallengeOutcome.NO_ACTIVE_CHALLENGES
    }

    const notCompletedChallenges = session.activeChallengesIds.filter(activeChallenge => {
      return !game.challengesPlayed.includes(activeChallenge)
    })

    if (notCompletedChallenges.length === 0) {
      return RetrieveChallengeOutcome.ALL_CHALLENGES_COMPLETED
    }

    const nextChallengeBlueprintId = notCompletedChallenges.sort((a: string, b: string) => {
      return parseInt(a) > parseInt(b) ? 1 : -1
    })[0]

    let challenge = await this.challengeRepository.fetchByGameIdAndBlueprintId(game.id, nextChallengeBlueprintId);

    if (challenge === null) {
      challenge = await this.buildChallenge(game, nextChallengeBlueprintId)
    }

    return challenge;
  }

  private async buildChallenge(game: Game, challengeBlueprintid: string) {

    const challengeBlueprint = await this.challengeBlueprintRepository.find(challengeBlueprintid)

    const blueprintSolutions = challengeBlueprint?.solutions;

    const solutions = getShuffle(blueprintSolutions!)

    let i = 1;
    for (var solution of solutions) {
      solution.number = i.toString()
      i++;
    }

    const challenge = new Challenge(game.id, challengeBlueprintid, solutions)

    challenge.number = challengeBlueprint?.number!
    challenge.title = challengeBlueprint?.title!
    challenge.video = challengeBlueprint?.video!
    challenge.map = challengeBlueprint?.map!
    challenge.description = challengeBlueprint?.description!
    challenge.area = challengeBlueprint?.area!
    challenge.hint = challengeBlueprint?.hint!

    // Data duplication for debrief
    challenge.treatment = game.treatment
    challenge.session_id = game.sessionId

    await this.challengeRepository.create(challenge)

    return challenge

  }

}