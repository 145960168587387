export class Solution {
  public experimented: boolean
  public applied: boolean



  public score: number | undefined

  constructor(
    public id: string,
    public title: string,
    public description: string,
    /**
     * Progressive number shown to the user
     */
    public number: string
  ) {
    this.experimented = false
    this.applied = false
  }


  // private isScoreVisible(): boolean {
  //     return this.experimented || this.applied
  // }

  public experiment(score: number) {
    this.experimented = true
    this.score = score
  }

  public apply(score: number) {
    this.applied = true
    this.score = score
  }
}

export default class Challenge {

  public _id: string | undefined = ""

  public _rev: string | undefined

  public solutions: Solution[] = []

  public gameId: string = ""

  public session_id: string = ""

  public challengeBlueprintId: string = ""

  public acknowledged: boolean = false

  public started_at: Date | undefined

  public number: string = ""

  public title: string = ""

  public video: string = ""

  public map: string = ""

  public description: string = ""

  public area: string = ""

  public hint: string = ""

  public experimentsDone: number = 0

  public grossScore: number = 0
  public netScore: number = 0

  public treatment: string | null = null

  public currentActionIndex: number = 0

  public completed: boolean = false

  constructor(
    gameId: string,
    challengeBlueprintId: string,
    solutions: Array<any>
  ) {
    this.gameId = gameId

    this.challengeBlueprintId = challengeBlueprintId

    this.solutions = solutions.map(solution => {
      return new Solution(
        solution.id,
        solution.title,
        solution.description,
        solution.number
      )
    })
  }

  public get appliedSolution(): Solution {
    var solution = this.solutions.find(solution => {
      return solution.applied === true
    })

    if (solution) {
      return solution;
    }

    throw new Error("No solution applied")
  }

  public acknowledge(): void {
    this.acknowledged = true
    this.currentActionIndex++
    this.started_at = new Date()
  }

  public applySolution(solutionId: string, score: number): void {
    if (this.hasSolutionsApplied()) {
      throw new Error("Another solution has been applied for this challenge")
    }

    const solutionToApply = this.getSolution(solutionId)

    if (!solutionToApply) {
      throw new Error("Cannot find solution with that id")
    }

    solutionToApply.apply(score)
    this.currentActionIndex++
    this.netScore += score
    this.grossScore = score
    this.completed = true
  }

  public experimentSolution(solutionId: string, score: number) {
    const solutionToExperiment = this.getSolution(solutionId)

    if (!solutionToExperiment) {
      throw new Error("Cannot find solution with that id")
    }

    if (solutionToExperiment.experimented) {
      throw new Error("Solution already experimented")
    }

    solutionToExperiment.experiment(score)
    this.experimentsDone++;
    this.currentActionIndex++
    this.netScore -= 1
  }

  public getSolution(solutionId: string) {
    return this.solutions.find(solution => solution.id === solutionId)
  }

  public hasSolutionsApplied() {
    for (const solution of this.solutions) {
      if (solution.applied) {
        return true
      }
    }

    return false
  }

  public static dump(challenge: Challenge) {
    return {
      _id: challenge._id,
      _rev: challenge._rev,
      number: challenge.number,
      title: challenge.title,
      video: challenge.video,
      map: challenge.map,
      description: challenge.description,
      area: challenge.area,
      hint: challenge.hint,
      challenge_blueprint_id: challenge.challengeBlueprintId,
      game_id: challenge.gameId,
      session_id: challenge.session_id,
      acknowledged: challenge.acknowledged,
      experimentsDone: challenge.experimentsDone,
      currentActionIndex: challenge.currentActionIndex,
      started_at: challenge.started_at?.toISOString(),
      netScore: challenge.netScore,
      grossScore: challenge.grossScore,
      treatment: challenge.treatment,
      completed: challenge.completed,
      solutions: challenge.solutions.map(solution => ({
        id: solution.id,
        title: solution.title,
        description: solution.description,
        experimented: solution.experimented,
        applied: solution.applied,
        score: solution.score,
        number: solution.number
      }))
    }
  }

  public static restore(datas: any, toChallenge: Challenge) {
    toChallenge._id = datas._id
    toChallenge._rev = datas._rev
    toChallenge.number = datas.number
    toChallenge.title = datas.title
    toChallenge.video = datas.video
    toChallenge.map = datas.map
    toChallenge.description = datas.description
    toChallenge.area = datas.area
    toChallenge.hint = datas.hint
    toChallenge.challengeBlueprintId = datas.challenge_blueprint_id
    toChallenge.gameId = datas.game_id
    toChallenge.session_id = datas.session_id
    toChallenge.acknowledged = datas.acknowledged
    toChallenge.experimentsDone = datas.experimentsDone
    toChallenge.started_at = new Date(datas.started_at)
    toChallenge.currentActionIndex = datas.currentActionIndex
    toChallenge.netScore = datas.netScore
    toChallenge.grossScore = datas.grossScore
    toChallenge.treatment = datas.treatment
    toChallenge.completed = datas.completed
    toChallenge.solutions = datas.solutions.map((solution: any) => {

      var solutionObject = new Solution(
        solution.id,
        solution.title,
        solution.description,
        solution.number)
      solutionObject.experimented = solution.experimented
      solutionObject.applied = solution.applied
      solutionObject.score = solution.score
      return solutionObject
    })
  }
}