import { useContext, useState } from 'react'
import './AreaSelector.scss'
import ChallengeContext from '../../../contexts/ChallengeContext'
import checkButton from '../../../assets/Icon_Check.svg';
import checkDisabledButton from '../../../assets/Icon_check_disabled.svg';
import selectTall from '../../../assets/UI_Select_Tall.svg'
import areaLeft from '../../../assets/AreaLeft.svg'
import areaRight from '../../../assets/AreaRight.svg'
const AreaSwitcher = ({
  selectedAreaLabel,
  nextArea,
  prevArea,
}: {
  selectedAreaLabel: string
  nextArea: () => void,
  prevArea: () => void,
}) => {

  return <div className="AreaSwitcher__Container">

    <div
      className="AreaSwitcher__Button AreaSwitcher__Left"
      onClick={e => {
        e.preventDefault()
        prevArea()
      }}
      style={{
        backgroundImage: `url(${areaLeft})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        aspectRatio: 1
      }}
    />

    <div
      className="AreaSwitcher__AreaContainer"
      style={{
        backgroundImage: `url(${selectTall})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '14vw',
        aspectRatio: 4,
      }}
    >
      <div className="AreaSwitcher__Area"
      >{selectedAreaLabel}</div>
    </div>
    <div
      className="AreaSwitcher__Button AreaSwitcher__Right"
      onClick={e => {
        e.preventDefault()
        nextArea()
      }}
      style={{
        backgroundImage: `url(${areaRight})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        aspectRatio: 1
      }}
    />
  </div>
}

const areas = [
  {
    "value": "",
    "label": "Select an area"
  },
  {
    "value": "bottling",
    "label": "Bottling Area"
  },
  {
    "value": "meeting",
    "label": "Meeting Room"
  },
  {
    "value": "marketing",
    "label": "Marketing Office"
  },
  {
    "value": "administration",
    "label": "Administration Office"
  },
  {
    "value": "fermentation",
    "label": "Fermentation and Wine Ageing"
  },
  {
    "value": "shipping",
    "label": "Stocking and Shipping"
  },
  {
    "value": "weighing",
    "label": "Weighing and Destemming"
  },
  {
    "value": "dressing",
    "label": "Dressing Room"
  },
  {
    "value": "retail",
    "label": "Retail Sale Area"
  },
  {
    "value": "vineyard",
    "label": "Vineyard"
  }
]

const AreaSelector = (
  {
    onGuessArea,
    onWrongArea,
    onHighlightArea
  }: {
    onGuessArea: () => any,
    onWrongArea: () => any,
    onHighlightArea: (area: string) => any
  }) => {
  const [currentAreaIndex, setCurrentAreaIndex] = useState(0);
  const [selectedArea, setSelectedArea] = useState(areas[currentAreaIndex].value)
  const challengeContext = useContext(ChallengeContext)


  const prevArea = () => {

    var nextAreaIndex = currentAreaIndex - 1

    if (nextAreaIndex <= 0) {
      nextAreaIndex = areas.length - 1
    }


    setCurrentAreaIndex(nextAreaIndex)
    setSelectedArea(areas[nextAreaIndex].value)
    onHighlightArea(areas[nextAreaIndex].value)
  }

  const nextArea = () => {

    var nextAreaIndex = currentAreaIndex + 1

    if ((currentAreaIndex + 1) >= areas.length) {
      nextAreaIndex = 0;
    }

    // Skip the "Select an area" option
    if (nextAreaIndex === 0) {
      nextAreaIndex++;
    }

    setCurrentAreaIndex(nextAreaIndex)
    setSelectedArea(areas[nextAreaIndex].value)
    onHighlightArea(areas[nextAreaIndex].value)
  }

  const selectArea = () => {
    if (selectedArea === challengeContext.challenge?.area) {
      onGuessArea()
    } else {
      onWrongArea()
    }
  }

  return <div className="AreaSelector__Container">
    <div className="AreaSelector__Title">
      Where is the issue?
    </div>
    <div className="AreaSelector__LeftSideBottom">
      <AreaSwitcher
        selectedAreaLabel={areas[currentAreaIndex].label}
        prevArea={prevArea}
        nextArea={nextArea}
      />
    </div>
    <div className="AreaSelector__ApplyButton">
      {
        areas[currentAreaIndex].value === "" ?
          <div style={{
            backgroundImage: `url(${checkDisabledButton})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '3vw',
            aspectRatio: 1
          }} />
          :
          <div
            className="AreaSelector__ButtonOk"
            onClick={selectArea}
            style={{
              backgroundImage: `url(${checkButton})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '3vw',
              aspectRatio: 1
            }}
          />
      }

    </div>
  </div>
}

export default AreaSelector