import { FunctionComponent } from "react";

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload, activeGroups } = props;

  const active = activeGroups.includes(props.payload.value)

  return (
    <g
      transform={`translate(${x},${y + 13})`}
    >
      <text
        x={0}
        y={0}
        textAnchor="middle"
        fill={active ? "#666" : '#aaaaaa'}
      >
        {payload.value}
      </text>
    </g>
  );
};

export default CustomizedAxisTick