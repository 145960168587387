import React from "react";
import "./style.scss"

export default function Button ({
    children,
    onClick,
    className = "",
    danger,
    disabled,
    icon,
    outline,
    title,
    inactive
}: {
    children: React.ReactElement | React.ReactElement[] | string | (string | React.ReactElement)[],
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    className?: string,
    danger?: boolean,
    disabled?: boolean,
    icon?: React.ReactElement,
    outline?: boolean,
    title?: string,
    inactive?:boolean
}) {

    const buttonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!disabled && typeof(onClick) !== 'undefined') {
            onClick(e);
        }
    }

    return <button
        title={title}
        className={"Button "
            + className
            + (danger ? ' Button--danger' : '')
            + (disabled ? ' Button--disabled' : '')
            + (inactive ? ' Button--inactive' : '')
            + (outline ? ' Button--outline' : '')}
        onClick={buttonClick}>
            {icon && <p className="Button__Icon">{icon}</p>}
        {children}
    </button>
}