import Game from "../Game";
import GameRepository from "../GameRepository";


export default class DoTutorialCommand {
    constructor (
        private gameRepository: GameRepository,
        private game: Game
    ) {

    }

    public async execute(): Promise<void> {
        this.game.doTutorial()
        await this.gameRepository.update(this.game)
    }
}