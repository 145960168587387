// import ChallengeRepositoryBlueprintFile from "../core/adapters/file/ChallengeRepositoryBlueprintFile";
import SessionRepositoryPouchDB from "../core/adapters/pouchdb/SessionRepositoryPouchDB";
import GameRepositoryPouchDB from "../core/adapters/pouchdb/GameRepositoryPouchDB";

import { createContext } from "react";

import SessionInitializer from "../core/session/SessionInitializer";
import LeaderbaordRepositoryPouchDB from "../core/adapters/pouchdb/LeaderboardRepositoryPouchDB";
import JwtKeeper from "../core/adapters/pouchdb/JwtKeeper";
import SessionService from "../core/session/SessionService";

import PouchDB from 'pouchdb'

import FindPlugin from "pouchdb-find";
import ChallengeRetrieveService from "../core/challenge/ChallengeRetrieveService";
import ChallengeRepositoryPouchDB from "../core/adapters/pouchdb/ChallengeRepositoryPouchDB";
import ChallengeInteractService from "../core/challenge/ChallengeInteractService";
import GameInteractService from "../core/game/GameInteractService";
import ChallengeBlueprintRepositoryPouchDB from "../core/adapters/pouchdb/ChallengeRepositoryBlueprintPouchDB";
import ResearchDataRepositoryPouchDB from "../core/adapters/pouchdb/ResearchDataRepositoryPouchDB";
import DownloadService from "../core/download/DownloadService";
PouchDB.plugin(FindPlugin)

export const buildDomainContext = (jwtToken: string) => {
  var jwtKeeper = new JwtKeeper()
  // jwtKeeper.SetJwtToken(jwtToken)

  const sessionRepository = new SessionRepositoryPouchDB(jwtKeeper);
  const challengeBlueprintRepository = new ChallengeBlueprintRepositoryPouchDB(jwtKeeper);
  const gameRepository = new GameRepositoryPouchDB(jwtKeeper);
  const leaderboardRepository = new LeaderbaordRepositoryPouchDB(jwtKeeper);
  const challengeRepository = new ChallengeRepositoryPouchDB(jwtKeeper)
  const researchDataRepository = new ResearchDataRepositoryPouchDB(jwtKeeper)

  const challengeInteractService = new ChallengeInteractService(
    challengeRepository,
    gameRepository,
    leaderboardRepository,
    researchDataRepository
  )
  const gameInteractService = new GameInteractService(
    gameRepository,
    leaderboardRepository
  )
  const downloadService = new DownloadService(
    gameRepository,
    researchDataRepository
  )

  const defaultContext = {
    sessionInitializer: new SessionInitializer(
      sessionRepository,
      gameRepository,
      leaderboardRepository
    ),
    sessionService: new SessionService(
      sessionRepository,
      gameRepository,
      leaderboardRepository
    ),
    challengeRetrieveService: new ChallengeRetrieveService(
      sessionRepository,
      challengeRepository,
      challengeBlueprintRepository
    ),
    sessionRepository: sessionRepository,
    challengeRepositoryBlueprint: challengeBlueprintRepository,
    researchDataRepository: researchDataRepository,
    gameRepository: gameRepository,
    challengeRepository: challengeRepository,
    leaderboardRepository: leaderboardRepository,
    challengeInteractService: challengeInteractService,
    gameInteractService: gameInteractService,
    downloadService: downloadService
  }

  return defaultContext
}



const DomainContext = createContext(buildDomainContext(""))

export default DomainContext;