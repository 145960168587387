import DialogBox from '../../presentationals/DialogBox'
import GameDialog from '../../presentationals/GameDialog'
import defaultBg from './Bg_Pattern_yellow.svg'
import map from './default.png'
import './style.scss'
import infoIcon from '../../assets/Icon_info.svg'
import { useContext } from 'react'
import ChallengeContext from '../../contexts/ChallengeContext'
import GameButton from '../../presentationals/GameButton'

const NoChallengePage = () => {

  const challengeContext = useContext(ChallengeContext)
  return <div
    className="NoChallengePage__Container"
    style={{
      backgroundImage: `url(${defaultBg})`,
      backgroundSize: 'cover'
    }}
  >
    <img alt="" src={map} className="NoChallengePage__Image" />
    <GameDialog
      onClose={() => {
        //cannot
      }}
      open
      small
    ><DialogBox
      small>
        <div className="NoChallengePage__MessageContainer">
          <div className="NoChallengePage__Icon">
            <img alt="" src={infoIcon} />
          </div>
          <div className="NoChallengePage__Message">
            There are no active challenges.
          </div>
          <br /><br />
          <GameButton
            onClick={() => {
              challengeContext.setInternalPage!("leaderboard")
            }}
          ><span
            style={{
              fontSize: '.8em',
            }}
          >leaderboard</span></GameButton>
        </div>
      </DialogBox></GameDialog>
  </div>
}

export default NoChallengePage;