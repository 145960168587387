import Challenge from "../challenge/Challenge";
import ChallengeRepository from "../challenge/ChallengeRepository";

export interface GroupData {
  name: string,
  value: number,
  challenges: number
}

export interface GameData {
  name: string,
  treatment: string,
  score: number,
  experiments: number
}

export default class DebriefService {
  constructor(
    private challengeRepository: ChallengeRepository
  ) {

  }

  public async fetchGamesData(
    session_id: string
  ) {
    const sessionChallenges = await this.challengeRepository.fetchCompletedBySessionId(session_id)

    return this.buildGamesData(sessionChallenges)
  }

  public async fetchGroupData(
    session_id: string,
    selectedChallenge: string,
    key: 'netScore' | 'grossScore' | 'experimentsDone',
    allGroups: string[]
  ) {
    // A map reduce index could be used
    var sessionChallenges
    if (selectedChallenge !== "") {
      sessionChallenges = await this.challengeRepository.fetchCompletedBySessionIdAndBlueprintId(session_id, selectedChallenge)
    } else {
      sessionChallenges = await this.challengeRepository.fetchCompletedBySessionId(session_id)
    }

    var groupData = this.buildGroupData(sessionChallenges, key)

    var groupsToReturn = []

    for (const group of allGroups) {
      var groupInGroupData = groupData.find(g => g.name === group)
      if (groupInGroupData) {
        groupsToReturn.push(groupInGroupData)
      } else {
        groupsToReturn.push({
          name: group,
          value: 0,
          challenges: 0
        })
      }
    }
    return groupsToReturn
  }

  protected buildGamesData(
    challenges: Challenge[]
  ) {
    var returnGames: GameData[] = []

    var gameIndex: {
      [index: string]: number
    } = {}

    var gameChallenges: {
      [index: string]: number
    } = {}

    for (var challenge of challenges) {
      if (Object.keys(gameIndex).includes(challenge.gameId)) {
        var existingGame = returnGames[gameIndex[challenge.gameId]]

        existingGame.score += challenge.grossScore
        existingGame.experiments += challenge.experimentsDone

        gameChallenges[challenge.gameId]++
      } else {
        gameIndex[challenge.gameId!] = returnGames.length

        gameChallenges[challenge.gameId] = 1

        returnGames.push({
          name: challenge.gameId,
          score: challenge.grossScore,
          experiments: challenge.experimentsDone,
          treatment: challenge.treatment!
        })
      }
    }

    // Average
    for (var returnGame of returnGames) {
      returnGame.score = returnGame.score / gameChallenges[returnGame.name]
      returnGame.experiments = returnGame.experiments / gameChallenges[returnGame.name]
    }

    return returnGames
  }

  protected buildGroupData(
    challenges: Challenge[],
    sumField: 'netScore' | 'grossScore' | 'experimentsDone'
  ) {
    var returnGroups: GroupData[] = []

    var groupIndex: {
      [index: string]: number
    } = {}

    var groupChallenges: {
      [index: string]: number
    } = {}

    for (var challenge of challenges) {
      if (Object.keys(groupIndex).includes(challenge.treatment!)) {
        var existingGroup = returnGroups[groupIndex[challenge.treatment!]]

        existingGroup.value += challenge[sumField]
        existingGroup.challenges++;

        groupChallenges[challenge.treatment!]++
      } else {
        groupIndex[challenge.treatment!] = returnGroups.length

        groupChallenges[challenge.treatment!] = 1

        returnGroups.push({
          name: challenge.treatment!,
          value: challenge[sumField],
          challenges: 1
        })
      }
    }

    // Average
    for (var returnGroup of returnGroups) {
      returnGroup.value = returnGroup.value / groupChallenges[returnGroup.name]
    }

    return returnGroups
  }
}