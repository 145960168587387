import GameDialog from '../../../../presentationals/GameDialog'
import bg from '../../../../assets/IssueDesc_bg.svg'
import './TutorialDialog.scss'
import NextButton from './NextButton'
import { useContext, useState } from 'react'
import DomainContext from '../../../../../contexts/DomainContext'
import ChallengeContext from '../../../../contexts/ChallengeContext'

import areaOneSvg from '../../../../assets/Area_one.svg'
import areaTwoSvg from '../../../../assets/Area_two.svg'
import areaThreeSvg from '../../../../assets/Area_three.svg'
import crossIconSVG from '../../../../assets/CrossIcon.svg'

import boxWrapper from '../../../../assets/BoxWrapper_yelloish.svg'

const TutorialDialog = ({
  open,
  withClose = false,
  onClose
}: {
  open: boolean,
  withClose?: boolean
  onClose: () => void
}) => {

  const domainContext = useContext(DomainContext)
  const challengeContext = useContext(ChallengeContext)

  const [currentPage, setCurrentPage] = useState(1)

  const onTutorialDone = () => {
    setCurrentPage(1);
    const game = challengeContext.game!;
    const command = domainContext.gameInteractService.buildDoTutorialCommand(game);
    command.execute()
  }

  const next = () => {
    if (currentPage === 2) {
      onTutorialDone()
      onClose()
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  return <GameDialog
    open={open}
    onClose={() => {
      // Don't close with overlay click
    }}
    blurBackground
    big
    dialogStyle={{
      height: '100%',
      width: '100%',
      padding: 0
    }}
  >
    {withClose && <img
      alt=""
      className="TutorialDialog__ExitIcon"
      src={crossIconSVG}
      onClick={() => {
        onClose()
      }}
    />}

    <div className="TutorialDialog__Container">

      <div className="TutorialDialog__Content">
        {currentPage === 1 &&
          <div className="TutorialDialog__Column"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >

            <h3>Welcome to Fiasco!</h3>
            <p className="TutorialDialog__Intro">
              Our company was founded in 1912 by Vincenzo Fiasco. Amidst the picturesque landscapes of Sicily, we are renowned for three wine varieties: Nerello Mascalese, Nero d’Avola, and Frappato.
              <br />
              Proudly positioned as one of the leading companies in the European red wine market, Fiasco consistently ranks among the top 10 in terms of profitability.
              <br />
              Our company is divided into three areas, each with its functions for operational excellence:
            </p>
            <div className="TutorialDialog__Areas">

              <div className="TutorialDialog__AreaGroup">
                <div
                  className="TutorialDialog__Area"
                  style={{
                    backgroundImage: `url(${boxWrapper})`,
                    backgroundSize: 'cover',
                    transform: 'rotate(-2deg)'
                  }}
                >
                  <div className="TutorialDialog__AreaHeader">
                    <img alt="" src={areaOneSvg} />
                    <div className="TutorialDialog__AreaTitle">Production</div>
                  </div>
                  <div className="TutorialDialog__AreaDesc">
                    <span className="TutorialDialog__Location">Vineyard:&nbsp;</span>
                    the heart of our business, which we run with the help of a team of both full-time and seasonal employees.<br />
                    <span className="TutorialDialog__Location">Weighing and Destemming:&nbsp;</span>
                    where we process the grapes from the harvest.<br />
                    <span className="TutorialDialog__Location">Fermentation and Wine Ageing:&nbsp;</span>

                    where the magic happens! Our production team carefully checks and regulates the fermentation and ageing process.<br />
                    <span className="TutorialDialog__Location">Bottling:&nbsp;</span>

                    a highly automated department with our best-specialised workers.<br />
                    <span className="TutorialDialog__Location">Stocking and Shipping:&nbsp;</span>

                    where we ensure our product gets to our customers.
                  </div>
                </div>
              </div>
              <div className="TutorialDialog__AreaGroup">
                <div
                  className="TutorialDialog__Area"
                  style={{
                    backgroundImage: `url(${boxWrapper})`,
                    backgroundSize: 'cover',
                    transform: 'rotate(2deg)'
                  }}
                >
                  <div className="TutorialDialog__AreaHeader"> <img alt="" src={areaTwoSvg} />
                    <div className="TutorialDialog__AreaTitle">Marketing</div></div>

                  <div className="TutorialDialog__AreaDesc">
                    <span className="TutorialDialog__Location">Marketing Team:&nbsp;</span>
                    the place for our creative minds.<br />
                    <span className="TutorialDialog__Location">Retail sale and Wine Tasting area:&nbsp;</span>

                    where we share our story with tourists over a glass of wine.
                  </div>
                </div>
                <div
                  className="TutorialDialog__Area"
                  style={{
                    backgroundImage: `url(${boxWrapper})`,
                    backgroundSize: 'cover',
                    transform: 'rotate(1deg)'
                  }}>
                  <div className="TutorialDialog__AreaHeader">
                    <img alt="" src={areaThreeSvg} />
                    <div className="TutorialDialog__AreaTitle">Administration</div>
                  </div>
                  <div className="TutorialDialog__AreaDesc">
                    <span className="TutorialDialog__Location">Accounting & Finance:&nbsp;</span>

                    our team of analysts and accountants.
                  </div>
                </div>
              </div>
            </div>



          </div>
        }
        {currentPage === 2 &&
          <div
            className="TutorialDialog__Column TutorialDialog__Instructions"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <h3>Your role & instructions</h3>

            <p>Now that we're acquainted, let's discuss business. Despite our exceptional team, lately, we have been facing several managerial issues. To gather insights and solutions, we have temporarily appointed you as the CEO of Fiasco.</p>

            <p>
              Your first task is to <b>explore the company map</b>. Although everything may seem fine from a distance, if you <b>look closely</b> you will probably notice something odd. Zoom in on the map and search the area until you find the issue (e.g. a person behaving inappropriately). Once you have found it, <b>select the corresponding area</b> through the carousel menu on the right side of the screen.
            </p>
            <p>
              If your suspicion is correct, you will be redirected to the challenge page where you can gather more information about the challenge. Otherwise, you will have to keep looking and select another area.
            </p>

            <p>
              On the challenge page, you will learn about the issue to solve and find nine potential solutions to choose from. On the left of the page you will find a video and a challenge description to gather information. On the right you can explore each solution by clicking on the “eye” icon.
            </p>

            <p>
              Some solutions are effective and will lead to a gain, some are less effective or even harmful and will generate a loss; the range of outcomes varies from -3 to +3. For each solution, you will have the option to spend money on an <b>experiment</b> to know how much profit it would yield. Alternatively, you can decide to <b>apply one of the solutions</b> without experimenting.

            </p>
            <p>
              Experimenting does not equate to definitively choosing a solution. You can experiment with as many solutions as you wish. Once you are done experimenting, you should select the solution you want to apply.

            </p>
            <p>
              If you have doubts about the rules, you can access these instructions anytime by clicking the “i” icon in the bottom-left corner.
            </p>
            <p>You are encouraged to use your experience and the course material while seeking a solution.</p>

            We look forward to working with you!

          </div>
        }

      </div>
    </div>
    <NextButton
      className="TutorialDialog__NextButton"
      onClick={() => {
        next();


      }}
    />
  </GameDialog>
}

export default TutorialDialog