import LeaderboardRepository from "../leaderboard/LeaderboardRepository";
import Game from "./Game";
import GameRepository from "./GameRepository";
import DoProfilationCommand from "./commands/DoProfilationCommand";
import DoTutorialCommand from "./commands/DoTutorialCommand";


export default class GameInteractService {
    constructor (
        private gameRepository: GameRepository,
        private leaderboardRepository: LeaderboardRepository,
    ) {

    }

    public buildDoTutorialCommand(game: Game): DoTutorialCommand {
        var command = new DoTutorialCommand(this.gameRepository, game);

        return command
    }

    public buildDoProfilationCommand(game: Game, payload: any): DoProfilationCommand {
        var command = new DoProfilationCommand(this.gameRepository, this.leaderboardRepository, game, payload);

        return command;
    }
}