enum InteractionStatus {
  IDLE,
  DRAGGING
}

export default class InteractableMap {
  canvas: HTMLCanvasElement | null = null
  canvasCtx: CanvasRenderingContext2D | null = null
  sourceImg: any = null
  backgroundImg: any = null

  sourceImgLoaded: boolean = false
  backgroundImgLoaded: boolean = false
  loadedAreas: number = 0

  imageSource = ""
  backgroundSource = "/images/Bg_Pattern_yellow.svg"
  currentZoom = 0;
  maxZoom = 7; // double
  proportionalRescale = 7600
  areaScale = 5.3

  imageRatio = 1;

  initialSize = {
    width: 0, height: 0
  }

  currentSize = {
    width: 0, height: 0
  }

  currentOffset = {
    x: 0,
    y: 0
  }

  element: {
    ref: HTMLElement | null,
    initialPosition: {
      x: number,
      y: number
    },
    initialSize: {
      width: number,
      height: number
    }
  } = {
      ref: null,
      initialPosition: {
        x: 300,
        y: 453
      },
      initialSize: {
        width: 263,
        height: 253
      }
    }

  video = {
    ref: null,
    initialPosition: {
      x: 300,
      y: 453
    },
    initialSize: {
      width: 263,
      height: 253
    },
    isShown: false
  }

  areas: Array<{
    area_id: string,
    path: string,
    image?: any,
    currentSize?: {
      width: number,
      height: number
    },
    initialSize?: {
      width: number,
      height: number
    },
    currentOffset?: {
      x: number,
      y: number
    },
    offset?: {
      x: number,
      y: number
    }
    imageRatio?: number
  }> = []

  currentArea: string = ""

  interactionStatus: InteractionStatus
  draggingInitialPoint = { x: 0, y: 0 }
  draggingDiff = { x: 0, y: 0 }
  draggingDistance = { x: 0, y: 0 }

  currentCursorPosition = { x: 0, y: 0 }

  loadingRef: any = null

  scrollTimeout: any = null

  loadingRemoved = false

  constructor(imageSource: string, classPrefix: string, areas: Array<{
    area_id: string,
    path: string,
    currentOffset?: {
      x: number,
      y: number
    },
    offset?: {
      x: number,
      y: number
    }
  }>, loadingRef: any) {
    this.imageSource = imageSource
    this.canvas = document.getElementsByClassName(classPrefix + 'MapCanvas')[0] as HTMLCanvasElement

    this.areas = areas
    this.interactionStatus = InteractionStatus.IDLE

    // this.element.ref = document.getElementsByClassName(classPrefix + 'Element')[0] as HTMLElement
    // this.video.ref = document.getElementById('videoElement')

    this.canvasCtx = this.canvas!.getContext("2d")

    var container = document.getElementsByClassName("GamePage__Container")[0] as HTMLElement
    this.canvas!.width = container.offsetWidth
    this.canvas!.height = container.offsetHeight
    this.loadingRef = loadingRef
    this.startEventListening()
  }

  onKeyUp = (event: any) => {

    if ((window as any).interactable_map_locked === true) {
      return
    }

    var code = event.code;

    if (code === "ArrowUp") {
      this.onMoveUp()
    }
    else if (code === "ArrowDown") {
      this.onMoveDown()
    }
    if (code === "ArrowRight") {
      this.onMoveRight()
    }
    else if (code === "ArrowLeft") {
      this.onMoveLeft()
    }
  }

  onMoveUp = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.moveUp()
    this.refresh()
  }

  onMoveDown = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.moveDown()
    this.refresh()
  }

  onMoveRight = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.moveRight()
    this.refresh()
  }

  onMoveLeft = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.moveLeft()
    this.refresh()
  }

  onWheel = (event: any) => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    // clearTimeout(this.scrollTimeout);  
    // this.scrollTimeout = setTimeout(() => {
    //     if (event.deltaY < 0) {
    //         this.onZoomIn();
    //     } else {
    //         this.onZoomOut();
    //     }
    // }, 50);
    if (event.deltaY < 0) {
      this.onZoomIn();
    } else {
      this.onZoomOut();
    }
  }

  onZoomIn = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.zoomIn()
    this.refresh()
  }

  onZoomOut = () => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    this.zoomOut()
    this.refresh()
  }

  onResize = (event: any) => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    var container = document.getElementsByClassName("GamePage__Container")[0] as HTMLElement
    this.canvas!.width = container.offsetWidth
    this.canvas!.height = container.offsetHeight
    this.initialDrawCallback()
  }

  startEventListening() {


    document.addEventListener('keyup', this.onKeyUp, false);


    document.addEventListener('wheel', this.onWheel)


    window.addEventListener('resize', this.onResize, true);


    this.canvas?.addEventListener('mousedown', this.canvasMouseDown)


    this.canvas?.addEventListener('mouseup', this.canvasMouseUp)


    this.canvas?.addEventListener('mousemove', this.canvasMove)

    // Touch controls

    this.canvas?.addEventListener('touchstart', this.canvasMouseDown)


    this.canvas?.addEventListener('touchend', this.canvasMouseUp)


    this.canvas?.addEventListener('touchmove', this.canvasMove)
  }

  removeListening() {

    document.removeEventListener('keyup', this.onKeyUp)

    document.removeEventListener('wheel', this.onWheel)

    window.removeEventListener('resize', this.onResize)

    this.canvas?.removeEventListener('mousedown', this.canvasMouseDown)

    this.canvas?.removeEventListener('mouseup', this.canvasMouseUp)

    this.canvas?.removeEventListener('mousemove', this.canvasMove)

    this.canvas?.removeEventListener('touchstart', this.canvasMouseDown)

    this.canvas?.removeEventListener('touchend', this.canvasMouseUp)

    this.canvas?.removeEventListener('touchmove', this.canvasMove)
  }

  initialDraw() {
    this.loadedAreas = 0;
    this.sourceImg = new Image()
    this.sourceImg.src = this.imageSource

    for (var areaKey in this.areas) {
      var area = this.areas[areaKey]
      area.image = new Image()
      area.image.src = area.path
      area.image.onload = ((area) => {
        return () => {
          this.loadedAreas++
          area.currentSize = {
            width: area.image.width * this.areaScale,
            height: area.image.height * this.areaScale
          }
          area.initialSize = {
            width: area.image.width * this.areaScale,
            height: area.image.height * this.areaScale
          }
          area.offset = {
            x: area.offset!.x * (this.areaScale / 1.7),
            y: area.offset!.y * (this.areaScale / 1.7)
          }
          area.imageRatio = area.image.height / area.image.width

          this.drawIfAllLoaded()
        }
      })(area)

    }

    this.sourceImg.onload = () => {
      this.sourceImgLoaded = true;
      this.drawIfAllLoaded()
    }
    this.backgroundImg = new Image()
    this.backgroundImg.src = this.backgroundSource
    this.backgroundImg.onload = () => {
      this.backgroundImgLoaded = true
      this.drawIfAllLoaded()
    }
  }

  drawIfAllLoaded() {
    var allLoaded = false
    if (this.sourceImgLoaded
      && this.backgroundImgLoaded
      && this.loadedAreas === 10
    ) {
      allLoaded = true;

    }

    if (allLoaded) {
      this.initialDrawCallback()
    }
  }

  setCurrentArea(area: string) {
    this.currentArea = area
    this.refresh()
  }

  initialDrawCallback() {
    // this.initialSize.width = 1920
    // this.initialSize.height = 1080
    // this.currentSize.width = 1920
    // this.currentSize.height = 1080

    if (!this.loadingRemoved) {

      this.loadingRef.current.remove()
      this.loadingRemoved = true
    }
    this.initialSize.width = this.sourceImg.width
    this.initialSize.height = this.sourceImg.height
    this.currentSize.width = this.sourceImg.width
    this.currentSize.height = this.sourceImg.height

    this.imageRatio = this.sourceImg!.height / this.sourceImg!.width

    this.currentSize.width = this.initialSize.width * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom)
    this.currentSize.height = this.currentSize.width * this.imageRatio


    // centering

    this.currentOffset.x = this.canvas!.width / 2 - this.currentSize.width / 2
    this.currentOffset.y = this.canvas!.height / 2 - this.currentSize.height / 2



    for (var area of this.areas) {
      area.currentSize!.width = area.initialSize!.width * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom)
      area.currentSize!.height = area.currentSize!.width * area.imageRatio!

      area.currentOffset!.x = area.offset!.x * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom) + this.currentOffset.x
      area.currentOffset!.y = area.offset!.y * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom) + this.currentOffset.y
    }

    this.refresh()
  }

  canvasMouseDown = (e: any) => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    if (e.button === 0) {
      if (this.interactionStatus === InteractionStatus.IDLE) {
        // Start dragging
        this.draggingDiff = {
          x: 0,
          y: 0
        }
        if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
          var touch = e.touches[0] || e.changedTouches[0];
          this.draggingInitialPoint = {
            x: touch.pageX,
            y: touch.pageY
          }
        } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover' || e.type === 'mouseout' || e.type === 'mouseenter' || e.type === 'mouseleave') {
          this.draggingInitialPoint = {
            x: e.pageX,
            y: e.pageY
          }
        }


        this.interactionStatus = InteractionStatus.DRAGGING
      }
    }


  }

  canvasMouseUp = (e: any) => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    if (e.button === 0) {
      this.interactionStatus = InteractionStatus.IDLE
      this.draggingDiff = {
        x: 0,
        y: 0
      }
    }
  }

  canvasMove = (e: any) => {
    if ((window as any).interactable_map_locked === true) {
      return
    }
    if (this.interactionStatus === InteractionStatus.DRAGGING) {
      if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
        var touch = e.touches[0] || e.changedTouches[0];

        this.draggingDiff = {
          x: touch.pageX - this.draggingInitialPoint.x,
          y: touch.pageY - this.draggingInitialPoint.y
        }
      } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover' || e.type === 'mouseout' || e.type === 'mouseenter' || e.type === 'mouseleave') {
        this.draggingDiff = {
          x: e.pageX - this.draggingInitialPoint.x,
          y: e.pageY - this.draggingInitialPoint.y
        }
      }


      if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
        var touch2 = e.touches[0] || e.changedTouches[0];
        this.draggingInitialPoint = {
          x: touch2.pageX,
          y: touch2.pageY
        }
      } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover' || e.type === 'mouseout' || e.type === 'mouseenter' || e.type === 'mouseleave') {
        this.draggingInitialPoint = {
          x: e.pageX,
          y: e.pageY
        }
      }
      this.recalculate()
      this.refresh()
    }

    this.currentCursorPosition = {
      x: e.pageX,
      y: e.pageY
    }
  }

  moveLeft() {
    this.currentOffset.x += 80
    for (var area of this.areas) {
      area.currentOffset!.x += 80
    }
  }
  moveRight() {
    this.currentOffset.x -= 80

    for (var area of this.areas) {
      area.currentOffset!.x -= 80
    }
  }
  moveUp() {
    this.currentOffset.y += 80

    for (var area of this.areas) {
      area.currentOffset!.y += 80
    }
  }
  moveDown() {
    this.currentOffset.y -= 80

    for (var area of this.areas) {
      area.currentOffset!.y -= 80
    }
  }

  zoomIn() {
    if (this.currentZoom < this.maxZoom) {
      this.currentZoom += 1


      this.recalculate(true)

    }
  }

  zoomOut() {
    if (this.currentZoom >= 1) {
      this.currentZoom -= 1

      this.recalculate(true)
    }
  }

  setCenterTo(x: number, y: number) {
    this.currentOffset.x = x - this.currentSize.width / 2
    this.currentOffset.y = y - this.currentSize.height / 2
  }

  recalculate(resetCenter = false) {
    var oldWidth = this.currentSize.width
    var oldHeight = this.currentSize.height

    // var scaleFactor = (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom);

    // var cursorPercentOnDraw = {
    //     x: 0,
    //     y: 0
    // }

    // if (resetCenter) {
    //     cursorPercentOnDraw = {
    //         x: (this.currentCursorPosition.x - this.currentOffset.x) / this.currentSize.width,
    //         y: (this.currentCursorPosition.y - this.currentOffset.y) / this.currentSize.height,
    //     }
    // }

    this.currentSize.width = this.initialSize.width * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom)
    this.currentSize.height = this.currentSize.width * this.imageRatio

    var offsetMoveX = (oldWidth - this.currentSize.width) / 2 + this.draggingDiff.x
    var offsetMoveY = (oldHeight - this.currentSize.height) / 2 + this.draggingDiff.y

    this.currentOffset.x = this.currentOffset.x + offsetMoveX
    this.currentOffset.y = this.currentOffset.y + offsetMoveY

    // if (resetCenter) {
    //     this.currentOffset.x = window.innerWidth / 2 - (cursorPercentOnDraw.x * this.currentSize.width)
    //     this.currentOffset.y = window.innerHeight / 2 - (cursorPercentOnDraw.y * this.currentSize.height)
    // }

    for (var area of this.areas) {
      area.currentSize!.width = area.initialSize!.width * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom)
      area.currentSize!.height = area.initialSize!.height * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom)

      area.currentOffset!.x = area.offset!.x * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom) + this.currentOffset.x
      area.currentOffset!.y = area.offset!.y * (this.canvas!.width / this.proportionalRescale + 0.1 * this.currentZoom) + this.currentOffset.y
    }
  }

  refresh() {
    var container = document.getElementsByClassName("GamePage__Container")[0] as HTMLElement
    this.canvasCtx!.drawImage(
      this.backgroundImg, 0, 0, container.offsetWidth * 1.4, container.offsetWidth * 0.8

    )
    this.canvasCtx!.drawImage(
      this.sourceImg,
      this.currentOffset.x, this.currentOffset.y,
      this.currentSize.width, this.currentSize.height)

    for (var area of this.areas) {
      if (area.area_id === this.currentArea) {
        this.canvasCtx!.drawImage(
          area.image,
          area.currentOffset?.x!, area.currentOffset?.y!,
          area.currentSize?.width!, area.currentSize?.height!
        )
      }

    }

  }
}