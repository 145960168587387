import Game from "./Game";
import User from "./entities/User";

export default abstract class GameRepository {
  abstract bulkInsert(games: Game[]): void;

  abstract bulkDelete(games: Game[]): void;

  abstract fetchAllBySessionId(sessionId: string): Promise<Game[]>;

  abstract fetchAllBySessionIdAndNickname(sessionId: string, nickname: string): Promise<Game[]>;

  abstract get(gameId: string): Promise<any>;

  abstract update(game: Game): void;

  abstract delete(game: Game): Promise<void>;

  abstract fetchByUsername(username: string): Promise<Game[]>;

  public async restoreGame(id: string): Promise<Game> {

    return this.get(id)
  }

  abstract restoreGameFromToken(token: string): Promise<Game>

  public newGame(sessionId: string): Game {
    var game = new Game()
    game.sessionId = sessionId
    game.user = new User()
    game.created_at = new Date()
    return game
  }
}