import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button'
import './style.scss'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function SimplePaginator ({
    currentPage,
    totalPages,
    onChangePage,
}: {
    currentPage: number,
    totalPages: number,
    onChangePage: (newPage: number) => void
})  {

    return <div className="SimplePaginator__Container">
        <div className="SimplePaginator__Content">
            <Button
                className='SimplePaginator__Back'
                onClick={() => {
                    onChangePage(currentPage-1)
                }}
                disabled={currentPage<=1}
            ><FontAwesomeIcon icon={faArrowLeft}/></Button>
            <div className="SimplePaginator__Current">
                <b>{currentPage.toString()}</b>
                <span className="SimplePaginator__Total">&nbsp;/ {totalPages}</span>
                </div>
            <Button
                className='SimplePaginator__Forward'
                onClick={() => {
                    onChangePage(currentPage+1)
                }}
                disabled={currentPage>=totalPages}
            ><FontAwesomeIcon icon={faArrowRight}/></Button>
            {/* <div className="SimplePaginator__Total">Total pages: </div> */}
        </div>
    </div>
}