import PouchDB from 'pouchdb'
import JwtKeeper from "./JwtKeeper";
import ResearchDataRepository from '../../research_data/ResearchDataRepository';
import ResearchData from '../../research_data/ResearchData';

class ResearchDataRepositoryPouchDB extends ResearchDataRepository {
    private _couchdbUrl: string

    private _databaseUrl: string

    private _db: PouchDB.Database | undefined

    constructor(private jwtKeeper: JwtKeeper) {
        super()

        this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
        this._databaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_RESEARCH_DATA_DB


        this._db = new PouchDB(this._databaseUrl, {
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })

    }

    public async insert(data: ResearchData): Promise<void> {
        await this._db?.post(data)
    }

    public async fetchAllBySessionId(sessionId: string): Promise<ResearchData[]> {
        const result = await this._db!.find({
            selector: {
                session_id: {$eq: sessionId}
            },
            limit: 100000
        })
        return result.docs as unknown as ResearchData[]
    }
}

export default ResearchDataRepositoryPouchDB