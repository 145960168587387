// import Link from 'next/link'
import './style.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

interface BreadcrumbItem {
    name: string,
    label: string,
    href: string
}

export default function Breadcrumb ({
    items,
    title
}: {
    items: BreadcrumbItem[],
    title?: string
}) {
    return <div className="Breadcrumb__Container">
        {items.map((item) => {
            return <a href={item.href} key={item.name}>
                    {item.name==='home' ?
                     <FontAwesomeIcon
                    style={{marginRight: 12}}
                     icon={faHome}/>
                    :
                    <>&nbsp;/&nbsp;</>
                    }
                    
                    {item.label}          
            </a>
        
        })}
        {title && <div className="Breadcrumb__Title">
            <h1>{title}</h1>
        </div>}
    </div>
}