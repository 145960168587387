import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../presentationals/Breadcrumb";
import './style.scss'
import Button from "../../presentationals/Button";
import { faArrowLeft, faBarsProgress, faDownload, faChartColumn, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import DomainContext from "../../../contexts/DomainContext";
import SessionModel from "../../../core/session/Session";
import Actions from "../../presentationals/Actions";
import Challenges from "../../widgets/Challenges";
import useSnackbar from "../../hooks/useSnackbar";
import Papa from 'papaparse';
import downloadBlob from "../../utils/downloadBlob";
import Dropdown from "../../presentationals/Dropdown";

export default function Session() {
  const { id } = useParams()
  const navigate = useNavigate()
  const globalContext = useContext(DomainContext)
  const [session, setSession] = useState<SessionModel | null>(null)
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    loadSession()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadSession = async () => {
    const result = await globalContext.sessionRepository.restoreSession(id!);
    setSession(result)
  }

  const activateChallenge = async (id: string) => {
    session?.activateChallenge(id)
    await globalContext.sessionRepository.update(session!);
    showSnackbar("Challenge activated")
  }

  const deactivateChallenge = async (id: string) => {
    session?.deactivateChallenge(id)
    await globalContext.sessionRepository.update(session!);
    showSnackbar("Challenge deactivated")
  }

  const downloadTokens = async () => {
    const tokens = await globalContext.downloadService.fetchTokens(id!)

    var csv = Papa.unparse(tokens);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(csvData, session?.name.replace(" ", "_") + "_tokens.csv")
  }

  const downloadResearchData = async () => {
    const research_data_download = await globalContext.downloadService.fetchResearchData(id!)

    var csv = Papa.unparse(research_data_download);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(csvData, session?.name.replace(" ", "_") + "_research_data.csv")
  }

  const downloadParticipantsData = async () => {
    const participants_data = await globalContext.downloadService.fetchParticipantsData(id!)

    var csv = Papa.unparse(participants_data);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(csvData, session?.name.replace(" ", "_") + "_participants_data.csv")
  }

  return <div className="SessionPage__Container">
    <Breadcrumb title={"Session " + session?.name} items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      }
    ]} />

    <Actions
      leftActions={<Button
        onClick={() => {
          navigate('/sessions')
        }}
        icon={
          <FontAwesomeIcon icon={faArrowLeft} />
        }
      >Back to sessions</Button>}
    >
      <Dropdown
        icon={<FontAwesomeIcon icon={faDownload} />}
        options={[
          {
            label: "Download tokens",
            name: "download_tokens",
            onClick: downloadTokens
          },
          {
            label: "Download research data",
            name: "download_research_data",
            onClick: downloadResearchData
          },
          {
            label: "Download participants data",
            name: "download_participants_data",
            onClick: downloadParticipantsData
          }
        ]}
      >
        Downloads
      </Dropdown>
      {/* <Button
        icon={<FontAwesomeIcon icon={faDownload} />}
        onClick={downloadTokens}
      >
        Download tokens
      </Button> */}
      <Button
        icon={<FontAwesomeIcon icon={faChartColumn} />}
        onClick={() => {
          navigate(`/sessions/${id}/debrief`)
        }}
      >
        Debrief
      </Button>
      {/* <Button
        icon={<FontAwesomeIcon icon={faDownload} />}
        onClick={downloadResearchData}
      >
        Download research data
      </Button> */}
      <Button
        onClick={() => {
          navigate(`/sessions/${id}/completion`)
        }}
        icon={<FontAwesomeIcon icon={faBarsProgress} />}
      >
        Completion status
      </Button>
      <Button
        onClick={() => {
          navigate(`/sessions/${id}/leaderboard`)
        }}
        icon={<FontAwesomeIcon icon={faTrophy} />}
      >
        Leaderboard
      </Button>
    </Actions>
    {session && <Challenges
      initActiveChallengesIds={session!.activeChallengesIds}
      initOnceActiveChallengesIds={session!.onceActiveChallengesIds}
      onActivateChallenge={(id) => activateChallenge(id)}
      onDeactivateChallenge={(id) => deactivateChallenge(id)}
    />}
  </div>
}