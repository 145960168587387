import { useContext, useState } from "react"
import SnackbarContext from '../contexts/SnackbarContext'

const useSnackbar = () => {
    const { setSnackbarContext} = useContext(SnackbarContext)
    const [currentTimeout, setCurrentTimeout] = useState<any>(null)

    const showSnackbar = async (message: string, type: string = "success") => {
        
        if (currentTimeout) {
            clearTimeout(currentTimeout);
            setCurrentTimeout(null)
        }
        setSnackbarContext({
            snackbarOpen: true,
            snackbarMessage: message,
            snackbarType: type,
            touched: 'yes'
        })


        let timeout = setTimeout(() => {
            setSnackbarContext({
                snackbarMessage: message,
                snackbarType: type,
                snackbarOpen: false
            })
        }, 2000)

        setCurrentTimeout(timeout)
    }
    
    const hideSnackbar = (newContext: any) => {
        setSnackbarContext(newContext)
    }

    return {showSnackbar, hideSnackbar}
}

export default useSnackbar