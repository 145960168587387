import React from 'react'
import Breadcrumb from '../../presentationals/Breadcrumb';
import './style.scss'
import Actions from '../../presentationals/Actions';
import Button from '../../presentationals/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SessionsTable from '../../widgets/SessionsTable';
import { useNavigate } from 'react-router-dom';

export default function Sessions()
{
    const navigate = useNavigate()

    return <div className="SessionsPage__Container">
            <Breadcrumb title="Sessions" items={[
                {
                    name: 'home',
                    label: 'Home',
                    href: '/sessions'
                },
                {
                    name: 'sessions',
                    label: 'Sessions',
                    href: '/sessions'
                }
            ]}/>
            <Actions>
                <Button
                    icon={<FontAwesomeIcon icon={faPlus}/>}
                    onClick={() => {
                        navigate('/sessions/create')
                    }}
                >Create session</Button>
            </Actions>
            <SessionsTable />
        </div>

}