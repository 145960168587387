import { createContext } from "react"
import Session from "../../core/session/Session"
import LeaderboardEntry from "../../core/leaderboard/LeaderboardEntry"
import DebriefService from "../../core/debrief/DebriefService"

export interface Group {
    name: string,
    score: number,
    participantsNum: number
}

export const defaultDebriefContext: {
    leaderboardEntries: LeaderboardEntry[],
    setLeaderboardEntries: (value: any) => any,
    groups: Group[],
    setGroups: (value: any) => any,
    session: Session | null,
    setSession: (value: any) => any,
    loading: boolean,
    setLoading: (value: any) => any,
    debriefService?: DebriefService
} = {
    leaderboardEntries: [],
    setLeaderboardEntries: () => {},
    groups: [],
    setGroups: () => {},
    session: null,
    setSession: ()=> {},
    loading: true,
    setLoading: ()=>{}
}

const DebriefContext = createContext(
    defaultDebriefContext
)

export default DebriefContext