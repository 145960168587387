import { createContext } from "react"

export const defaultSnackbarContext = {
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarType: "success"
}

const SnackbarContext = createContext({
    snackbarContext: defaultSnackbarContext,
    setSnackbarContext: (ctx: any) => {}
})

export default SnackbarContext;