import './style.scss'
import React from 'react'

export default function Card({
    children,
    footer,
    noPadding,
    className,
    style,
    pureOverlay
}:{
    children?: any,
    footer?: any,
    noPadding?: boolean,
    className?: string,
    style?: any,
    pureOverlay?: boolean
})
{
    return <div
        className={"Card__Container " + (className ? className : "")}
        style={style}
    >
        {pureOverlay ?
        <>{children}</>
            :
        <><div className={`Card__Content ${noPadding ? 'Card__Content--noPadding ' : ''}`}>
            {children}
        </div>
        {footer && <div className="Card__Footer">
            {footer}
        </div>}</>
        }
        
    </div>
}