import { INITIAL_SCORE } from "../utils/constants";
import GameStatus from "./GameStatus";
import User from "./entities/User";

export default class Game {

  private _id: string | undefined = ""

  public _rev: string | undefined

  public token: string | undefined

  public status: GameStatus

  public created_at: Date | null = null

  public treatment: string | null = null

  public score: number = 0

  public sessionId: string = ""

  public challengesPlayed: string[] = []

  public challengesPlayedNum: number = 0

  public user: User | null = null

  public tutorialDone: boolean = false

  public profilationDone: boolean = false

  constructor(

  ) {
    this.status = GameStatus.Ready
    this.score = INITIAL_SCORE
  }

  public get id(): string {
    return this._id!
  }

  public doTutorial() {
    this.tutorialDone = true;
  }

  public doProfilation(payload: any) {
    this.user!.nickname = payload.nickname
    this.user!.age = payload.age
    this.user!.gender = payload.gender
    this.user!.role = payload.role

    this.user!.city_birth = payload.city_birth
    this.user!.city_residence = payload.city_residence
    this.user!.country_birth = payload.country_birth
    this.user!.country_residence = payload.country_residence
    this.user!.university_major = payload.university_major

    this.profilationDone = true
  }

  public startGame(): void {
    this.status = GameStatus.Started
  }

  public addScore(scoreToAdd: number): void {
    this.score += scoreToAdd
  }

  public addChallengePlayed(challengeBlueprintId: string): void {

    this.challengesPlayed.push(challengeBlueprintId)
    this.challengesPlayedNum++;
  }

  public static dump(game: Game) {
    return {
      _id: game._id,
      _rev: game._rev,
      token: game.token,
      status: game.status,
      created_at: game.created_at?.toISOString(),
      treatment: game.treatment,
      score: game.score,
      session_id: game.sessionId,
      challenges_played: game.challengesPlayed,
      tutorial_done: game.tutorialDone,
      profilation_done: game.profilationDone,
      user: {
        nickname: game.user?.nickname,
        age: game.user?.age,
        gender: game.user?.gender,
        role: game.user?.role,
        first_name: game.user?.first_name,
        last_name: game.user?.last_name,
        user_name: game.user?.user_name,

        city_birth: game.user?.city_birth,
        city_residence: game.user?.city_residence,
        country_birth: game.user?.country_birth,
        country_residence: game.user?.country_residence,
        university_major: game.user?.university_major,
      }
    }
  }

  public static restore(datas: any, toGame: Game) {
    toGame._id = datas._id
    toGame._rev = datas._rev
    toGame.token = datas.token
    toGame.status = datas.status
    toGame.created_at = new Date(datas.created_at)
    toGame.treatment = datas.treatment
    toGame.score = datas.score
    toGame.sessionId = datas.session_id
    toGame.challengesPlayed = datas.challenges_played
    toGame.tutorialDone = datas.tutorial_done
    toGame.profilationDone = datas.profilation_done

    var user = new User()
    user.nickname = datas.user.nickname
    user.age = datas.user.age
    user.gender = datas.user.gender
    user.role = datas.user.role
    user.first_name = datas.user.first_name
    user.last_name = datas.user.last_name
    user.user_name = datas.user.user_name

    user.city_birth = datas.user.city_birth
    user.city_residence = datas.user.city_residence
    user.country_birth = datas.user.country_birth
    user.country_residence = datas.user.country_residence
    user.university_major = datas.user.university_major

    toGame.user = user
  }
}