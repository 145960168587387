import GameRepository from "../game/GameRepository";
import LeaderboardRepository from "../leaderboard/LeaderboardRepository";
import SessionRepository from "./SessionRepository";

export default class SessionService {
    /**
     *
     */
    constructor(
        private sessionRepository: SessionRepository,
        private gameRepository: GameRepository,
        private leaderboardRepository: LeaderboardRepository
    ) {
        
    }
    public async deleteSession(sessionId: string) {

        // Delete session
        const session = await this.sessionRepository.restoreSession(sessionId)
        await this.sessionRepository.delete(session)

        // Delete games
        const games = await this.gameRepository.fetchAllBySessionId(sessionId)
        await this.gameRepository.bulkDelete(games)

        // Delete leaderboard
        const leaderboardEntries = await this.leaderboardRepository.fetchAllScores(sessionId)
        await this.leaderboardRepository.bulkDelete(leaderboardEntries)
    }
}