import Game from "../game/Game";
import GameRepository from "../game/GameRepository";
import LeaderboardRepository from "../leaderboard/LeaderboardRepository";
import ResearchDataRepository from "../research_data/ResearchDataRepository";
import Challenge, { Solution } from "./Challenge";
import ChallengeRepository from "./ChallengeRepository";
import AcknowledgeCommand from "./commands/AcknowledgeCommand";
import ApplyCommand from "./commands/ApplyCommand";
import ExperimentCommand from "./commands/ExperimentCommand";


export default class ChallengeInteractService {
    constructor (
        private challengeRepository: ChallengeRepository,
        private gameRepository: GameRepository,
        private leaderboardRepository: LeaderboardRepository,
        private researchDataRepository: ResearchDataRepository
    ) {

    }

    public buildAcknowledgeCommand(challenge: Challenge, game: Game): AcknowledgeCommand {
        var command = new AcknowledgeCommand(this.challengeRepository, this.researchDataRepository, challenge, game);

        return command;
    }

    public buildExperimentCommand(game: Game, challenge: Challenge, solution: Solution): ExperimentCommand {
        var command = new ExperimentCommand(
            this.gameRepository, this.challengeRepository, this.leaderboardRepository,
            this.researchDataRepository,
            game, challenge, solution)

        return command
    }
    
    public buildApplyCommand(game: Game, challenge: Challenge, solution: Solution): ApplyCommand {
        var command = new ApplyCommand(
            this.gameRepository, this.challengeRepository, this.leaderboardRepository,
            this.researchDataRepository,
            challenge, game, solution)

        return command
    }
}