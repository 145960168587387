import ResearchDataRepository from "../../research_data/ResearchDataRepository";
import Challenge from "../Challenge";
import ChallengeRepository from "../ChallengeRepository";
import Game from "../../game/Game";

export default class AcknowledgeCommand {

    constructor(
        private challengeRepository: ChallengeRepository,
        private researchDataRepository: ResearchDataRepository,
        private challenge: Challenge,
        private game: Game
    ) {
    
    }

    public async execute(): Promise<void> {
        this.challenge.acknowledge()
        await this.challengeRepository.update(this.challenge)
        
        var researchData= {
            session_id: this.game.sessionId,
            student: this.game.user?.user_name!,
            treatment: this.game.treatment!,
            issue: this.challenge.title,
            action_seq: this.challenge.currentActionIndex.toString(),
            action: "acknowledge",
            timestamp: new Date()
        }

        await this.researchDataRepository.insert(researchData)
    }
}