
import { useEffect, useState } from 'react';
import './DebriefChartLayout.scss'

export default function DebriefChartLayout({
  filters,
  children,
  loading
}: {
  filters: any,
  children: any,
  loading?: boolean
}) {

  const [isZoomed, setIsZoomed] = useState(false)

  const checkZoomed = () => {
    setIsZoomed(window.innerHeight < 900)
  }

  useEffect(() => {

    checkZoomed()

    window.addEventListener("resize", checkZoomed);
    return () => {
      window.removeEventListener("resize", checkZoomed);
    }
  })

  return <div
    className="DebriefChartLayout__Container"
  >
    {isZoomed && <div style={{
      right: 0,
      top: '-100px',
      position: 'absolute',
      fontSize: '20px',
      color: 'red'
    }}>Please zoom out on the page for a better experience.</div>}
    <div className="DebriefChartLayout__Sidebar">
      {filters}
    </div>
    <div className="DebriefChartLayout__Chart">

      {!loading && <>{children}</>}
    </div>
    {
      loading && <div className="DebriefChartLayout__Loading">
        Loading data...
      </div>
    }
  </div>
}