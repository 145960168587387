import Session from "../../session/Session";
import SessionRepository from "../../session/SessionRepository";
import PouchDB from 'pouchdb'
import PaginationResult from "../../types/PaginationResult";
import JwtKeeper from "./JwtKeeper";

class SessionRepositoryPouchDB extends SessionRepository {
    private _couchdbUrl: string

    private _databaseUrl: string

    private _db: PouchDB.Database | undefined

    constructor(private jwtKeeper: JwtKeeper) {
        super()

        this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
        this._databaseUrl = this._couchdbUrl
            + '/'
            + process.env.REACT_APP_DB_PREFIX
            + process.env.REACT_APP_SESSIONS_DB

        this._db = new PouchDB(this._databaseUrl, {
            // auth: {
            //     username: process.env.REACT_APP_DB_USERNAME,
            //     password: process.env.REACT_APP_DB_PASSWORD
            // }
            fetch: (url, opts) => {
                const headers: any = opts!.headers;
                headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
                return PouchDB.fetch(url, opts)
            }
        })
    }

    public async create(session: Session): Promise<string> {
        const dump = Session.dump(session)
        delete dump._id
        delete dump._rev
        const result = await this._db!.post(dump)

        session._id = result.id

        return result.id;
    }

    public async get(sessionId: string): Promise<object> {
        return await this._db!.get(sessionId)
    }

    public async update(session: Session) {
        const dump = Session.dump(session)
        var response = await this._db!.put(dump)
        session._rev = response.rev
    }

    public async fetch (page: number, perPage: number): Promise<PaginationResult<Session>> {

        const elements = await this._db!.find({
            selector: {},
            sort: [{'created_at': 'desc'}],
            limit: perPage,
            skip: (page - 1) * perPage,
        })

        const rows = elements.docs.map((document) => {
            //Hydrate
            const session = new Session()
            Session.restore(document, session)
            return session
        })

        const total = (await this._db!.info()).doc_count

        return new PaginationResult<Session>(rows, total)
    }

    public async delete(session: Session): Promise<void> {
        const dump = Session.dump(session)
        await this._db!.remove(dump as {_id: string, _rev: string})
    }
}

export default SessionRepositoryPouchDB