import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "../../../presentationals/Card"
import './GroupCard.scss'
import { faTrophy } from "@fortawesome/free-solid-svg-icons"

const GroupCard = ({
  name,
  score
}: {
  name: string,
  score: string
}) => {
  return <Card className="GroupCard__Container">
    <div className="GroupCard__Trophy">
      <FontAwesomeIcon color='#cfa411' icon={faTrophy} />
    </div>
    <div className="GroupCard__Name">{name}</div>
    <div className="GroupCard__Score">{score}</div>
  </Card>
}

export default GroupCard