import { useContext, useState } from "react"
import ChallengeContext from "../../../contexts/ChallengeContext"
import SolutionDialog from './SolutionDialog'
import solutionElement from '../../../assets/UI_Solution_grid_element.svg'
import solutionElementTested from '../../../assets/UI_Solition_grid_element_highlighted.svg'
import './Solutions.scss'
import buttonEye from '../../../assets/ButtonIcon_eye_mini.svg';
import buttonEyeTested from '../../../assets/ButtonIcon_eye_mini_active.svg';
import pointsElem from '../../../assets/PointsElement.svg'
import { Solution as SolutionEntity } from "../../../../core/challenge/Challenge"


const ShowButton = ({
    onClick,
    tested
} : {
    onClick: () => void,
    tested: boolean
}) => {
    return <div className="ShowButton__Container Button__Container AnimatedButton">
        <img alt="" src={tested ? buttonEyeTested : buttonEye}/>
        <button
            onClick={onClick}
        />
    </div>
}



const Solution = ({
    solution,
    showSolutionDialog
}: {
    solution: SolutionEntity
    showSolutionDialog: any
}) => {
    return <div className="Solutions__Solution"
    style={{
        backgroundImage: `url(${solution.experimented ? solutionElementTested : solutionElement})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }}
    >
        <div className="Solutions__SolutionContainer">
            <div className="Solutions__SolutionTitle">
                Solution #{solution.number}
            </div>
            <div className="Solutions__SolutionDescription">
                <div className="Solutions__SolutionDescriptionContent">

                {solution.title}
                </div>
            </div>
            
        </div>
        <div className="Solutions__SolutionActions">
                <ShowButton
                    tested={solution.experimented}
                    onClick={() => showSolutionDialog(solution)}
                />
                
            </div>
    {solution.experimented && <div className="Solutions__SolutionScore">
        <img alt="" src={pointsElem} />
        <div className="Solutions__SolutionScoreContent">
            {solution?.score! > 0 ? "+" + solution.score : solution.score}
        </div>
    </div>}
</div>
}

const Solutions = ({
    onApplySolution
} : {
    onApplySolution: () => any
}) => {

    const challengeContext = useContext(ChallengeContext)
    const [solutionDialogShown, setSolutionDialogShown] = useState(false)
    const [currentSolution, setCurrentSolution] = useState<SolutionEntity|undefined>()

    const showSolutionDialog = (solution: SolutionEntity) => {
        setCurrentSolution(solution)
        setSolutionDialogShown(true)
    }

    const hideSolutionDialog = () => {
        setSolutionDialogShown(false)
    }

    return <div className="Solutions__Container">
        {challengeContext.challenge?.solutions.map((solution, index) => {
            return <Solution
                key={index}
                solution={solution}
                showSolutionDialog={showSolutionDialog}
            />
        })}
        <SolutionDialog
            open={solutionDialogShown}
            onClose={hideSolutionDialog}
            solution={currentSolution}
            onApplySolution={onApplySolution}
        />
    </div>
}

export default Solutions