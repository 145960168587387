import textField from '../../assets/Textfield.svg';
import dropdownArrow from '../../assets/DropDownArrow.svg'
import './style.scss'
import { ChangeEvent } from "react"

export interface Option {
  label: string,
  value: string
}

const SelectInput = ({
  name,
  value,
  className,
  placeholder,
  onChange,
  options
}: {
  className?: string,
  placeholder?: string,
  name: string,
  value: any,
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
  options: Option[],
}) => {


  return <div
    className={"GameSelectInput__Container" + (className ? ` ${className}` : "")}
    style={{
      backgroundImage: `url(${textField})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      paddingTop: '2%'
    }}
  >


    <select
      className="GameSelectInput__Input"
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      style={{
        background: `url(${dropdownArrow}) no-repeat right`,

      }}
    >
      {options?.map((option) => {
        return <option
          value={option.value}
          key={option.value}
        >{option.label}</option>
      })}

    </select>
  </div>
}

export default SelectInput;