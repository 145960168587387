import './InfoButton.scss'
import iconInfo from '../../../assets/Icon_info.svg';


const InfoButton = ({onClick}: {onClick: () => any}) => {
    return <div
        className="InfoButton__Container"
        onClick={onClick}
        style={{
            backgroundImage: `url(${iconInfo})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        }}
    >
    </div>
}

export default InfoButton