import LeaderboardEntry from "./LeaderboardEntry";


export enum PerformanceResult {
    GOOD,
    NORMAL,
    BAD
}
export default abstract class LeaderboardRepository {

    protected sortingRule: Array<string | { [propName: string]: "asc" | "desc" }> | undefined = [{'score_data.score': 'desc'}, {'score_data.challengesPlayedNum': 'desc'}]

    abstract assignScore (sessionId: string, gameId: string, score: number): void;
    abstract assignScoreAndGamesPlayedNum (sessionId: string, gameId: string, score: number, challengesPlayedNum: number): void;
    abstract assignNickname (sessionId: string, gameId: string, nickname: string): void;
    abstract fetchAllScores (sessionId: string, onlyPlayed?: boolean): Promise<LeaderboardEntry[]>
    abstract bulkInsert(entries: LeaderboardEntry[]): void;
    abstract bulkDelete(games: LeaderboardEntry[]): void;
    abstract fetchTop10(sessionId: string): Promise<LeaderboardEntry[]>
    abstract fetchTop10AllData(sessionId: string): Promise<LeaderboardEntry[]>
    abstract checkPerformance(sessionId: string, gameId: string): Promise<PerformanceResult>
}