import './AppliedSolution.scss'
import boxOutline from '../../../assets/UI_Box_frame_outline.svg'
import labelLeft from '../../../assets/UI_Label_left_purplish.svg';
import scoreCircle from '../../../assets/UI_Circle_reddish.svg'
import GameScore from '../../Game/components/GameScore';
import NextButton from '../../Game/components/Tutorial/NextButton';
import { useContext } from 'react';
import ChallengeContext from '../../../contexts/ChallengeContext';
import minus from '../../../assets/Minus.svg'
import equal from '../../../assets/Equal.svg'
import experimentCircle from '../../../assets/ExperimentCircle.svg'
import net from '../../../assets/NetScore.svg'
const ScoreCircle = ({
    score
} : {
    score: number
}) => {
    return <div
        className="ScoreCircle__Container"
    >
        <img alt="" src={scoreCircle}/>
        <div
            className="ScoreCircle__Content"
        >
            {score.toString()}
        </div>
    </div>
}

const Minus = () => {
    return <div className="Operator__Container">
        <img alt="" src={minus}/>
    </div>
}

const Equal = () => {
    return <div className="Operator__Container">
        <img alt="" src={equal}/>
    </div>
}

const ExperimentCircle = ({
    score
} : {
    score: number
}) => {
    return <div
        className="ScoreCircle__Container"
    >
        <img alt="" src={experimentCircle}/>
        <div
            className="ScoreCircle__Content"
        >
            {score.toString()}
        </div>
    </div>
}
const NetCircle = ({
    score
} : {
    score: number
}) => {
    return <div
        className="NetCircle__Container"
    >
        <img alt="" src={net}/>
        <div
            className="NetCircle__Content"
        >
            {score > 0 ? "+" + score.toString() : score}
        </div>
    </div>
}
const AppliedSolution = ({
    onNext
} : {
    onNext: () => void
}) => {

    const challengeContext = useContext(ChallengeContext)

    return <div className="AppliedSolution__Container">
        <div className="AppliedSolution__Content">
            <img
                alt=""
                src={boxOutline}
                className="AppliedSolution__Background"
            />
            <div className="AppliedSolution__ContentInner">
                <div className="AppliedSolution__ChallengeTitle">
                    <img alt="" src={labelLeft}/>
                    <div className="AppliedSolution__ChallengeTitleText">
                        CHALLENGE
                    </div>
                </div>
                <div
                    className="AppliedSolution__Text"
                >
                    <b>Solution #{challengeContext.challenge?.appliedSolution?.number}</b> was applied after running <b>{challengeContext.challenge?.experimentsDone === 1 ? (challengeContext.challenge?.experimentsDone + " experiment") : (challengeContext.challenge?.experimentsDone + " experiments")}</b>
                </div>
                <div className="AppliedSolution__PointsEquation">
                    <div className="AppliedSolution__PointsEquationElement">
                        <div className="AppliedSolution__PointsEquationElementUpper">
                            <ScoreCircle score={challengeContext.challenge?.appliedSolution?.score!}/>
                        </div>
                        <div className="AppliedSolution__PointsEquationElementLower">
                            Total points earned
                        </div>
                    </div>
                    <div className="AppliedSolution__PointsEquationElement">
                        <div className="AppliedSolution__PointsEquationElementUpper">
                            <Minus/>
                        </div>
                        <div className="AppliedSolution__PointsEquationElementLower"></div>
                        
                    </div>
                    <div className="AppliedSolution__PointsEquationElement">
                        <div className="AppliedSolution__PointsEquationElementUpper">
                        <ExperimentCircle score={challengeContext.challenge?.experimentsDone!}/></div>
                        <div className="AppliedSolution__PointsEquationElementLower">
                            Total points spent on experiments
                        </div>
                    </div>
                    <div className="AppliedSolution__PointsEquationElement">
                        <div className="AppliedSolution__PointsEquationElementUpper">
                        <Equal/></div>
                        <div className="AppliedSolution__PointsEquationElementLower"></div>
                    </div>
                    <div className="AppliedSolution__PointsEquationElement">
                        <div className="AppliedSolution__PointsEquationElementUpper">
                        <NetCircle score={challengeContext.challenge?.appliedSolution?.score! - challengeContext.challenge?.experimentsDone!}/></div>
                        <div className="AppliedSolution__PointsEquationElementLower">
                            Total net points
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="AppliedSolution__Points">
                <img alt="" src={labelOrange}/>
                <div className="AppliedSolution__PointsContent">
                    You earned <b>{challengeContext.challenge?.appliedSolution?.score! - challengeContext.challenge?.experimentsDone!}</b> points
                </div>
            </div> */}
        </div>
        <GameScore/>
        <NextButton
            className="AppliedSolution__NextButton"
        onClick={onNext}/>
    </div>
}

export default AppliedSolution;