import React from "react"

import './style.scss'

export default function Actions({
    children,
    leftActions
}: {
    children?: React.ReactElement[] | React.ReactElement,
    leftActions?: React.ReactElement[] | React.ReactElement
}) {
    return (<div className="Actions">
        <div className="Actions__Left">
            {leftActions}
        </div>
        <div className="Actions__Right">

            { children }
        </div>
    </div>)
}