import React from "react"

import './style.scss'

export default function Table ({
    children
}: {
    children: React.ReactElement[] | React.ReactElement | any
}){
    return <div className="Table__Container">
        <table>
            { children }
        </table>
    </div>
}