import { Outlet, useNavigate , useLocation} from "react-router-dom";
import DomainContext from "./contexts/DomainContext";
import { buildDomainContext } from "./contexts/DomainContext";
import { CAS } from './cas';

// import { useContext, useEffect, useState } from "react";
// import AuthContext from "./contexts/AuthContext";
// import JwtKeeper from "./core/adapters/pouchdb/JwtKeeper";
// import SessionRepositoryPouchDB from "./core/adapters/pouchdb/SessionRepositoryPouchDB";
// import ChallengeRepositoryBlueprintFile from "./core/adapters/file/ChallengeRepositoryBlueprintFile";
// import GameRepository from "./core/game/GameRepository";
// import GameRepositoryPouchDB from "./core/adapters/pouchdb/GameRepositoryPouchDB";
// import LeaderbaordRepositoryPouchDB from "./core/adapters/pouchdb/LeaderboardRepositoryPouchDB";
// import SessionInitializer from "./core/session/SessionInitializer";
// import SessionService from "./core/session/SessionService";

export default function AuthLayout(){

    // const authContext = useContext(AuthContext)

    
    // const [jwtKeeper, setJwtKeeper] = useState(new JwtKeeper())
    // const [sessionRepository, setSessionRepository] = useState(new SessionRepositoryPouchDB(jwtKeeper))
    // const [challengeRepositoryBlueprint, setChallengeBlueprintRepository] = useState(new ChallengeRepositoryBlueprintFile())
    // const [gameRepository, setGameRepository] =  useState( new GameRepositoryPouchDB(jwtKeeper))
    // const [leaderboardRepository, setLeaderboardRepository] = useState( new LeaderbaordRepositoryPouchDB(jwtKeeper))
    // const [sessionInitializer, setSessionInitializer] = useState( new SessionInitializer(
    //     sessionRepository,
    //     gameRepository,
    //     leaderboardRepository
    // ))
    // const [sessionService, setSessionService] = useState(new SessionService(
    //     sessionRepository,
    //     gameRepository,
    //     leaderboardRepository
    // ))

    // useEffect(() => {
    //     if (authContext.database_jwt_token) {
    //         jwtKeeper.SetJwtToken(authContext.database_jwt_token)
    //         sessionRepository.init()
    //         gameRepository.init()
    //         leaderboardRepository.init()
    //     }
    // }, [authContext.database_jwt_token])


    // if (!authContext.loggedIn) {
    //     return <Navigate to="/login" replace />;
    // }

    const navigate = useNavigate();
    const location = useLocation();
    const cas = CAS.init();
    const user = cas.getUser();

    // Move sessions in AuthLayout to allow only authenticated user to see the sessions page
    if (!user && location.pathname.includes('sessions')) {
        alert('missing user')
        navigate(cas.loginUrl);
    }
    
    return <DomainContext.Provider value={buildDomainContext("")}>
        <Outlet/>
    </DomainContext.Provider>
}