import { useNavigate, useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import DomainContext from "../../../contexts/DomainContext"
import Game from "../../../core/game/Game"
import Challenge from "../../../core/challenge/Challenge"
import ChallengeContext from "../../contexts/ChallengeContext"
import { RetrieveChallengeOutcome } from "../../../core/challenge/ChallengeRetrieveService"
import ChallengeMap from "./components/ChallengeMap"
import GameScore from "./components/GameScore"
import AcknowledgeDialog from "./components/AcknowledgeDialog"
import ChallengePage from "../Challenge"
import './style.scss'
import InfoButton from "./components/InfoButton"
import NoChallengePage from "../NoChallenge"
import TutorialDialog from "./components/Tutorial/TutorialDialog"
import ProfilationPage from "../Profilation"
import LeaderboardPage from "../Leaderboard"
import HalfwayCheck from "../Challenge/components/HalfwayCheck"
import AreaWrong from "./components/AreaWrong"

export enum InternalPages {
  CHALLENGE = "challenge",
  LEADERBOARD = "leaderboard",
  PROFILATION = "profilation",
  ALL_COMPLETED = "all_completed",
  NO_ACTIVE = "no_active",
  MAP = "map",
  HALFWAY_CHECK = "halfway_check"
}

const GamePage = () => {

  const navigate = useNavigate()

  const { token } = useParams()

  const domainContext = useContext(DomainContext)

  const [game, setGame] = useState<Game | undefined>()
  const [challenge, setChallenge] = useState<Challenge | undefined>()
  const [challengeLoaded, setChallengeLoaded] = useState<boolean>(false)
  const [internalPage, setInternalPage] = useState("map")

  const [acknowledgeDialogOpen, setAcknowledgeDialogOpen] = useState(false)
  const [areaWrongDialogOpen, setAreaWrongDialogOpen] = useState(false)

  const [tutorialDialogOpen, setTutorialDialogOpen] = useState(false)
  const [tutorialDialogCloseable, setTutorialDialogCloseable] = useState(false)
  const showChallenge = () => {
    setInternalPage(InternalPages.CHALLENGE)
  }

  const showLeaderboard = () => {
    setInternalPage(InternalPages.LEADERBOARD)
  }

  const loadGameAndChallenge = async () => {
    let retrievedGame = null
    try {
      retrievedGame = await domainContext.gameRepository.restoreGameFromToken(token!)

      setGame(retrievedGame)
    } catch (e) {
      // navigate('/')
      navigate({
        pathname: '/',
        search: '?error=Token%20not%20found'
      })
    }

    // Check profilation


    if (retrievedGame !== null) {
      if (!retrievedGame?.profilationDone) {
        setInternalPage(InternalPages.PROFILATION)
        setChallengeLoaded(true);
        return;
      }

      var challenge = await loadChallenge(retrievedGame)

      setChallengeLoaded(true);

      if (!retrievedGame.tutorialDone) {
        setTutorialDialogOpen(true)
      }

      if (challenge && challenge.acknowledged) {
        setInternalPage(InternalPages.CHALLENGE)
      }
    }
  }

  const loadChallenge = async (game: Game) => {
    const retrievedChallenge = await domainContext.challengeRetrieveService.retrieveOrBuildChallenge(game)

    if (retrievedChallenge === RetrieveChallengeOutcome.ALL_CHALLENGES_COMPLETED) {

      setInternalPage(InternalPages.ALL_COMPLETED)
    } else if (retrievedChallenge === RetrieveChallengeOutcome.NO_ACTIVE_CHALLENGES) {

      setInternalPage(InternalPages.NO_ACTIVE)
    } else {
      setChallenge(retrievedChallenge as Challenge)

      return retrievedChallenge as Challenge
    }
  }

  const reloadChallenge = async () => {
    await loadChallenge(game!)
  }

  useEffect(() => {
    loadGameAndChallenge()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return <div className="GamePage__Container">

    {challengeLoaded ?
      <ChallengeContext.Provider
        value={{
          game,
          challenge,
          challengeInteractService: domainContext.challengeInteractService,
          reloadChallenge,
          showChallenge,
          showLeaderboard,
          setInternalPage
        }}
      >
        {internalPage === InternalPages.MAP && <>
          <ChallengeMap onGuessArea={() => {
            setAcknowledgeDialogOpen(true)
          }}
            onWrongArea={() => {
              setAreaWrongDialogOpen(true)
            }}
          />
          <GameScore />
          <InfoButton onClick={() => {
            setTutorialDialogOpen(true)
            setTutorialDialogCloseable(true)
          }} />
          {/* <AreaSelector
                        onGuessArea={() => {
                            
                        }}
                    /> */}
          <AcknowledgeDialog
            open={acknowledgeDialogOpen}
            onClose={() => {
              setAcknowledgeDialogOpen(false)
            }}
          />
          <AreaWrong
            open={areaWrongDialogOpen}
            onClose={() => {
              setAreaWrongDialogOpen(false)
            }}
          />
          <TutorialDialog
            open={tutorialDialogOpen}
            onClose={() => {
              setTutorialDialogOpen(false)
            }}
            withClose={tutorialDialogCloseable}
          />
        </>}
        {
          internalPage === InternalPages.CHALLENGE && <ChallengePage />
        }
        {
          internalPage === InternalPages.LEADERBOARD && <LeaderboardPage />
        }
        {
          (internalPage === InternalPages.ALL_COMPLETED || internalPage === InternalPages.NO_ACTIVE) &&
          <NoChallengePage />
        }
        {
          internalPage === InternalPages.PROFILATION && <ProfilationPage />
        }
        {
          internalPage === InternalPages.HALFWAY_CHECK && <HalfwayCheck />
        }

      </ChallengeContext.Provider>
      :
      <div>
        Loading challenge
      </div>
    }


  </div>
}

export default GamePage