import { useState } from 'react'
import './style.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GameButton from '../../presentationals/GameButton'
import Box from '../../presentationals/Box'
import loginBg from '../../assets/LoginBG@2x.jpg'
import GameInput from '../../presentationals/GameInput'
import FiascoIcon from '../../../presentationals/FiascoIcon'
import { CAS } from '../../../cas'

const HomePage = () => {
  const navigate = useNavigate()
  const cas = CAS.init();

  const [gameToken, setGameToken] = useState("")

  const [searchParams] = useSearchParams()

  const onSendGameToken = () => {
    if (gameToken === null || gameToken === "") {
      alert('Please insert a token')
    } else {
      navigate(`/games/${gameToken}`)
    }
  }

  return <div className="HomePage__Container">

    <div
      className="HomePage__Content"
      style={{
        backgroundImage: `url(${loginBg})`,
        backgroundSize: 'cover'
      }}
    >
      <div
        className="HomePage__ContentLeft"
      >
        <div>
          <FiascoIcon
            className="HomePage__Icon"
          />
        </div>
        <div
          className="HomePage__FacultyAuthor"
        >
          <div
            className="HomePage__FacultyAuthorTitle"
          >
            Faculty author
          </div>
          <div
            className="HomePage__FacultyAuthorName"
          >
            Miguel Espinosa
          </div>
        </div>
      </div>
      <div
        className="HomePage__ContentCenter"
      >
        <Box
          small
          title="Login with TOKEN"
          buttons={<GameButton
            onClick={onSendGameToken}
          >Enter</GameButton>}
        >
          <div className="HomePage__SelectGameInput">

            <GameInput
              className="HomePage__Input"
              placeholder='insert token'
              name="token"
              value={gameToken}
              onChange={e => {
                setGameToken(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  onSendGameToken()
              }}
            />

          </div>
          {searchParams.get('error') &&
            <div className="HomePage__TokenNotFound">Token not found</div>}
        </Box>
        <div className="HomePage__ContentCenterBottom">
          <a href={cas.loginUrl}>or login with SSO</a>
        </div>
      </div>
      <div
        className="HomePage__ContentRight"
      >

      </div>

    </div>
  </div>
}

export default HomePage