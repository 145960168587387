import GameRepository from "../game/GameRepository";
import LeaderboardEntry from "../leaderboard/LeaderboardEntry";
import LeaderboardRepository from "../leaderboard/LeaderboardRepository";
import { INITIAL_SCORE } from "../utils/constants";
import Session from "./Session";
import SessionRepository from "./SessionRepository";

export default class SessionInitializer {
  /**
   *
   */
  constructor(
    private sessionRepository: SessionRepository,
    private gameRepository: GameRepository,
    private leaderboardRepository: LeaderboardRepository
  ) {

  }

  private makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  public async initializeSession(
    session: Session,
    partecipants: Array<{
      first_name: string,
      last_name: string,
      user_name: string,
      treatment: string
    }>,
    prefix: string
  ) {
    // Create session

    var treatments: Array<string> = []

    for (var partecipant of partecipants) {
      if (!treatments.includes(partecipant.treatment)) {
        treatments.push(partecipant.treatment)
      }
    }

    session.treatments = treatments

    const sessionId = await this.sessionRepository.create(session)


    // Create games
    const games = partecipants.map(partecipant => {
      var game = this.gameRepository.newGame(
        sessionId)

      game.user!.first_name = partecipant.first_name
      game.user!.last_name = partecipant.last_name
      game.user!.user_name = partecipant.user_name.toLowerCase()
      game.treatment = partecipant.treatment
      game.created_at = new Date()
      game.token = prefix + "_" + this.makeid(8)
      return game;
    })

    await this.gameRepository.bulkInsert(games)

    const createdGames = await this.gameRepository.fetchAllBySessionId(sessionId);

    // Create leaderboard entries
    const leaderboardEntries = createdGames.map(createdGame => {
      return {
        session_id: sessionId,
        game_id: createdGame.id,
        name: "-",
        group: createdGame.treatment,
        score_data: {
          score: INITIAL_SCORE,
          challengesPlayedNum: 0
        }
      } as LeaderboardEntry
    })

    this.leaderboardRepository.bulkInsert(leaderboardEntries)

    return sessionId
  }
}