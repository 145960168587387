import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DomainContext from './contexts/DomainContext';
import Game from './core/game/Game';
import "./UserResolver.scss"
import loginBg from './game/assets/LoginBG@2x.jpg'
import GameDialog from './game/presentationals/GameDialog';
import DialogBox from './game/presentationals/DialogBox';

export const UserResolver = () => {
  const domainContext = useContext(DomainContext);
  const { userId } = useParams();
  const [games, setGames] = useState<Game[]>([]);
  const navigate = useNavigate()

  useEffect(() => {
    domainContext.gameRepository.fetchByUsername(userId!).then((games) => {
      if (games.length === 1) {
        navigate(`/games/${games[0].token}`)
      }
      setGames(games)
    });
  }, []);


  return (
    <div
      className="UserResolver__Container"
      style={{
        backgroundImage: `url(${loginBg})`,
        backgroundSize: 'cover'
      }}
    >
      <GameDialog
        onClose={() => {
          //cannot
        }}
        open
        small
      >
        <DialogBox>
          <div
            className="UserResolver__Content"
          >
            {games.length === 0 ?
              <div
                className="UserResolver__NoGames"
              >
                No games found for the current user.
              </div> :
              <>
                <div>Multiple games found for the current user.</div>
                <h3>Choose a game:</h3>
                <div
                  className="UserResolver__Games"
                >
                  <ul>
                    {games.map((game) => (
                      <li key={game.token}>
                        <Link to={`/games/${game.token}`}>{game.token}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>}


          </div>
        </DialogBox>
      </GameDialog>
    </div>
  );
};
