import './style.scss'
import loginBg from '../../assets/LoginBG@1x.jpg'
import FiascoIcon from '../../../presentationals/FiascoIcon'
const SplashScreen = ({
    show
}: {
    show:boolean
}) => {
    return <>{<div
        className={"SplashScreen__Container"
        + (show? "" : " SplashScreen__Container--hidden")
    }
    style={{
        backgroundColor: 'black',
        backgroundImage: `url(${loginBg})`,
        backgroundSize: 'cover'
    }}
    >
        <div className="SplashScreen__Content">
            <FiascoIcon
                className='SplashScreen__Icon'
            size="big"/>
        </div>    
    </div>}</>
}

export default SplashScreen