import GameInput from "../../../presentationals/GameInput"
import SelectInput from "../../../presentationals/SelectInput"
import "./SecondPage.scss"

const genderOptions = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  },
  {
    label: "Non-binary",
    value: "non-binary"
  },
]

const roleOptions = [
  {
    label: "Student",
    value: "student"
  },
  {
    label: "Professional",
    value: "professional"
  }
]

const SecondPage = ({
  age,
  setAge,
  gender,
  setGender,
  role,
  setRole,
  resetError
}: {
  age: number | null,
  setAge: (value: any) => void,
  gender: string,
  setGender: (value: any) => void,
  role: string,
  setRole: (value: any) => void,
  resetError: () => void
}) => {

  const ID = "ProfilationSecondPage"

  const relativeClass = (className: string) => {
    return ID + "__" + className
  }

  return <div className={relativeClass("Container")}>
    <div className={relativeClass("Field")}>
      <div className={relativeClass("Label")}>
        Who are you?
      </div>
      <SelectInput
        name="role"
        value={role}
        onChange={(e) => {
          resetError()
          setRole(e.target.value)
        }}
        options={roleOptions}
      />
    </div>
    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        Age
      </div>
      <GameInput
        type="number"
        name="age"
        value={age}
        onChange={(e) => {
          resetError()
          setAge(e.target.value as unknown as number)
        }}
      />
    </div>

    <div className={relativeClass("FieldHalf")}>
      <div className={relativeClass("Label")}>
        Gender
      </div>
      <SelectInput
        name="gender"
        value={gender}
        onChange={(e) => {
          resetError()
          setGender(e.target.value)
        }}
        options={genderOptions}
      />
    </div>
  </div>

}

export default SecondPage