import { ChangeEvent, FC } from "react"

import './style.scss'

interface TextInputProps {
    type?: 'text' | 'number' | 'email' | 'password' | 'file'
    label: string
    value: any
    name: string
    placeholder?: string
    error?: string
    disabled?: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const TextInput: FC<TextInputProps> = ({
    type,
    label,
    value,
    name,
    placeholder,
    error,
    disabled,
    onChange
}) => {
    return <div className="TextInput__Container">
        <label
            htmlFor={label}
            className="TextInput__Label"
        >{label}</label>
        <input
            className={`TextInput__Input ${type==="file" ? "TextInput__InputFile" : ""}`}
            type={type}
            id={label}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
        />
        {error && <p className="TextInput__Error">{error}</p>}
    </div>
}

export default TextInput;