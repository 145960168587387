import './style.scss'
import { ChangeEvent } from "react"
import textField from '../../assets/Textfield.svg';
const GameInput = ({
  name,
  value,
  className,
  placeholder,
  onChange,
  maxLength,
  type = 'text',
  onKeyDown
}: {
  className?: string,
  placeholder?: string,
  name: string,
  value: any,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  maxLength?: number,
  type?: string,
  onKeyDown?: (e: any) => void,
}) => {
  return <div
    className={"GameInput__Container" + (className ? ` ${className}` : "")}
    style={{
      backgroundImage: `url(${textField})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }}
  >
    <input
      type={type}
      maxLength={maxLength}
      className="GameInput__Input"
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  </div>
}

export default GameInput;