import icon from './Fiasco.svg'

const FiascoIcon = ({
    size,
    className
}: {
    size?: string,
    className?: string
}) => {
    return <img
        alt="Fiasco logo"
        src={icon}
        className={"FiascoIcon " + className}
    />
}

export default FiascoIcon