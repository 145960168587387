import LeaderboardRepository from "../../leaderboard/LeaderboardRepository";
import Game from "../Game";
import GameRepository from "../GameRepository";


export default class DoProfilationCommand {
  constructor(
    private gameRepository: GameRepository,
    private leaderboardRepository: LeaderboardRepository,
    private game: Game,
    private payload: {
      nickname: string,
      age: number,
      gender: string,
      role: string,

      city_birth: string,
      city_residence: string,
      country_birth: string,
      country_residence: string,
      university_major: string
    }
  ) {

  }

  public async execute(): Promise<void> {
    this.game.doProfilation(this.payload)
    await this.gameRepository.update(this.game)
    await this.leaderboardRepository.assignNickname(this.game.sessionId, this.game.id, this.payload.nickname)
  }
}