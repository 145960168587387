import Game from "../../game/Game";
import GameRepository from "../../game/GameRepository";

import PouchDB from 'pouchdb'
import JwtKeeper from "./JwtKeeper";

class GameRepositoryPouchDB extends GameRepository {
  private _couchdbUrl: string

  private _databaseUrl: string

  private _db: PouchDB.Database | undefined

  private max_participants: string | undefined

  constructor(private jwtKeeper: JwtKeeper) {
    super()

    this._couchdbUrl = process.env.REACT_APP_COUCHDB_URL!
    this._databaseUrl = this._couchdbUrl
      + '/'
      + process.env.REACT_APP_DB_PREFIX
      + process.env.REACT_APP_GAMES_DB

    this.max_participants = process.env.REACT_APP_MAX_PARTICIPANTS


    this._db = new PouchDB(this._databaseUrl, {
      // auth: {
      //     username: process.env.REACT_APP_DB_USERNAME,
      //     password: process.env.REACT_APP_DB_PASSWORD
      // }
      fetch: (url, opts) => {
        const headers: any = opts!.headers;
        headers.set('Authorization', 'Bearer ' + this.jwtKeeper.GetJwtToken())
        return PouchDB.fetch(url, opts)
      }
    })
  }

  public async bulkInsert(games: Game[]) {
    await this._db!.bulkDocs(games.map(game => {
      const dump = Game.dump(game)

      delete dump._id
      delete dump._rev
      return dump
    }))
  }

  public async bulkDelete(games: Game[]) {
    await this._db!.bulkDocs(games.map(game => {
      const dump = Game.dump(game) as any

      dump._deleted = true
      return dump
    }))
  }

  public async fetchAllBySessionId(sessionId: string): Promise<Game[]> {
    const result = await this._db!.find({
      selector: {
        session_id: { $eq: sessionId }
      },
      limit: Number(this.max_participants)
    })


    const games = result.docs.map(document => {
      var game = new Game();
      Game.restore(document, game)
      return game
    })

    return games;
  }

  public async fetchAllBySessionIdAndNickname(sessionId: string, nickname: string): Promise<Game[]> {
    const result = await this._db!.find({
      selector: {
        session_id: { $eq: sessionId },
        'user.nickname': { $eq: nickname }
      },
      limit: Number(this.max_participants)
    })


    const games = result.docs.map(document => {
      var game = new Game();
      Game.restore(document, game)
      return game
    })

    return games;
  }

  public async fetchByUsername(username: string): Promise<Game[]> {
    const result = await this._db!.find({
      selector: {
        'user.user_name': { $eq: username.toLowerCase() }
      },
      limit: Number(this.max_participants)
    })


    const games = result.docs.map(document => {
      var game = new Game();
      Game.restore(document, game)
      return game
    })

    return games;
  }

  public async get(gameId: string): Promise<Game> {
    var game = new Game();
    const result = await this._db?.get(gameId)

    Game.restore(result, game)
    return game
  }

  public async restoreGameFromToken(token: string): Promise<Game> {
    const result = await this._db!.find({
      selector: {
        'token': { $eq: token }
      },
      limit: 1
    })
    var game = new Game();
    Game.restore(result.docs[0], game)
    return game
  }

  public async update(game: Game) {
    const dump = Game.dump(game)
    var response = await this._db!.put(dump)
    game._rev = response.rev
  }

  public async delete(game: Game): Promise<void> {
    throw new Error()
  }
}

export default GameRepositoryPouchDB