import { createContext } from "react"


export const defaultContext = {
    loggedIn: false,
    identity: "",
    role: "",
    database_jwt_token: "",
    login: () => {},
    logout: () => {},
    isAdmin: () => {}
}

const AuthContext = createContext(defaultContext)

export default AuthContext
