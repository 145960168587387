import GameRepository from "../game/GameRepository";
import ResearchDataRepository from "../research_data/ResearchDataRepository";


export default class DownloadService {
  constructor(
    private gameRepository: GameRepository,
    private researchDataRepository: ResearchDataRepository
  ) {

  }

  public async fetchTokens(session_id: string) {
    const games = await this.gameRepository.fetchAllBySessionId(session_id)
    const tokens = games.map(obj => ({
      'First name': obj.user?.first_name,
      'Last name': obj.user?.last_name,
      'Treatment': obj.treatment,
      'Token': obj.token
    }))

    return tokens;
  }

  public async fetchResearchData(session_id: string) {
    const research_data = await this.researchDataRepository.fetchAllBySessionId(session_id)

    const research_data_download = research_data.map(obj => {
      var d = new Date(obj.timestamp)

      var stringDate = [d.getMonth() + 1,
      d.getDate(),
      d.getFullYear()].join('/') + ' ' +
        [d.getHours(),
        d.getMinutes(),
        d.getSeconds()].join(':');
      return {
        'session': obj.session_id,
        'student': obj.student,
        'treatment': obj.treatment,
        'issue': obj.issue,
        'action seq.': obj.action_seq,
        'action': obj.action,
        'target solution': obj.solution,
        'solution position': obj.solution_pos,
        'timestamp': stringDate
      }
    })

    return research_data_download
  }

  public async fetchParticipantsData(session_id: string) {
    const games = await this.gameRepository.fetchAllBySessionId(session_id)
    const participants_data = games.map(obj => ({
      'First name': obj.user?.first_name,
      'Last name': obj.user?.last_name,
      'Age': obj.user?.age ? obj.user?.age : '',
      'Gender': obj.user?.gender,
      'Role': obj.user?.role,
      'City of birth': obj.user?.city_birth,
      'City of residence': obj.user?.city_residence,
      'Country of birth': obj.user?.country_birth,
      'Country of residence': obj.user?.country_residence,
      'University major': obj.user?.university_major
    }))

    return participants_data;
  }
}