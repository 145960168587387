
import DebriefChart from "../../widgets/DebriefChart";



export default function SessionDebriefNetScore()
{
    return <DebriefChart
        title={"Which group decided better?"}
        description={"average points net cost of experiments"}
        field={"grossScore"}
    />
}