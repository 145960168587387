import { useContext, useEffect, useRef, useState } from "react"
import ChallengeContext from "../../../contexts/ChallengeContext"
import InteractableMap from "./InteractableMap"
import './ChallengeMap.scss'
import MapNavigator from "./MapNavigator"
import topLeftGroup from '../../../assets/TopLeftGroup.svg'
import background from '../../../assets/Bg_Pattern_yellow.svg'

const ChallengeMap = ({
  onGuessArea,
  onWrongArea
}: {
  onGuessArea: () => void,
  onWrongArea: () => void
}) => {

  const challengeContext = useContext(ChallengeContext)

  const [interactableMap, setInteractableMap] = useState<any>(null)

  const loadingRef = useRef(null)
  useEffect(() => {
    const interactableMap = new InteractableMap(
      challengeContext.challenge?.map as string,
      "ChallengeMap__",
      [
        {
          area_id: 'administration', // Administration Office
          path: '/challenges/areas/MapArea_highlight_Admin.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 565,
            y: 790
          }
        },
        {
          area_id: 'bottling', // Bottling Area
          path: '/challenges/areas/MapArea_highlight_Bottling.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 60,
            y: 505
          }
        },
        {
          area_id: 'dressing', // Dressing Room
          path: '/challenges/areas/MapArea_highlight_Dressing.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 680,
            y: 600
          }
        },
        {
          area_id: 'fermentation', // Fermentation and Wine Ageing
          path: '/challenges/areas/MapArea_highlight_Fermentation.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 380,
            y: 520
          }
        },
        {
          area_id: 'marketing', // Marketing Office
          path: '/challenges/areas/MapArea_highlight_Marketing_1.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 265,
            y: 880
          }
        },
        {
          area_id: 'meeting', // Meeting Room
          path: '/challenges/areas/MapArea_highlight_Marketing_2.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 190,
            y: 710
          }
        },
        {
          area_id: 'shipping', // Stocking and Shipping
          path: '/challenges/areas/MapArea_highlight_Shipping.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 270,
            y: 190
          }
        },
        {
          area_id: 'retail', // Retail Sale Area
          path: '/challenges/areas/MapArea_highlight_Tasting.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 810,
            y: 700
          }
        },
        {
          area_id: 'vineyard', // Vineyard
          path: '/challenges/areas/MapArea_highlight_Vineyard.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 820,
            y: 160
          }
        },
        {
          area_id: 'weighing', // Weighing and Destemming
          path: '/challenges/areas/MapArea_highlight_Weighing.svg',
          currentOffset: {
            x: 0,
            y: 0
          },
          offset: {
            x: 570,
            y: 450
          }
        },
      ],
      loadingRef
    )

    interactableMap.initialDraw()

    setInteractableMap(interactableMap)

    return () => {

      interactableMap.removeListening()
    }
  }, [])

  const onHighilightArea = (area: string) => {
    interactableMap.setCurrentArea(area)
  }

  return <div className="ChallengeMap__Container">
    <canvas className="ChallengeMap__MapCanvas">

    </canvas>
    <div className="ChallengeMap__Loading"
      ref={loadingRef}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat'
      }}
    >
      <div className="ChallengeMap__LoadingInner">
        Loading map...
      </div>
    </div>
    <div
      style={{
        backgroundImage: `url(${topLeftGroup})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
      className="ChallengeMap__Title">
      <div className="ChallengeMap__TitleContent">

        Company map
      </div>
    </div>
    <MapNavigator
      onGuessArea={onGuessArea}
      onWrongArea={onWrongArea}
      onHighlightArea={onHighilightArea}
      zoomIn={() => {
        interactableMap.onZoomIn();
      }}
      zoomOut={() => {
        interactableMap.onZoomOut();
      }}
      moveUp={() => {
        interactableMap.onMoveUp();
      }}
      moveDown={() => {
        interactableMap.onMoveDown();
      }}
      moveLeft={() => {
        interactableMap.onMoveLeft();
      }}
      moveRight={() => {
        interactableMap.onMoveRight();
      }}
    />
  </div>
}

export default ChallengeMap