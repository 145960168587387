import button from '../../../../assets/ButtonIcon_Arrow.svg'


const NextButton = ({
    className,
    onClick
} : {
    className?: string,
    onClick: () => void
}) => {
    return <div className={"NextButton__Container " + className}>
            <img style={{
                width: '4.4em'
            }} alt="" src={button} />
            <button
                className="NextButton__Button"
                style={{
                    position: 'absolute',
                    top: 0,
                    left:0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    border: 0
                }}
                onClick={onClick}
            ></button>
        </div>
}

export default NextButton;