import PaginationResult from "../types/PaginationResult";
import Session from "./Session";

export default abstract class SessionRepository {
    abstract create(session: Session): Promise<string>;

    abstract get(sessionId: string): Promise<object>;

    abstract update(session: Session): void;

    abstract delete(session: Session): Promise<void>;

    abstract fetch(page: number, perPage: number): Promise<PaginationResult<Session>>;

    public async restoreSession(id: string): Promise<Session> {

        const datas = await this.get(id)

        const session = this.newSession()
        Session.restore(datas, session)

        return session;
    }

    public newSession(): Session {
        return new Session()
    }
}