import './style.scss'
import loginBg from '../../assets/LoginBG@2x.jpg'
import GameDialog from '../../presentationals/GameDialog'
import DialogBox from '../../presentationals/DialogBox'
import GameButton from '../../presentationals/GameButton'
import { useContext, useState } from 'react'
import GameInput from '../../presentationals/GameInput'
import DomainContext from '../../../contexts/DomainContext'
import ChallengeContext from '../../contexts/ChallengeContext'
import SecondPage from './components/SecondPage'
import ThirdPage from './components/ThirdPage'

const lastPage = 3;

const genderOptions = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  },
  {
    label: "Non-binary",
    value: "non-binary"
  },
]

const roleOptions = [
  {
    label: "Student",
    value: "student"
  },
  {
    label: "Professional",
    value: "professional"
  }
]

const ProfilationPage = () => {

  const domainContext = useContext(DomainContext)
  const challengeContext = useContext(ChallengeContext)


  const [currentPage, setCurrentPage] = useState(1)

  const [nickname, setNickname] = useState("")

  const [age, setAge] = useState<number | null>(null)
  const [gender, setGender] = useState<string>(genderOptions[0].value)
  const [role, setRole] = useState<string>(roleOptions[0].value)

  const [cityBirth, setCityBirth] = useState("")
  const [cityResidence, setCityResidence] = useState("")
  const [countryBirth, setCountryBirth] = useState("")
  const [countryResidence, setCountryResidence] = useState("")
  const [universityMajor, setUniversityMajor] = useState("")

  const [error, setError] = useState("")

  const checkNicknameTaken = async (nickname: string): Promise<boolean> => {
    const sessionId = challengeContext.game?.sessionId
    const result = await domainContext.gameRepository.fetchAllBySessionIdAndNickname(sessionId!, nickname);
    if (result.length > 0) {
      return true
    }
    return false;
  }

  const nextPage = async () => {
    if (currentPage === 1 && nickname === "") {
      setError("Insert a nickname")
    } else if (currentPage === 1 && await checkNicknameTaken(nickname)) {
      setError("Nickname is already taken, please choose another one")
    } else if (currentPage === 2 && age === null) {
      setError("Insert your age")
    } else if (currentPage === 2 && age as unknown as number <= 0) {
      setError("Age must be a positive number")
    } else if (currentPage === 3 && (
      cityBirth === "" ||
      cityResidence === "" ||
      countryBirth === "" ||
      countryResidence === "" ||
      universityMajor === ""
    )) {
      setError("Please fill all fields")
    } else {
      if (currentPage === lastPage) {

        // Save
        var profilationPayload = {
          nickname: nickname,
          age: age,
          gender: gender,
          role: role,

          city_birth: cityBirth,
          city_residence: cityResidence,
          country_birth: countryBirth,
          country_residence: countryResidence,
          university_major: universityMajor
        }

        const command = domainContext.gameInteractService.buildDoProfilationCommand(challengeContext.game!, profilationPayload);

        await command.execute()

        window.location.reload();
      } else {

        setCurrentPage(currentPage + 1)
      }
    }
  }

  return <div
    className="ProfilationPage__Container"
    style={{
      backgroundImage: `url(${loginBg})`,
      backgroundSize: 'cover'
    }}
  >
    {currentPage > 1 && <><div className="ProfilationPage__Welcome">
      Welcome <b>{nickname}</b>!<br />
      Let's know each other
    </div>
      <div
        className="ProfilationPage__Back"
        onClick={() => {
          setError("")
          setCurrentPage(currentPage - 1)
        }}
      >
        back
      </div>
    </>}
    <GameDialog
      open
      onClose={() => { }}
      blurBackground
      small
      dialogStyle={{
        height: '28vw',
        marginTop: '4vw'
      }}
    >
      <DialogBox
        buttons={<GameButton
          onClick={nextPage}
        >NEXT</GameButton>}
      >
        <div
          className="ProfilationPage__DialogContent"
        >
          {currentPage === 1 && <>
            <div className="ProfilationPage__DialogContentLabel">
              Nickname
            </div>
            <div className="ProfilationPage__DialogContentField">
              <GameInput
                maxLength={13}
                name="nickname"
                value={nickname}
                onChange={(e) => {
                  setError("")
                  setNickname(e.target.value)
                }}
              />
            </div>
            {error !== "" && <div className="ProfilationPage__DialogContentFieldError">
              {error}
            </div>}
            <div className="ProfilationPage__DialogContentInfo">
              Please note: This nickname will appear in the game's leaderboard
            </div>
          </>}
          {currentPage === 2 && <>
            <SecondPage
              age={age}
              setAge={setAge}
              gender={gender}
              setGender={setGender}
              role={role}
              setRole={setRole}
              resetError={() => {
                setError("")
              }}
            />
            {error !== "" && <div className="ProfilationPage__DialogContentFieldError">
              {error}
            </div>}
          </>}
          {currentPage === 3 && <>
            <ThirdPage
              cityBirth={cityBirth}
              cityResidence={cityResidence}
              countryBirth={countryBirth}
              countryResidence={countryResidence}
              universityMajor={universityMajor}
              setCityBirth={setCityBirth}
              setCityResidence={setCityResidence}
              setCountryBirth={setCountryBirth}
              setCountryResidence={setCountryResidence}
              setUniversityMajor={setUniversityMajor}
              resetError={() => {
                setError("")
              }}
            />
            {error !== "" && <div className="ProfilationPage__DialogContentFieldError">
              {error}
            </div>}
          </>}
        </div>

      </DialogBox>
    </GameDialog>
  </div>
}

export default ProfilationPage