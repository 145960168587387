import SessionStatus from "./SessionStatus"

export default class Session {
    public _id: string|undefined

    public _rev: string|undefined

    public name: string = ""

    public created_at: Date|undefined = new Date()

    public status: SessionStatus = SessionStatus.Ready

    public activeChallengesIds: Array<string> = []

    public onceActiveChallengesIds: Array<string> = []

    public treatments: Array<string> = []

    public activateChallenge (challengeId: string): void {
        if (this.isChallengeActive(challengeId)) {
            return // Nothing to do
        }

        this.activeChallengesIds.push(challengeId)
        
        if (typeof (this.onceActiveChallengesIds) === 'undefined') {
            this.onceActiveChallengesIds = []
        }
        if (!this.onceActiveChallengesIds.includes(challengeId)) {

            this.onceActiveChallengesIds.push(challengeId)
        }
    }

    public deactivateChallenge (challengeId: string): void {
        if (!this.isChallengeActive(challengeId)) {
            return // Nothing to do
        }

        this.activeChallengesIds = this.activeChallengesIds
            .filter(activeElement => activeElement !== challengeId)
    }

    public isChallengeActive (challengeId: string): boolean {
        return this.activeChallengesIds
            .find(activeChallenge => activeChallenge === challengeId) != null
    }

    public get id() {
        return this._id
    }

    public get rev() {
        return this._rev
    }

    public startSession(): void {
        this.status = SessionStatus.Started
    }

    public endSession(): void {
        this.status = SessionStatus.Completed
    }

    public static dump(session: Session){ // DUMP
        return {
            _id: session.id,
            _rev: session.rev,
            name: session.name,
            created_at: session.created_at?.toISOString(),
            status: session.status,
            activeChallengesIds: session.activeChallengesIds,
            onceActiveChallengesIds: session.onceActiveChallengesIds,
            treatments: session.treatments
        }
    }

    public static restore(datas: any, toSession: Session){
        toSession._id = datas._id
        toSession._rev = datas._rev
        toSession.name = datas.name
        toSession.created_at = new Date(datas.created_at)
        toSession.status = datas.status
        toSession.activeChallengesIds = datas.activeChallengesIds
        toSession.onceActiveChallengesIds = datas.onceActiveChallengesIds
        toSession.treatments = datas.treatments
    }
}