import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../presentationals/Breadcrumb";
import { useContext, useEffect, useMemo, useState } from "react";
import DebriefContext from "../../contexts/DebriefContext";
import Actions from "../../presentationals/Actions";
import Button from "../../presentationals/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import './index.scss'
import DebriefChartLayout from "../../layout/DebriefChartLayout";
import SelectInput, { Option } from "../../presentationals/inputs/SelectInput";
import DomainContext from "../../../contexts/DomainContext";
import Card from "../../presentationals/Card";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
  ReferenceLine,
  Label
} from 'recharts';
import CustomizedAxisTick from "./components/CustomizedAxisTick";

const CustomTooltip = ({ active, payload, label, description }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid grey',
          padding: '0px 16px'
        }}
      >
        <p>{label}</p>
        <p style={{
          color: '#826C9F'
        }}>{description}: {payload[0].payload.isHidden ? '-' : payload[0].value}</p>
        <p>challenges played: {payload[0].payload.isHidden ? '-' : payload[0].payload.challenges}</p>
      </div>
    );
  }

  return null
}

export default function DebriefChart({
  title,
  description,
  field,
  yDomain = [-3, 3],
  yTicks = [-3, -2, -1, 0, 1, 2, 3]
}: {
  title: string,
  description: string,
  field: 'netScore' | 'grossScore' | 'experimentsDone',
  yDomain?: any,
  yTicks?: any
}) {
  const { id } = useParams()
  const navigate = useNavigate()

  const globalContext = useContext(DomainContext)

  const [selectedChallenge, setSelectedChallenge] = useState("")
  const [challengeOptions, setChallengeOptions] = useState<Option[]>([])
  const [groupToggles, setGroupToggles] = useState<{
    name: string,
    active: boolean
  }[]>([])
  const [loading, setLoading] = useState(true)

  const activeGroups = useMemo(() => {
    return groupToggles.filter(g => g.active).map(g => g.name)
  }, [groupToggles])

  const [chartData, setChartData] = useState<{ name: string, value: number }[]>([])
  const [filteredChartData, setFilteredChartData] = useState<{ name: string, value: number }[]>([])
  const {
    session,
    debriefService
  } = useContext(DebriefContext)

  useEffect(() => {
    fetchChallengeOptions()
  }, [])

  useEffect(() => {
    if (session)
      initGroups()
  }, [session])

  useEffect(() => {
    if (session)
      fetchChallengeData()
  }, [selectedChallenge, session])

  useEffect(() => {
    filterChallengeData()
  }, [activeGroups, chartData])

  const fetchChallengeOptions = async () => {
    const challenges = await globalContext.challengeRepositoryBlueprint.fetch()
    const options = challenges.map(challenge => ({
      label: challenge.title,
      value: challenge.id
    }))

    options.unshift({
      label: 'All challenges',
      value: ''
    })

    setChallengeOptions(options)
  }

  const fetchChallengeData = async () => {
    setLoading(true)
    const data = await debriefService?.fetchGroupData(id!, selectedChallenge, field, session?.treatments!)

    if (data) {

      setChartData(data)
      setFilteredChartData(data)
      setLoading(false)
    } else {
      // Error
    }
  }

  const filterChallengeData = async () => {
    let newDatas = chartData;

    newDatas = newDatas
      .map(nd => {
        var newValue = 0
        var isHidden = false
        if (!activeGroups.includes(nd.name)) {
          newValue = 0
          isHidden = true
        } else {
          newValue = nd.value
        }

        return {
          ...nd,
          value: Math.round(newValue * 100) / 100,
          isHidden
        }
      })

    setFilteredChartData(newDatas)
  }

  const initGroups = () => {
    setGroupToggles(session!.treatments.map(group => ({
      name: group,
      active: true
    })))
  }

  const toggleGroup = (name: string) => {
    setGroupToggles(groupToggles.map(group => {
      if (group.name === name) {
        group.active = !group.active
      }

      return group;
    }))
  }

  return <div className="DebriefChart__Container">
    <Breadcrumb items={[
      {
        name: 'home',
        label: 'Home',
        href: '/sessions'
      },
      {
        name: 'sessions',
        label: 'Sessions',
        href: '/sessions'
      },
      {
        name: 'session',
        label: session?.name!,
        href: '/sessions/' + id
      },
      {
        name: 'debrief',
        label: 'Debrief',
        href: '/sessions/' + id + '/debrief'
      },
      {
        name: 'score_chart',
        label: title,
        href: '/sessions/' + id + '/debrief/net-score'
      },
    ]} />

    <div className="DebriefChart__Title">
      <h1>{title}</h1>
    </div>
    <Actions
      leftActions={<div
        className="DebriefChart__Actions"
      >
        <Button
          onClick={() => {
            navigate('/sessions/' + id + '/debrief')
          }}
          icon={
            <FontAwesomeIcon icon={faArrowLeft} />
          }
        >Back to debrief</Button>
        <div
          className="DebriefChart__Challenge"
        >{challengeOptions.find(co => co.value === selectedChallenge)?.label}</div>
      </div>}
    ></Actions>
    <DebriefChartLayout
      loading={loading}
      filters={<Card>
        <SelectInput
          label="Challenge"
          value={selectedChallenge}
          name="challenge"
          options={challengeOptions}
          onChange={e => setSelectedChallenge(e.target.value)}
          vertical
        />
        <div className="DebriefChart__GroupFilter">
          <div className="DebriefChart__GroupFilterLabel">
            Groups
          </div>
          {
            groupToggles.map(group => {
              return <Button
                inactive={!group.active}
                onClick={() => {
                  toggleGroup(group.name)
                }}
              >{group.name}</Button>
            })
          }
        </div>
      </Card>}>

      <Card style={{
        height: '800px',
        padding: '20px'
      }}
        pureOverlay
      >



        {filteredChartData.length > 0 ?
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={filteredChartData}
              margin={{
                top: 5,
                right: 30,
                bottom: 24,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"
                tick={<CustomizedAxisTick activeGroups={activeGroups} />}
              >
                <Label value={'treatments'} position="insideBottom" style={{ translate: '0 22px'}} />
              </XAxis>
              <YAxis
                domain={yDomain}
                ticks={yTicks}
              >
                <Label angle={-90} value={description} position='insideLeft' style={{ textAnchor: 'middle' }} />

              </YAxis>
              <Tooltip
                content={<CustomTooltip description={description} />}
              />
              <Bar
                dataKey="value"
                fill="#826C9F"
                activeBar={<Rectangle fill="#826C9F" stroke="#FFFFFF" />}
                name={description}
              />
              <ReferenceLine y={0} stroke="black" isFront strokeWidth={2} />
            </BarChart>

          </ResponsiveContainer>
          :
          <div className="DebriefChart__NoData">No data to show</div>
        }


      </Card>



    </DebriefChartLayout>
  </div>
}